export default {
  metaInfo () {
    let meta = [
      { vmid: 'og:type', property: 'og:type', content: 'website' },
      { vmid: 'twitter:card', property: 'twitter:card', content: 'summary_large_image' },
      { vmid: 'og:image:width', property: 'og:image:width', content: '1200' },
      { vmid: 'og:image:height', property: 'og:image:height', content: '630' }
    ]
    const keys = Object.keys(this.seo)
    for (const key of keys) {
      if (key === 'title' || key === 'description' || key === 'robots') {
        meta.push(
          { vmid: key, name: key, content: this.seo[key] }
        )
      }
      if (key !== 'robots') {
        meta.push(
          { vmid: 'og:' + key, property: 'og:' + key, content: this.seo[key] },
          { vmid: 'twitter:' + key, property: 'twitter:' + key, content: this.seo[key] }
        )
      }
    }
    return {
      title: this.seo.title || '',
      titleTemplate: chunk => chunk,
      meta: meta
    }
  }
}
