import { debug } from '@/plugins/util'
import * as searchService from '@/service/search'
import moment from 'moment'
import i18n from '@/i18n'
import api from '@/service/api'
import { isCancel } from '../../service/api'

const state = {
  propertyCode: undefined,
  continuePolling: true,
  property: undefined,
  reviews: {
    summary: undefined,
    location: undefined
  },
  nights: undefined,
  roomCount: undefined,
  availability: { packages: [] },
  searchId: '',
  isSearching: false,
  isSearchingMore: false,
  fetchCompleted: false,
  fetchCount: 0,
  maxFetchCount: 3,
  fetchInterval: 1000, // ms
  delta: Math.PI,
  retryCount: 0,
  maxRetries: 5
}

const getters = {
  searchOneProperty: state => {
    return state.property ? state.property : null
  },
  searchOnePackage: state => {
    return state.availability ? state.availability.packages[0] : null
  },
  searchOneReviews: state => {
    return state.reviews ? state.reviews : null
  }
}

const mutations = {
  SET_SEARCH_ONE: (state, payload) => {
    if (debug) console.log('setSearchOne triggered with', payload)
    const keys = Object.keys(payload)
    keys.forEach((key) => {
      state[key] = payload[key]
    })
  },
  CLEAR_SEARCH_ONE: (state, payload) => {
    if (debug) console.log('clearSearchOne triggered')
    state.propertyCode = undefined
    state.isSearching = false
    state.continuePolling = true
    state.property = undefined
    state.reviews = undefined
    state.availability = undefined
    state.nights = undefined
  },
  RETRY_COUNT: (state, payload) => {
    state.retryCount = payload
  }
}

const actions = {
  search ({ commit, dispatch, state }, query) {
    // validate query
    const dateNow = moment().format('YYYY-MM-DD')
    if (query.checkInDate < dateNow || query.checkOutDate < dateNow) {
      dispatch(
        'setGlobalErrorModal',
        {
          show: true,
          title: i18n.t('app.error.past-date.title'),
          content: i18n.t('app.error.past-date.content')
        },
        { root: true }
      )
      commit('SET_SEARCH_ONE', { isSearching: false, fetchCompleted: true })
      return
    }
    if (state.searchId === query.searchId) {
      let counter = state.fetchCount
      commit('SET_SEARCH_ONE', { fetchCount: ++counter })
    } else {
      commit('SET_SEARCH_ONE', { fetchCount: 1, searchId: query.searchId })
    }
    commit('SET_SEARCH_ONE', { isSearching: true })
    searchService.poll(() => {
      if (typeof (query.propertyCode) === 'undefined') throw { message: 'Invalid property code' }
      return searchService.one(query)
    }, 2500, 20).then(
      (resp) => {
        const { outlets, meta } = resp
        // set state on meta module
        commit('meta/SET_META_ACTION', meta, { root: true })
        commit('SET_SEARCH_ONE', {
          isSearching: false,
          ...outlets
        })
        commit('SET_SEARCH_ONE', {
          fetchCompleted: true
        })
      }
    ).catch((err) => {
      if (isCancel(err)) {
        if (debug) console.warn(`Cancel: ${err.message}`)
        if (err.message !== 'NEW_SEARCH_ONE') {
          commit('SET_SEARCH_ONE', {
            isSearching: false,
            availability: { packages: [] }
          })
          commit('meta/SET_META_ACTION', err.error.meta, { root: true })
          commit('SET_GLOBAL_MODAL', { show: true, content: err.message || (err.error && err.error.message) || '' }, { root: true })
        }
      } else {
        if (err.message && err.message.toLowerCase().indexOf('timeout') > -1) {
          setTimeout(() => dispatch('search', query), 2500)
        } else {
          commit('SET_SEARCH_ONE', {
            isSearching: false,
            availability: { packages: [] }
          })
        }
      }
    })
  },
  searchMore ({ commit, state }, query) {
    commit('SET_SEARCH_ONE', { isSearchingMore: true })
    searchService.poll(() => {
      if (typeof (query.propertyCode) === 'undefined') throw { message: 'Invalid property code' }
      return searchService.one(query)
    }, 2500, 20).then(
      (resp) => {
        const { outlets, meta } = resp
        commit('meta/SET_META_ACTION', meta, { root: true })
        const tmp = [...state.availability.packages, ...outlets.availability.packages]
        commit('SET_SEARCH_ONE', { availability: { ...outlets.availability, packages: tmp } })
        commit('SET_SEARCH_ONE', { isSearchingMore: false })
      }
    ).catch((err) => {
      commit('SET_GLOBAL_MODAL', { show: true, content: err.message || (err.error && err.error.message) || '' }, { root: true })
    })
  },
  async details ({ commit, dispatch, state }, query) {
    try {
      if (typeof (query.propertyCode) === 'undefined') throw { message: 'Invalid property code' }
      const data = await api.get(`/properties/details/${query.propertyCode}`)
      const { outlets, meta } = data
      commit('meta/SET_META_ACTION', meta, { root: true })
      commit('SET_SEARCH_ONE', {
        ...outlets
      })
      if (!outlets.property) {
        commit('SET_GLOBAL_MODAL', { show: true, content: 'Invalid property code' }, { root: true })
      }
    } catch (err) {
      if (err.error && err.error.status > 499) {
        if (state.retryCount === state.maxRetries) {
          // Show popup panel to refresh
          commit('SET_GLOBAL_MODAL',
            { show: true, content: '', refresh: true }, { root: true })
        } else {
          // Update retry count and refrsh every 2 seconds
          let retries = state.retryCount + 1
          commit('RETRY_COUNT', retries)
          setTimeout(() => {
            dispatch('details', query)
          }, 2000)
        }
      } else {
        commit('SET_GLOBAL_MODAL', { show: true, content: err.message || (err.error && err.error.message) || '' }, { root: true })
      }
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
