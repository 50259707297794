const cols = {
  companyName: {
    value: 'companyName',
    prop: 'companyName',
    label: 'Client name',
    active: true,
    width: 250
  },
  userAdmin: {
    value: 'userAdmin',
    prop: 'userAdmin',
    label: 'Client admin',
    active: true,
    formatter: (row) => {
      return row.userAdmin.join(', ')
    }
  },
  activeUsersCount: {
    value: 'activeUsersCount',
    prop: 'activeUsersCount',
    label: 'Users',
    active: true
  },
  inactive: {
    value: 'inactive',
    prop: 'inactive',
    label: 'Status',
    active: true,
    html: true,
    formatter: (row) => {
      return row.inactive
        ? '<span class="has-text-grey-light">Inactive</span>'
        : '<span class="has-text-success">Active</span>'
    }
  },
  availabilitySearch: {
    value: 'availabilitySearch',
    prop: 'availabilitySearch',
    label: 'Searchable',
    active: true,
    html: true,
    formatter: (row) => {
      return row.availabilitySearch
        ? '<span class="has-text-success">Allow</span>'
        : '<span class="has-text-grey-light">Blocked</span>'
    }
  },
  reservation: {
    value: 'reservation',
    prop: 'reservation',
    label: 'Make booking',
    active: true,
    html: true,
    formatter: (row) => {
      return row.reservation
        ? '<span class="has-text-success">Allow</span>'
        : '<span class="has-text-grey-light">Blocked</span>'
    }
  },
  updatedAt: {
    value: 'updatedAt',
    prop: 'updatedAt',
    label: 'Updated at',
    active: true,
    formatter: (row, filters) => {
      return filters.date(row.updatedAt)
    }
  },
  createdAt: {
    value: 'createdAt',
    prop: 'createdAt',
    label: 'Created at',
    active: true,
    formatter: (row, filters) => {
      return filters.date(row.createdAt)
    }
  },
  // balance: {
  //   value: 'balance',
  //   prop: 'balance',
  //   label: 'Balance',
  //   active: false,
  //   formatter: (row, filters) => {
  //     return filters.currency(row.balance > 0 ? row.balance / 100 : 0)
  //   }
  // },
  // negativeBalance: {
  //   value: 'negativeBalance',
  //   prop: 'negativeBalance',
  //   label: 'Allow negative balance',
  //   active: false,
  //   formatter: (row, filters) => {
  //     if (!row.wallet) return '-'
  //     return filters.currency(row.wallet.minBalance < 0 ? row.wallet.minBalance / 100 : 0)
  //   }
  // },
  city: {
    value: 'city',
    prop: 'city',
    label: 'City',
    active: false
  },
  country: {
    value: 'country',
    prop: 'country',
    label: 'Country',
    active: false,
    formatter: (row, filters, localFilters) => {
      return localFilters.country(row.country)
    }
  },
  forexMarkup: {
    value: 'forexMarkupPct',
    prop: 'forexMarkupPct',
    label: 'Forex markup',
    active: false,
    formatter: (row, filters) => {
      return `${(row.forexMarkupPct * 100).toFixed(2)}%`
    }
  },
  minMarkup: {
    value: 'minMarkupInPct',
    prop: 'minMarkupInPct',
    label: 'Min markup',
    active: false,
    formatter: (row, filters) => {
      return `${(row.minMarkupInPct * 100).toFixed(2)}%`
    }
  },
  txFee: {
    value: 'txFee',
    prop: 'txFee',
    label: 'Transaction fee',
    active: false,
    formatter: (row, filters, localFilters) => {
      const { txFee } = row
      const fee = txFee[0].adjustmentPct
      return `${(fee * 100).toFixed(2)}%`
    }
  },
  additionalFee: {
    value: 'additionalFee',
    prop: 'additionalFee',
    label: 'Additional fee',
    active: false,
    formatter: (row, filters) => {
      const { value, ccy } = row.additionalFee
      return filters.currency(value > 0 ? value / 100 : value, { currency: ccy })
    }
  }
}

/**
 * Cache for later use
 */
const colCodes = Object.keys(cols)

const list = colCodes.map(key => ({ key, ...cols[key] }))

/**
 * Retrive colmns insance from key
 * @param {string} colCode – code to represent the column
 */
const findByKey = (colCode) => {
  return cols[colCode]
}

const defaultColumns = ['companyName', 'userAdmin', 'activeUsersCount', 'inactive', 'availabilitySearch', 'reservation', 'updatedAt', 'createdAt']

export default {
  cols,
  list,
  findByKey,
  defaultColumns
}
