import Vue from 'vue'
import VueRouter from 'vue-router'
import Homepage from '@/views/static/Homepage'
import { isLoggedIn, isAdminLoggedIn, retrieveUser } from '@/service/auth'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'homepage',
    component: Homepage,
    meta: {
      // hasContactHidden: true
    },
    beforeEnter: (_to, _from, next) => {
      if (isLoggedIn()) {
        next({ name: 'dashboard' })
      } else if (isAdminLoggedIn() && _to.meta.isAdminRoute) {
        next({ name: 'admin/client-management' })
      } else {
        next()
      }
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/dashboard/index.vue')
  },
  {
    path: '/dashboard-empty',
    name: 'dashboard-empty',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/dashboard/DashboardEmpty.vue')
  },
  {
    path: '/dashboard-bookmark-more',
    name: 'dashboard-bookmark-more',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/dashboard/DashboardBookmarkMore.vue')
  },
  {
    path: '/search/:locationQuery',
    name: 'search',
    component: () => import(/* webpackChunkName: "search" */ '../views/search/index.vue'),
    props: (route) => ({
      query: route.query,
      params: route.params
    }),
    meta: {
      keepAlive: true,
      hasFooterHidden: true,
      stickyHeader: true,
      scrollToPos: { x: 0, y: 0 }
    }
  },
  {
    path: '/property/:name',
    name: 'property',
    component: () => import(/* webpackChunkName: "hotel" */ '../views/hotel/index.vue'),
    props: (route) => ({
      query: route.query,
      params: route.params
    }),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/property-info/:propertyCode',
    name: 'propertyInfo',
    component: () => import(/* webpackChunkName: "hotel" */ '../views/hotel/PropertyInfo.vue'),
    props: (route) => ({
      query: route.query,
      params: route.params
    }),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/quote',
    name: 'quote',
    component: () => import(/* webpackChunkName: "quote" */ '../views/quote/Quote.vue'),
    props: (route) => ({
      query: route.query
    }),
    meta: {
      keepAlive: true
    }
  },
  // {
  //   path: '/register',
  //   name: 'register',
  //   component: () => import(/* webpackChunkName: "register" */ '../views/customer/Register.vue'),
  //   meta: {
  //     scrollToPos: {
  //       x: 0,
  //       y: 0
  //     }
  //   },
  //   beforeEnter: (_to, _from, next) => {
  //     if (isLoggedIn()) {
  //       next({ name: 'dashboard' })
  //     } else {
  //       next()
  //     }
  //   }
  // },
  // {
  //   path: '/confirm-registration',
  //   name: 'confirm-registration',
  //   component: () => import(/* webpackChunkName: "register" */ '../views/customer/ConfirmRegistration.vue')
  // },
  // {
  //   path: '/register-complete',
  //   name: 'register-complete',
  //   props: (route) => ({
  //     query: route.query,
  //     params: route.params
  //   }),
  //   component: () => import(/* webpackChunkName: "register" */ '../views/customer/RegisterComplete.vue')
  // },
  // {
  //   path: '/register-by-invitation',
  //   name: 'register-by-invitation',
  //   component: () => import(/* webpackChunkName: "register" */ '../views/customer/RegisterByInvitation.vue')
  // },
  // {
  //   path: '/register-by-invitation-complete',
  //   name: 'register-by-invitation-complete',
  //   component: () => import(/* webpackChunkName: "register" */ '../views/customer/RegisterByInvitationComplete.vue')
  // },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import(/* webpackChunkName: "forgot" */ '../views/customer/ForgotPassword.vue')
  },
  {
    path: '/check-email',
    name: 'check-email',
    component: () => import(/* webpackChunkName: "register" */ '../views/customer/CheckEmail.vue')
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import(/* webpackChunkName: "register" */ '../views/customer/ResetPassword.vue')
  },
  {
    path: '/reset-password-success',
    name: 'reset-password-success',
    component: () => import(/* webpackChunkName: "register" */ '../views/customer/ResetPasswordSuccess.vue')
  },
  {
    path: '/book/:ckToken',
    name: 'book',
    component: () => import(/* webpackChunkName: "book" */ '../views/book/index.vue'),
    meta: {
      scrollToPos: { x: 0, y: 0 }
    }
  },
  {
    path: '/payment/:ckToken',
    name: 'payment',
    component: () => import(/* webpackChunkName: "book" */ '../views/payment/index.vue'),
    meta: {
      scrollToPos: { x: 0, y: 0 }
    }
  },
  // {
  //   path: '/payment-balance-not-enough',
  //   name: 'payment-balance-not-enough',
  //   component: () => import(/* webpackChunkName: "book" */ '../views/payment/PaymentBalanceNotEnough.vue')
  // },
  {
    path: '/payment-processing/:orderReference',
    name: 'payment-processing',
    component: () => import(/* webpackChunkName: "book" */ '../views/payment/PaymentProcessing.vue')
  },
  {
    path: '/order/:orderToken',
    name: 'orderDetails',
    component: () => import(/* webpackChunkName: "book" */ '../views/book/CheckoutComplete.vue'),
    meta: {
      scrollToPos: { x: 0, y: 0 }
    }
  },
  {
    path: '/pci-success',
    name: 'pci-success',
    component: () => import(/* webpackChunkName: "pci" */ '../views/payment/PCISuccess.vue')
  },
  {
    path: '/pci-fail',
    name: 'pci-fail',
    component: () => import(/* webpackChunkName: "pci" */ '../views/payment/PCIFail.vue')
  },

  /**
   * Account Profile
   */
  {
    path: '/my-bookings',
    name: 'my-bookings',
    component: () => import(/* webpackChunkName: "myAccount" */ '../views/customer/MyBookings.vue'),
    meta: {
      scrollToPos: { x: 0, y: 0 }
    }
  },
  {
    path: '/my-bookings/:orderReference',
    name: 'my-bookings-detail',
    props: (route) => ({
      params: route.params
    }),
    component: () => import(/* webpackChunkName: "myAccount" */ '../views/customer/MyBookingsDetail.vue')
  },
  {
    path: '/settle-payment',
    name: 'settle-payment',
    component: () => import(/* webpackChunkName: "myAccount" */ '../views/customer/SettlePayment'),
    meta: {
      scrollToPos: { x: 0, y: 0 }
    }
  },
  {
    path: '/bulk-payment/:orderReference',
    name: 'bulk-payment',
    props: (route) => ({
      params: route.params
    }),
    component: () => import(/* webpackChunkName: "payment" */ '../views/payment/BulkPayment')
  },
  {
    path: '/bulk-payment-status/:paymentToken',
    name: 'bulk-payment-status',
    props: (route) => ({
      params: route.params
    }),
    component: () => import(/* webpackChunkName: "payment" */ '../views/payment/BulkPaymentComplete')
  },
  {
    path: '/my-account',
    name: 'my-account',
    component: () => import(/* webpackChunkName: "myAccount" */ '../views/customer/MyAccount.vue')
  },
  {
    path: '/my-company',
    name: 'my-company',
    component: () => import(/* webpackChunkName: "myAccount" */ '../views/customer/MyCompany.vue')
  },
  {
    path: '/users',
    name: 'users',
    component: () => import(/* webpackChunkName: "myAccount" */ '../views/customer/Users.vue')
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: () => import(/* webpackChunkName: "myAccount" */ '../views/customer/ChangePassword.vue')
  },
  {
    path: '/account-preferences',
    name: 'account-preferences',
    component: () => import(/* webpackChunkName: "myAccount" */ '../views/customer/AccountPreferences.vue')
  },
  {
    path: '/my-wallet/:currency',
    name: 'my-wallet',
    component: () => import(/* webpackChunkName: "myAccount" */ '../views/customer/MyWallet.vue')
  },
  {
    path: '/credit-card',
    name: 'credit-card',
    component: () => import(/* webpackChunkName: "myAccount" */ '../views/customer/CreditCard.vue')
  },
  {
    path: '/my-pricing',
    name: 'my-pricing',
    component: () => import(/* webpackChunkName: "myAccount" */ '../views/customer/MyPricing.vue')
  },

  /**
   * Super Admin Routes
   */
  {
    path: '/admin/register',
    name: 'admin/register',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/Register.vue'),
    meta: {
      isAdminRoute: true
    }
  },
  {
    path: '/admin',
    redirect: '/admin/login'
  },
  {
    path: '/admin/login',
    name: 'admin/login',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/Login.vue'),
    meta: {
      isAdminRoute: true
    },
    beforeEnter: (to, from, next) => {
      if (isAdminLoggedIn()) {
        next({ name: 'admin/client-management' })
      } else {
        next()
      }
    }
  },
  {
    path: '/admin/forgot-password',
    name: 'admin/forgot-password',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/ForgotPassword.vue'),
    meta: {
      isAdminRoute: true
    }
  },
  {
    path: '/admin/reset-password',
    name: 'admin/reset-password',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/ResetPassword.vue'),
    meta: {
      isAdminRoute: true
    }
  },
  {
    path: '/admin/reset-password-success',
    name: 'admin/reset-password-success',
    component: () => import(/* webpackChunkName: "admin" */ '../views/customer/ResetPasswordSuccess.vue'),
    meta: {
      isAdminRoute: true
    }
  },
  {
    path: '/admin/user-management',
    name: 'admin/user-management',
    component: () => import(/* webpackChunkName: "admin-user-management" */ '../views/admin/UserManagement.vue'),
    meta: {
      keepAlive: true,
      isAdminRoute: true
    }
  },
  {
    path: '/admin/user-management/overview/:uid',
    name: 'admin/user-management/overview',
    component: () => import(/* webpackChunkName: "admin-user-management" */ '../views/admin/UserManagementOverview.vue'),
    meta: {
      isAdminRoute: true
    }
  },
  {
    path: '/admin/user-management/client/:uid',
    name: 'admin/user-management/client',
    component: () => import(/* webpackChunkName: "admin-user-management" */ '../views/admin/UserManagementClient.vue'),
    meta: {
      isAdminRoute: true
    }
  },
  {
    path: '/admin/user-management/history/:uid',
    name: 'admin/user-management/history',
    component: () => import(/* webpackChunkName: "admin-client-management" */ '../views/admin/UserManagementHistory.vue'),
    meta: {
      isAdminRoute: true
    }
  },
  {
    path: '/admin/top-up-requests',
    name: 'admin/top-up-requests',
    component: () => import(/* webpackChunkName: "admin-top-up-requests" */ '../views/admin/TopUpRequests.vue'),
    meta: {
      isAdminRoute: true
    }
  },
  {
    path: '/admin/client-management',
    name: 'admin/client-management',
    component: () => import(/* webpackChunkName: "admin-client-management" */ '../views/admin/ClientManagement.vue'),
    meta: {
      keepAlive: true,
      isAdminRoute: true
    }
  },
  {
    path: '/admin/client-management/overview/:appCode',
    name: 'admin/client-management/overview',
    component: () => import(/* webpackChunkName: "admin-client-management" */ '../views/admin/ClientManagementOverview.vue'),
    meta: {
      isAdminRoute: true
    }
  },
  {
    path: '/admin/client-management/users/:appCode',
    name: 'admin/client-management/users',
    component: () => import(/* webpackChunkName: "admin-client-management" */ '../views/admin/ClientManagementUsers.vue'),
    meta: {
      isAdminRoute: true
    }
  },
  {
    path: '/admin/client-management/pricing/:appCode',
    name: 'admin/client-management/pricing',
    component: () => import(/* webpackChunkName: "admin-client-management" */ '../views/admin/ClientManagementPricing.vue'),
    meta: {
      isAdminRoute: true
    }
  },
  {
    path: '/admin/client-management/wallet/:currency/:appCode',
    name: 'admin/client-management/wallet',
    component: () => import(/* webpackChunkName: "admin-client-management" */ '../views/admin/ClientManagementWallet.vue'),
    meta: {
      isAdminRoute: true
    }
  },
  {
    path: '/admin/client-management/history/:appCode',
    name: 'admin/client-management/history',
    component: () => import(/* webpackChunkName: "admin-client-management" */ '../views/admin/ClientManagementHistory.vue'),
    meta: {
      isAdminRoute: true
    }
  },
  {
    path: '/admin/orders-management',
    name: 'admin/orders-management',
    component: () => import(/* webpackChunkName: "admin-orders-management" */ '../views/admin/OrdersManagement.vue'),
    meta: {
      keepAlive: true,
      isAdminRoute: true
    }
  },
  {
    path: '/admin/orders-management/:orderReference',
    name: 'admin/orders-management-detail',
    props: (route) => ({
      params: route.params
    }),
    component: () => import(/* webpackChunkName: "admin-orders-management" */ '../views/admin/OrdersManagementDetail.vue'),
    meta: {
      isAdminRoute: true,
      scrollToPos: { x: 0, y: 0 }
    }
  },
  {
    path: '/admin/master-settings',
    name: 'admin/master-settings',
    component: () => import(/* webpackChunkName: "admin-master-settings" */ '../views/admin/MasterSettings.vue'),
    meta: {
      isAdminRoute: true
    }
  },
  {
    path: '/admin/master-settings-pricing',
    name: 'admin/master-settings-pricing',
    component: () => import(/* webpackChunkName: "admin-master-settings" */ '../views/admin/MasterSettingsPricing.vue'),
    meta: {
      isAdminRoute: true
    }
  },
  /**
   * Static page Routes
   */
  {
    path: '/feedback',
    name: 'feedback',
    component: () => import(/* webpackChunkName: "static" */ '../views/static/Feedback.vue'),
    props: (route) => ({
      type: route.query.type,
      hotelId: route.query.hotelId
    })
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import(/* webpackChunkName: "static" */ '../views/static/Faq.vue')
  },
  {
    path: '/about-us',
    name: 'about-us',
    component: () => import(/* webpackChunkName: "static" */ '../views/static/AboutUs.vue')
  },
  // {
  //   path: '/roadmap',
  //   name: 'roadmap',
  //   component: () => import(/* webpackChunkName: "static" */ '../views/static/Roadmap.vue')
  // },
  {
    path: '/ecreds',
    name: 'ecreds',
    component: () => import(/* webpackChunkName: "static" */ '../views/static/Ecreds.vue')
  },
  {
    path: '/terms-of-use',
    name: 'terms-of-use',
    component: () => import(/* webpackChunkName: "static" */ '../views/static/TermsOfUse.vue')
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import(/* webpackChunkName: "static" */ '../views/static/PrivacyPolicy.vue')
  },
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "static" */ `../views/static/Error404.vue`)
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, _from, savedPosition) {
    if (savedPosition) {
      return Promise.resolve(savedPosition)
    } else {
      const position = {}
      return new Promise(resolve => {
        // check if any matched route config has meta that requires scrolling to top
        if (to.matched.some(m => m.meta.scrollToPos)) {
          // coords will be used if no selector is provided,
          // or if the selector didn't match any element.
          if (to.meta.scrollToPos) {
            position.x = to.meta.scrollToPos.x
            position.y = to.meta.scrollToPos.y
          }
        }
        resolve(position)
      })
    }
  }
})

const routerPush = router.push
const routerReplace = router.replace

router.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) {
    return routerPush.call(this, location, onResolve, onReject)
  }

  try {
    return routerPush.call(this, location)
  } catch (error) {
    if (VueRouter.isNavigationFailure(error)) {
      return error
    }

    return Promise.reject(error)
  }
}
router.replace = function replace (location, onResolve, onReject) {
  if (onResolve || onReject) {
    return routerReplace.call(this, location, onResolve, onReject)
  }

  try {
    return routerReplace.call(this, location)
  } catch (error) {
    if (VueRouter.isNavigationFailure(error)) {
      return error
    }

    return Promise.reject(error)
  }
}

router.beforeEach(async (to, from, next) => {
  // Tracking subsequent page views https://developers.hubspot.com/docs/api/events/tracking-code
  let _hsq = window._hsq = window._hsq || []
  _hsq.push(['setPath', to.path])
  _hsq.push(['trackPageView'])

  const publicPages = [
    'privacy-policy',
    'terms-of-use',
    'ecreds',
    'about-us',
    'faq',
    'feedback',
    'homepage',
    'register',
    'register-complete',
    'confirm-registration',
    'forgot-password',
    'check-email',
    'reset-password',
    'reset-password-success',
    'register-by-invitation',
    'register-by-invitation-complete',
    'admin/login',
    'admin/register',
    'admin/forgot-password',
    'admin/reset-password',
    'admin/reset-password-success'
  ]
  if (!publicPages.includes(to.name) && !to.meta.isAdminRoute) {
    if (isLoggedIn()) {
      if (window.dataLayer && process.env.VUE_APP_GTM_ID) {
        Vue.gtm.dataLayer().push({ userId: retrieveUser('uid') })
      }
      next()
    } else {
      next({ name: 'homepage' })
    }
  } else if (!publicPages.includes(to.name) && to.meta.isAdminRoute) {
    if (!isAdminLoggedIn()) {
      next({ name: 'admin/login' })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
