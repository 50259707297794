<template>
  <div :class="`${alignClass} m-b-4x is-size-6`">
    {{ $t('version') }}: {{ version }} | {{ $t('last-updated') }}: {{ lastUpdated }}
  </div>
</template>

<script>
export default {
  name: 'StaticVersion',
  props: {
    alignClass: {
      type: String,
      default: 'has-text-right'
    }
  },
  i18n: {
    messages: {
      'en-US': {
        'version': 'Version',
        'last-updated': 'Last updated'
      },
      'zh-CN': {
        'version': '版本',
        'last-updated': '上次更新时间'
      }
    }
  },
  data () {
    return {
      version: process.env.VUE_APP_TOU_VERSION,
      lastUpdated: process.env.VUE_APP_TOU_DATE
    }
  }
}
</script>

<style scoped>

</style>
