<template>
  <VueRecaptcha
    ref="invisibleRecaptcha"
    recaptcha-host="www.recaptcha.net"
    :sitekey="recaptchaKey"
    :load-recaptcha-script="true"
    size="invisible"
    badge="hidden"
    @verify="onVerify" />
</template>

<script>
import VueRecaptcha from 'vue-recaptcha'

export default {
  name: 'Recaptcha',
  components: {
    VueRecaptcha
  },
  i18n: {
    messages: {
      'en-US': {
        'error': 'Invalid recaptcha token',
        'expired': 'Recaptcha token expired'
      },
      'zh-CN': {
        'error': 'Recaptcha令牌不正确',
        'expired': 'Recaptcha令牌已过期'
      }
    }
  },
  data () {
    return {
      recaptchaKey: process.env.VUE_APP_RECAPTCHA_KEY
    }
  },
  methods: {
    onVerify (token) {
      if (!token) {
        this.$emit('error', this.$t('error'))
      } else {
        this.$emit('verify', token)
      }
    },
    onExpired () {
      this.$emit('error', this.$t('expired'))
    },
    onError () {
      this.$emit('error', this.$t('error'))
    },
    execute () {
      this.$refs.invisibleRecaptcha.execute()
    },
    reset () {
      this.$refs.invisibleRecaptcha.reset()
    }
  }
}
</script>

<style lang="scss">
  .grecaptcha-badge {
    visibility: hidden;
  }

  .recaptcha-column {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
</style>
