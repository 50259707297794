<template>
  <div class="tou-notif-bar">
    <span class="m-r-2x">We have updated our Term of Use</span>
    <el-button
      size="mini"
      type="info"
      class="btn"
      @click="modalVisibility = true">
      Review
    </el-button>
    <el-button
      size="mini"
      type="info"
      class="btn"
      @click="handleAccept">
      Ok
    </el-button>

    <PpModal v-model="modalVisibility">
      <div class="has-background-white has-text-grey is-paddingless">
        <h1
          class="is-size-4 has-text-weight-bold has-text-secondary has-padding"
          style="padding-top: 20px">
          Terms Of Use
        </h1>
        <hr class="m-b-2x m-t-2x">
        <div style="max-height: 250px;overflow: auto; padding: 20px">
          <StaticVersion align-class="has-text-left" />
          <TouContent layout="modal" />
        </div>
        <hr class="m-b-2x m-t-2x">
        <div
          class="has-text-right has-padding"
          style="padding-bottom: 20px">
          <el-button
            class="button-secondary"
            size="small"
            style="min-width: 155px;"
            @click="handleAccept">
            Accept
          </el-button>
        </div>
      </div>
    </PpModal>
  </div>
</template>

<script>
import { retrieveUser } from '@/service/auth'

import StaticVersion from './StaticVersion'
import TouContent from './TouContent'

export default {
  name: 'TouNotifBar',
  components: {
    StaticVersion,
    TouContent
  },
  data () {
    return {
      modalVisibility: false
    }
  },
  mounted () {
    if (!window['__PRERENDER_INJECTED']) document.querySelector('body').classList.add('has-tou-bar')
  },
  beforeDestroy () {
    document.querySelector('body').classList.remove('has-tou-bar')
  },
  methods: {
    async handleAccept () {
      this.modalVisibility = false
      this.$store.dispatch('user/acceptTerm', { uid: retrieveUser('uid') })
        .then(({ outlets }) => {
          this.$store.dispatch('user/updateLocalStorage', outlets.details)
          this.$emit('accepted')
        })
        .catch((err) => {
          this.$store.commit(
            'SET_GLOBAL_MODAL',
            {
              show: true,
              content: err.message || (err.error && err.error.message) || ''
            }
          )
        })
    }
  }
}
</script>

<style lang="scss">
.tou-notif-bar {
  background-color: #4A4A4A;
  color: #fff;
  padding: .25rem 1rem;
  font-size: 0.875rem;

  .btn {
    background-color: #4A4A4A;
    border-color: #fff;
  }

  .has-padding {
    padding-left: 20px;
    padding-right: 20px
  }
}
</style>
