<template>
  <div>
    {{ $t('views.share.copyright') }} eBEDS {{ year }}.
    <router-link to="/terms-of-use">
      {{ $t('views.share.terms') }}
    </router-link>.
  </div>
</template>

<script>
export default {
  name: 'CopyRight',
  data () {
    return {
      year: new Date().getFullYear()
    }
  }
}
</script>

<style scoped>

</style>
