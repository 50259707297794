import Vue from 'vue'
import Vuex from 'vuex'
import { debug } from '@/plugins/util'

import quoteSelector from '@/store/modules/quoteSelector'
import quote from '@/store/modules/quote'
import app from '@/store/modules/app'
import user from '@/store/modules/user'
import meta from '@/store/modules/meta'
import searchMany from '@/store/modules/searchMany'
import searchOne from '@/store/modules/searchOne'
import bookingPolicy from '@/store/modules/bookingPolicy'
import dashboard from './modules/dashboard'
import order from './modules/order'
import admin from './modules/admin'
import searchFilters from '@/store/modules/searchFilters'
import google from '@/store/modules/google'
import bulkPayment from '@/store/modules/bulkPayment'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: debug,
  modules: {
    bulkPayment,
    quoteSelector,
    quote,
    app,
    meta,
    user,
    searchMany,
    searchOne,
    bookingPolicy,
    dashboard,
    order,
    admin,
    searchFilters,
    google
  },
  state: {
    baseUrl: process.env.VUE_APP_BASE_URL || 'https://ebeds.co',
    globalModal: {
      show: false,
      content: '',
      newSearchBtn: false,
      hotelPageBtn: false,
      showForgotLink: false,
      okBtn: true,
      refresh: false,
      showIcon: true
    },
    globalErrorModal: {
      show: false,
      content: '',
      title: ''
    },
    loginDialogVisible: false,
    fullScreenLoaderVisible: false,
    fullScreenLoaderTitle: '',
    zumata: {
      tel: '+27 83 391 5377',
      tel2: '+27 21 012 5858',
      email: 'support@ebeds.co',
      whatsapp: 'https://api.whatsapp.com/message/LTVAIVAJMKFRB1'
    },
    inNightlyAvgRate: false,
    cancellationBuffer: 3,
    hotelNames: ['doubletree', 'hilton', 'hampton', 'embassy suites', 'canopy', 'signia', 'waldorf', 'astoria', 'lxr', 'conrad', 'hltn', 'curio', 'dtree', 'dbl', 'dbltree', 'dt', 'dtbh', 'tapestry', 'es', 'tempo', 'motto', 'hgi', 'hmptn', 'tru', 'homewood', 'home2', 'hampt', 'holiday inn', 'kimpton', 'regent', 'hotel indigo', 'crowne plaza', 'hualuxe', 'holiday inn express', 'hi express', 'hi exp', 'intercontinental', 'voco', 'staybridge', 'candlewood', 'avid', 'even', 'indigo', 'marriott', 'courtyard', 'sheraton', 'aloft', 'springhill', 'ritz', 'ritz carlton', 'st regis', 'edition', 'luxury collection', 'w hotels', 'jw', 'delta hotels', 'le meridien', 'westin', 'autograph collection', 'design hotels', 'renaissance hotels', 'tribute', 'gaylord', 'fp', 'four points', 'springhill suites', 'protea hotels', 'fairfield', 'fis', 'ac hotels', 'aloft hotels', 'moxy', 'residence inn', 'element', 'renaissance', 'residence', 'towneplace suites', 'towneplace', 'hyatt', 'hyatt place', 'hyatt house', 'hyatt regency', 'grand hyatt', 'andaz', 'hyatt centric', 'park hyatt', 'miraval', 'alila']
  },
  mutations: {
    SET_GLOBAL_MODAL: (state, payload) => {
      state.globalModal = payload
    },
    SET_GLOBAL_ERROR_MODAL: (state, payload) => {
      state.globalErrorModal = payload
    },
    SET_LOGIN_DIALOG_VISIBLE: (state, payload) => {
      state.loginDialogVisible = payload
    },
    SHOW_FULLSCREEN_LOADER: (state, payload) => {
      state.fullScreenLoaderVisible = true
      state.fullScreenLoaderTitle = payload
    },
    HIDE_FULLSCREEN_LOADER: (state, payload) => {
      state.fullScreenLoaderVisible = false
      state.fullScreenLoaderTitle = ''
    }
  },
  actions: {
    setGlobalErrorModal: ({ commit }, payload) => {
      commit('SET_GLOBAL_ERROR_MODAL', payload)
    }
  }
})
