import api from '@/service/api'
import { debug } from '@/plugins/util'
import orderColumns from './orderColumns'

const ORDERS_COLS_KEY = `${process.env.VUE_APP_PREFIX_NAME}orderColumns`

const initOrderDetails = Object.freeze({
  agent: {},
  contactPerson: {},
  booking: {},
  property: {},
  payment: {},
  order: {},
  isContinuePulling: undefined,
  v3Status: null
})

const state = {
  orderDetails: JSON.parse(JSON.stringify(initOrderDetails)),
  orders: {
    summary: { total: undefined, showing: [] },
    rows: [],
    cols: [],
    sort: ''
  },
  filters: {
    dateRangeType: '',
    dateRange: {
      checkInDate: '',
      checkOutDate: ''
    },
    orderReference: '',
    hotelName: '',
    guestName: '',
    users: [],
    orderStatuses: [],
    agentReference: '',
    paymentCurrency: '',
    agentEarningStatus: []
  },
  agentOptions: [],
  isFetching: false
}

const mutations = {
  SET_DATA: (state, payload) => {
    if (debug) console.log('order triggered with', payload)
    const keys = Object.keys(payload)
    keys.forEach((key) => {
      state[key] = payload[key]
    })
  },
  CLEAR_ORDER_DETAILS: (state) => {
    state['orderDetails'] = JSON.parse(JSON.stringify(initOrderDetails))
  },
  SET_ORDER_FILTERS: (state, payload) => {
    if (debug) console.log('orderFilter triggered with', payload)
    const keys = Object.keys(payload)
    keys.forEach((key) => {
      state.filters[key] = payload[key]
    })

    // Save filters to session storage
    sessionStorage.setItem(`${process.env.VUE_APP_PREFIX_NAME}orderFilters`, JSON.stringify(state.filters))
  },
  SET_AGENT_OPTIONS: (state, payload) => {
    if (debug) console.log('agentOptions triggered with', payload)
    state.agentOptions = payload
  },
  SET_ORDER_DETAILS: (state, payload) => {
    if (debug) console.log('setOrderDetails triggered with', payload)
    state.orderDetails = { ...state.orderDetails, ...payload }
  },
  SET_ORDERS (state, nV) {
    state.orders.summary = nV.summary
    state.orders.rows = nV.rows
  },
  SET_ORDERS_COLS (state, nV) {
    if (debug) console.log('setOrderCols triggered with', nV)
    if (nV != null) state.orders.cols = nV
  }
}

const actions = {
  retrieveOrderList ({ commit }, params) {
    commit('SET_DATA', { isFetching: true })
    commit('SET_ORDERS', {
      summary: {
        total: undefined,
        showing: []
      },
      rows: []
    })
    let url
    if (params.superAdmin) {
      url = '/admin/order/list'
    } else {
      url = '/order/list'
    }
    return new Promise((resolve, reject) => {
      api.get(url, { params }).then(
        ({ outlets, meta }) => {
          commit('SET_ORDERS', outlets.list)
          commit('SET_DATA', { isFetching: false })
          resolve({ outlets, meta })
        }
      ).catch((err) => {
        commit('SET_DATA', { isFetching: false })
        reject(err)
      })
    })
  },
  retrieveOrder ({ commit }, orderToken) {
    commit('SET_DATA', { isFetching: true })
    return new Promise((resolve, reject) => {
      api.get(`/order/one/${orderToken}`).then(
        ({ outlets, meta }) => {
          commit('SET_ORDER_DETAILS', outlets)
          commit('SET_DATA', { isFetching: false })
          resolve({ outlets, meta })
        }
      ).catch((err) => {
        commit('SET_DATA', { isFetching: false })
        reject(err)
      })
    })
  },
  retrieveOrderByReference ({ commit }, params) {
    commit('SET_DATA', { isFetching: true })
    let url
    if (params.superAdmin) {
      url = '/admin/order/reference'
    } else {
      url = '/order/reference'
    }
    return new Promise((resolve, reject) => {
      api.get(`${url}/${params.orderReference}`).then(
        ({ outlets, meta }) => {
          commit('SET_ORDER_DETAILS', outlets.details)
          commit('SET_DATA', { isFetching: false })
          resolve({ outlets, meta })
        }
      ).catch((err) => {
        commit('SET_DATA', { isFetching: false })
        reject(err)
      })
    })
  },
  sendEmail ({ commit }, payload) {
    commit('SET_DATA', { isFetching: true })
    let url
    if (payload.superAdmin) {
      url = '/admin/order/sendEmail'
    } else {
      url = '/order/sendEmail'
    }
    return new Promise((resolve, reject) => {
      api.post(url, payload).then(
        ({ outlets, meta }) => {
          commit('SET_DATA', { isFetching: false })
          resolve({ outlets, meta })
        }
      ).catch((err) => {
        commit('SET_DATA', { isFetching: false })
        reject(err)
      })
    })
  },
  cancelOrder ({ commit }, payload) {
    commit('SET_DATA', { isFetching: true })
    let url
    if (payload.superAdmin) {
      url = '/admin/order/cancel'
    } else {
      url = '/order/cancel'
    }
    return new Promise((resolve, reject) => {
      api.post(url, payload).then(
        ({ outlets, meta }) => {
          commit('SET_DATA', { isFetching: false })
          resolve({ outlets, meta })
        }
      ).catch((err) => {
        commit('SET_DATA', { isFetching: false })
        reject(err)
      })
    })
  },
  exportXLS (_, params) {
    let url
    if (params.superAdmin) {
      url = '/admin/order/xls'
    } else {
      url = '/order/xls'
    }
    return api.post(url, {},
      {
        headers: {
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
        params: { ...params },
        responseType: 'blob'
      }
    )
  },
  getVoucherOrInvoice (_, payload) {
    let url
    if (payload.superAdmin) {
      url = '/admin/order/getVoucherOrInvoice'
    } else {
      url = '/order/getVoucherOrInvoice'
    }
    return api.post(url, payload,
      {
        responseType: 'arraybuffer',
        headers: {
          'Accept': 'application/pdf'
        }
      }
    )
  },
  /**
   * Set Order Columns
   * @param {*} param0
   * @param {[]String} nV ['cost', 'orderReference', ...]
   */
  setOrdersCols ({ commit }, nV) {
    if (nV != null) localStorage.setItem(ORDERS_COLS_KEY, JSON.stringify(nV))
    commit('SET_ORDERS_COLS', nV)
  },
  loadOrdersCols ({ commit }) {
    const defaultCols = orderColumns.defaultColumns
    let k
    try {
      k = JSON.parse(localStorage.getItem(ORDERS_COLS_KEY))
    } catch (err) {
      console.error('Invalid saved cols, reset to default cols')
    }
    if (k == null || (Array.isArray(k) && k.length === 0)) {
      k = defaultCols
      localStorage.setItem(ORDERS_COLS_KEY, JSON.stringify(k))
    }
    commit('SET_ORDERS_COLS', k)
  },
  editMetadata (_, payload) {
    const { orderReference, ...rest } = payload
    return api.put(`/order/metadata/${orderReference}`, rest)
  }
}
const getters = {
  getAgentByUid: (state) => (uid) => {
    return state.agentOptions.find(agent => agent.uid === uid)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
