<template>
  <!-- <div :style="[ visibility ]"> -->
  <div v-loading="isLoading">
    <div
      class="empty-state"
      :style="[ visibility ]">
      <div v-html="message" />
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmptyState',
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: 'It\'s empty'
    }
  },
  computed: {
    visibility () {
      return this.isLoading ? { visibility: 'hidden' } : {}
    }
  }
}
</script>
<style lang="scss">
  @import "../styles/bulma-variables";

  .empty-state {
    padding: $bleed*5;
    border: 1px dashed $color-9;
    text-align: center;
    font-weight: bold;
    color: $grey-light;
  }
</style>
