import api from '@/service/api'

const state = {
  paymentMethod: 'creditcard',
  totalAmount: {
    value: 0,
    currency: ''
  },
  convertedTotalAmount: {
    value: 0,
    currency: ''
  },
  finalAdjustments: [],
  finalAdjustmentAmount: {
    value: 0,
    currency: 0
  },
  list: [],
  paymentUrl: '',
  isFetching: false,
  error: undefined,
  continuePulling: false,
  orders: [],
  additionalFee: undefined
}

const getters = {
  totalAmount: (state, getters) => {
    if (state.paymentMethod === 'walletUSD') {
      return state.convertedTotalAmount
    }
    return state.totalAmount
  }
}

const mutations = {
  SET_DATA: (state, payload) => {
    const keys = Object.keys(payload)
    keys.forEach((key) => {
      state[key] = payload[key]
    })
  }
}

const actions = {
  enquiry: (_, payload) => {
    return new Promise((resolve, reject) => {
      _.commit('SET_DATA', { isFetching: true })
      // const payloadPostBook = JSON.parse(localStorage.getItem(`${process.env.VUE_APP_PREFIX_NAME}postBook`))
      // payload.ckToken = payloadPostBook.ckToken
      api.post('/order/enquiry', payload).then(({ outlets, meta }) => {
        _.commit('SET_DATA', { isFetching: false, ...outlets })
        resolve({ outlets, meta })
      }).catch((err) => {
        _.commit('SET_DATA', {
          isFetching: false,
          error: { message: err.error.message || err.message, ...err.error }
        })
        reject(err)
      })
    })
  },
  bulkPayment: (_, payload) => {
    return api.post('/order/bulkPayment', payload)
  },
  retrievePaymentStatus: ({ commit }, paymentToken) => {
    return new Promise((resolve) => {
      api.get(`/order/bulkPayment/status/${paymentToken}`).then(({ outlets, meta }) => {
        commit('SET_DATA', { ...outlets })
        resolve({ outlets, meta })
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
