<template>
  <div class="has-background-preview has-text-white has-text-centered">
    ––   PREVIEW MODE   ––
  </div>
</template>

<script>
export default {
  name: 'PreviewMode'
}
</script>

<style scoped>
.has-background-preview {
  background-color: #FF3860;
  font-size: 16px;
  padding: 8px;
}
</style>
