<template>
  <div class="app-header">
    <PreviewMode v-if="$store.state.meta.previewMode" />
    <div v-if="showNotifBar">
      <TouNotifBar
        v-if="notifBarVisibility"
        @accepted="showNotifBar = false" />
    </div>
    <nav
      class="navbar"
      role="navigation"
      aria-label="main navigation">
      <div class="container is-fluid">
        <div class="navbar-brand">
          <router-link
            :to="defaultLogoLink"
            class="navbar-item">
            <TheLogo />
          </router-link>

          <a
            role="button"
            class="navbar-burger burger"
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasic"
            @click="handleBurgerClicked">
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </a>
        </div>
        <div
          :class="{'is-active':menuActive}"
          class="navbar-menu">
          <div class="navbar-end has-text-weight-bold">
            <router-link
              v-if="isLoggedIn"
              to="/dashboard"
              class="navbar-item">
              {{ $t('views.TheHeader.find-hotel') }}
            </router-link>
            <a
              v-if="isESafariLinkShow"
              class="navbar-item"
              href="https://ebeds.mozio.com/en-us/?currency=ZAR"
              target="_blank"
              rel="noreferrer noopener">eTransfers</a>
            <a
              v-if="isGroupsLinkShow"
              class="navbar-item"
              href="https://form.typeform.com/to/KywB3sy4"
              target="_blank"
              rel="noreferrer noopener">Groups</a>
            <template v-if="isLoggedIn">
              <router-link
                v-if="$hasRole(['CLIENT_ADMIN', 'CLIENT_AGENT_SB', 'CLIENT_AGENT_SBC', 'CLIENT_FINANCE', 'CLIENT_CS'])"
                to="/my-bookings"
                class="navbar-item">
                {{ $t('views.TheHeader.manage-bookings') }}
              </router-link>
              <span class="navbar-item">
                <el-dropdown
                  :hide-on-click="true"
                  @command="handleDropdownMenu">
                  <span class="el-dropdown-link">
                    {{ displayName }} <i class="el-icon-caret-bottom el-icon--right" />
                  </span>
                  <el-dropdown-menu
                    slot="dropdown"
                    class="el-nav-dropdown">
                    <el-dropdown-item :command="{link:'my-account'}">{{ $t('views.TheHeader.my-account') }}</el-dropdown-item>
                    <!--                <el-dropdown-item :command="{link:'balance'}">{{$t('views.TheHeader.balance')}}</el-dropdown-item>-->
                    <!--                    <el-dropdown-item-->
                    <!--                      v-for="language in unSelectLanguages"-->
                    <!--                      :key="language.value"-->
                    <!--                      :command="{locale:language.value}">{{ language.label }}</el-dropdown-item>-->
                    <el-dropdown-item :command="{logout:true}">{{ $t('views.TheHeader.logout') }}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </span>
            </template>
            <template v-else>
              <!-- <router-link
                to="/ecreds"
                class="navbar-item">
                eCREDS
              </router-link>-->
              <!--              <span class="navbar-item">-->
              <!--                <el-dropdown-->
              <!--                  :hide-on-click="true"-->
              <!--                  @command="handleLocaleChanged">-->
              <!--                  <span class="el-dropdown-link">-->
              <!--                    {{ selectedLocaleLabel }} <i class="el-icon-caret-bottom el-icon&#45;&#45;right"/>-->
              <!--                  </span>-->
              <!--                  <el-dropdown-menu-->
              <!--                    slot="dropdown"-->
              <!--                    class="el-nav-dropdown">-->
              <!--                    <el-dropdown-item-->
              <!--                      v-for="(language, index) in $languages"-->
              <!--                      :key="index"-->
              <!--                      :command="language.value">{{ language.label }}-->
              <!--                    </el-dropdown-item>-->
              <!--                  </el-dropdown-menu>-->
              <!--                </el-dropdown>-->
              <!--              </span>-->
              <a
                class="navbar-item has-text-secondary"
                @click="loginDialogVisible=true">
                {{ $t('views.TheHeader.login') }}
              </a>
              <!-- TEMP: hide register entrance -->
              <!-- <router-link
                to="register"
                class="navbar-item has-text-secondary">
                {{ $t('views.TheHeader.register') }}
              </router-link> -->
            </template>
          </div>
        </div>
      </div>
    </nav>
    <el-dialog
      :visible.sync="loginDialogVisible"
      :close-on-click-modal="false"
      custom-class="login-dialog"
      append-to-body
      @closed="loginDialogVisible=false">
      <LoginForm class="login-form" />
      <!-- TEMP: hide register entrance -->
      <!-- <hr class="is-marginless">
      <div class="dialog-footer">
        {{ $t('views.TheHeader.No account yet') }}
        <router-link
          :to="{ name: 'register' }"
          class="has-text-secondary">
          {{ $t('views.TheHeader.register') }}
        </router-link>
      </div> -->
    </el-dialog>
  </div>
</template>

<script>
import { retrieveUser, retrieveAgent, isLoggedIn, doLogout } from '@/service/auth'
import LoginForm from '@/views/customer/LoginForm'
import TheLogo from '@/components/TheLogo'
import TouNotifBar from '@/components/TouNotifBar'
import PreviewMode from '@/components/PreviewMode'

export default {
  name: 'TheHeader',
  components: {
    LoginForm,
    TheLogo,
    TouNotifBar,
    PreviewMode
  },
  data () {
    return {
      menuActive: false,
      showNotifBar: false
    }
  },
  computed: {
    locale () {
      return retrieveAgent('defaultLocale')
    },
    isLoggedIn () {
      return isLoggedIn()
    },
    displayName () {
      return retrieveUser('displayName')
    },
    touVersion () {
      return retrieveUser('touVersion')
    },
    defaultLogoLink () {
      if (this.isLoggedIn) return { name: 'dashboard' }
      return { name: 'homepage' }
    },
    selectedLocaleLabel () {
      for (let i in this.$languages) {
        if (this.$languages[i].value === this.$i18n.locale) {
          return this.$mq.mobile ? this.$languages[i].short : this.$languages[i].label
        }
      }

      return 'en-US'
    },
    unSelectLanguages () {
      return this.$languages.filter((lang) => lang.value !== this.$i18n.locale)
    },
    // isUserLogin () {
    //   //FIXME mock, change it later
    //   const allowUnAuthRoutes = [
    //     'register',
    //     'confirm-registration',
    //     'register-complete',
    //     'forgot-password'
    //   ]
    //   return !allowUnAuthRoutes.includes(this.$route.name) && this.mx_user
    // },
    loginDialogVisible: {
      get () {
        return this.$store.state.loginDialogVisible
      },
      set (value) {
        this.$store.commit('SET_LOGIN_DIALOG_VISIBLE', value)
      }
    },
    notifBarVisibility () {
      if (this.isLoggedIn) {
        if (this.touVersion.value !== process.env.VUE_APP_TOU_VERSION) return true
      }
      return false
    },
    isGroupsLinkShow () {
      return process.env.VUE_APP_SHOW_GROUPS_LINK === 'true'
    },
    isESafariLinkShow () {
      return process.env.VUE_APP_SHOW_ESAFARI_LINK === 'true'
    }
  },
  watch: {
    '$route' (to, from) {
      if (to !== from) {
        this.loginDialogVisible = false
      }
    }
  },
  mounted () {
    if (this.isLoggedIn) {
      if (!this.touVersion ||
        (this.touVersion && this.touVersion.value !== process.env.VUE_APP_TOU_VERSION)) this.showNotifBar = true
    }
  },
  methods: {
    handleBurgerClicked () {
      this.menuActive = !this.menuActive
    },
    handleLocaleChanged (locale) {
      this.$i18n.locale = locale
      this.$store.commit('user/SET_LOCALE', locale)
      window.location.reload()
    },
    handleDropdownMenu (command) {
      const keys = Object.keys(command)
      if (keys.includes('link')) {
        this.$router.push({ name: command.link })
      } else if (keys.includes('logout')) {
        this.$store.commit('meta/SET_META_ACTION', {
          user: null,
          usrToken: null
        })
        doLogout()
      } else if (keys.includes('locale')) {
        this.handleLocaleChanged(command.locale)
      }
    }
  }
}
</script>

<style lang="scss">
  .login-dialog {
    max-width: 600px;
    min-width: 320px;

    .login-form {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }

    .el-dialog__header {
      padding: 0;
    }

    .el-dialog__body {
      padding: 0;
    }

    .dialog-footer {
      padding: 1.5rem;
    }
  }
</style>
