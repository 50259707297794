import Vue from 'vue'
import VueI18n from 'vue-i18n'
// import store from '@/store'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import cookies from 'js-cookie'

Vue.use(VueI18n)

// /**
//  * load json file from @/locales/*.json
//  */
function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

const messages = loadLocaleMessages()

// element ui locale
Object.assign(messages['en-US'], enLocale)
Object.assign(messages['zh-CN'], zhLocale)

Vue.prototype.$languages = Object.keys(messages).map(language => ({
  label: messages[language]._name,
  short: messages[language]._short,
  value: language
}))

// store.commit('user/SET_LOCALE', null)
// ebeds only use en-US
cookies.set(`${process.env.VUE_APP_PREFIX_NAME}locale`, 'en-US')
export default new VueI18n({
  locale: 'en-US',
  fallbackLocale: 'en-US',
  silentTranslationWarn: process.env.NODE_ENV === 'production',
  messages,
  missing: () => ''
})
