import cookies from 'js-cookie'
import { debug } from '@/plugins/util'

const state = {
  'version': null,
  'previewMode': false,
  'brandName': null,
  'appCode': null,
  'affiliateCode': null,
  'country': null,
  'sourceMarket': null,
  'currency': 'SGD',
  'locale': 'en-US',
  'path': null,
  'reqId': null,
  'devMode': false,
  'nights': null,
  'roomCount': null,
  'targetCcy': null,
  'usrToken': null
}

const mutations = {
  SET_META_ACTION: (state, payload) => {
    if (debug) console.log('setMetaAction triggered with', payload)
    if (payload) {
      const keys = Object.keys(payload)
      keys.forEach((key) => {
        state[key] = payload[key]
      })
      if (payload.currency) {
        cookies.set(`${process.env.VUE_APP_PREFIX_NAME}prfs`, { currency: payload.currency }, { expires: 14 })
      }

      if (payload.hasOwnProperty('user')) {
        /* const stringify = JSON.stringify({
          ...JSON.parse(localStorage.getItem(process.env.VUE_APP_AUTH_STORAGE_KEY)),
          ...payload.user
        })
        localStorage.setItem(process.env.VUE_APP_AUTH_STORAGE_KEY, stringify) */

        // In the firebox private window mode, newly opened windows cannot share localStorage. Therefore, user auth data is best stored in a cookie (fix bug AP-529)
        const stringify = JSON.stringify({
          ...cookies.getJSON(process.env.VUE_APP_AUTH_STORAGE_KEY),
          ...payload.user
        })
        cookies.set(process.env.VUE_APP_AUTH_STORAGE_KEY, stringify, { expires: 14 })
      }
    }
  },
  CLEAR_META_ACTION: (state) => {
    if (debug) console.log('clearMetaAction triggered')
    state.version = null
    state.previewMode = false
    state.brandName = null
    state.appCode = null
    state.affiliateCode = null
    state.country = null
    state.sourceMarket = null
    state.currency = null
    state.locale = null
    state.path = null
    state.reqId = null
    state.devMode = false
    state.nights = null
    state.roomCount = null
    state.targetCcy = null
    state.usrToken = null
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
