import { getCountryByPhone } from './countriesHelper'

export const NAME_PATTERN = /^[a-z]+(\s+[a-z]+)*$/ig
export const debug = process.env.NODE_ENV !== 'production'

/**
 * Transform object into query params.
 * @param {object} obj
 */
export const serialize = (obj) => {
  const tmp = JSON.parse(JSON.stringify(obj))
  let str = '?' + Object.keys(tmp).reduce((a, k) => {
    if (obj[k] !== undefined || obj[k] !== null) {
      a.push(k + '=' + encodeURIComponent(obj[k]))
    }
    return a
  }, []).join('&')
  return str
}

export const deserialize = (strValue) => {
  return JSON.parse('{"' + decodeURI(strValue.replace(/&/g, '","').replace(/=/g, '":"')) + '"}')
}

export const getUrlParams = (url) => {
  const hashes = url.slice(url.indexOf('?') + 1).split('&')
  const params = {}
  hashes.map(hash => {
    const [key, val] = hash.split('=')
    params[key] = decodeURIComponent(val)
  })
  return params
}

export const phoneCountryCode = (contactNo) => {
  const prefix = contactNo.split(' ')[0]
  const normalized = prefix.replace(/\(/g, '').replace(/\)/g, '')
  return getCountryByPhone(normalized)
}

export const getPhoneNumber = (contactNo = '') => {
  const phoneNumber = contactNo.split(' ')[1]
  return phoneNumber
}

// download
export const download = (data, headers) => {
  const contentDisposition = headers['content-disposition']
  const filename = contentDisposition.replace('attachment; filename=', '')
  const a = document.createElement('a')
  document.body.appendChild(a)
  a.style = 'display: none'
  const url = window.URL.createObjectURL(data)
  a.href = url
  a.download = filename
  a.click()
  window.URL.revokeObjectURL(url)
  document.body.removeChild(a)
}

export const getOrderEventName = (eventType, orderStatus, event) => {
  const pendingFromSupplier = [
    'booking.prebook',
    'postbook',
    'prebook',
    'v3_cancelled',
    'booking.v3_pending_supplier',
    'booking.v3_failed'
  ]
  const pendingFromPaymentGateway = [
    'booking.v3_pending_confirmed',
    'v3_confirmed',
    'v3_pending_confirmed'
  ]
  if (pendingFromSupplier.includes(eventType)) {
    return 'Pending from suppliers'
  } else if (pendingFromPaymentGateway.includes(eventType)) {
    return 'Pending confirmation from payment gateway'
  } else if (event.type === 'sending_mail') {
    return event.eventName
  } else {
    switch (eventType) {
    case 'booking.postconfirm':
      return 'Order has been confirmed'
    case 'booking.postbook':
      return 'Confirming booking from suppliers'
    case 'booking.created':
      return 'Booking created'
    case 'payment.refused':
      return 'Payment has been refused from payment gateway'
    case 'payment.paid':
      return 'Received payment confirmation from payment gateway'
    case 'booking.v3_failed':
    case 'v3_failed':
      return 'Failed to book from suppliers'
    case 'booking.v3_cancelled':
    case 'v3_cancelled':
      return 'Cancelled the booking from supplier side'
    case 'booking.auto_cancelled':
      return 'Booking was automatically cancelled'
    case 'order.cancel_request':
      return `Order canceled by ${event.data.cancelledBy && event.data.cancelledBy.name}`
    case 'payment.created':
      return 'Payment in progress'
    case 'payment.cancel':
      return 'Failed to book from suppliers'
    case 'payment.pending':
      return 'Awaiting payment authorization'
    case 'payment.authorized':
      return 'Verifying Adyen authorization'
    case 'payment.capture_in_progress':
      return 'Capturing payment from the payment gateway'
    case 'booking.v3_confirmed':
      return 'Booking confirmed from supplier'
    case 'payment.capture':
    case 'payment.succeeded':
      return 'Order confirmed'
    case 'payment.capture_failed':
      return 'Failed to capture from Payment Gateway'
    case 'payment.refund_in_progress':
      return 'Refund in Progress'
    case 'payment.refund_failed':
      return 'Failed to Refund'
    case 'payment.refund':
      if (orderStatus === 'REFUND_FAILURE') {
        return 'Failed to Refund'
      }
      return 'Cancelled successfully'
    case 'payment.lookupTransaction':
      return 'Lookup payment status'
    case 'order.edited_agent_earning_status':
      return `Commission status changed to ${event.data.agentEarningStatus}, remarks: “${event.data.agentEarningStatusRemarks}”`
    case 'order.confirm_hold_booking':
      return 'Confirm hold booking'
    case 'order.confirm_hold_booking_failed':
      return 'Failed to confirm hold booking'
    case 'order.auto_fail':
      return 'Order automatically marked as failed'
    case 'order.updatedManually':
      return event.data.insertLog
    case 'order.updated':
      return `Order updated (${Object.keys(event.data)})`
    default: return 'Processing on checkout page'
    }
  }
}

export const getOrderEventDescription = (eventType, orderStatus) => {
  switch (eventType) {
  case 'order.created':
    return 'The order has been created (created a record in database)'
  case 'booking.created':
    return 'The booking has been created (created a record in database)'
  case 'booking.prebook':
  case 'prebook':
    return 'Call v3 API to do "Prebook"'
  case 'payment.created':
    return 'The payment has been created (created a record in database)'
  case 'payment.pending':
    return 'Waiting for the client to authorize the payment on Adyen'
  case 'payment.authorized':
    return 'Adyen authorization is being verified'
  case 'payment.refused':
    return 'Payment was refused.'
  case 'payment.paid':
    return 'The payment has been authorized and confirmed'
  case 'booking.postbook':
  case 'postbook':
    return 'Call v3 API to do "Postbook" (to confirm with supplier that user has paid the amount)'
  case 'booking.v3_pending_supplier':
    return 'Pending confirmation from supplier'
  case 'v3_confirmed':
  case 'v3_pending_confirmed':
  case 'booking.v3_confirmed':
  case 'booking.v3_pending_confirmed':
    return 'The supplier has confirmed the booking'
  case 'booking.v3_cancelled':
  case 'v3_cancelled':
    return 'The booking has been cancelled on supplier side'
  case 'booking.v3_failed':
  case 'v3_failed':
  case 'v3_pending_failed':
  case 'booking.v3_pending_failed':
    return 'Supplier failed to confirm the booking'
  case 'booking.auto_cancelled':
    return 'The booking has already passed the payment deadline and was auto-cancelled'
  case 'payment.capture_in_progress':
    return 'The payment gateway is being notified for the payment capture'
  case 'payment.cancel':
    return 'The payment has been cancelled before captured'
  case 'payment.capture':
  case 'payment.succeeded':
    return 'The payment has been captured from payment gateway'
  case 'payment.capture_failed':
    return 'The payment cannot be captured'
  case 'payment.refund_in_progress':
    return 'Notified payment gateway to refund the payment'
  case 'payment.refund_failed':
    return 'The payment gateway failed to refund the payment'
  case 'payment.refund':
    if (orderStatus === 'REFUND_FAILURE') {
      return `The payment gateway failed to refund the payment`
    }
    return 'Cancelled & refunded successfully'
  case 'booking.postconfirm':
    return 'Call v3 api to do "confirm" hold booking'
  case 'order.confirm_hold_booking':
    return 'Call api v3 to confirm hold booking'
  case 'order.confirm_hold_booking_failed':
    return 'Failed to call api v3 to confirm hold booking'
  case 'order.auto_fail':
    return 'The order was automatically marked as failed'
  default: return '-'
  }
}

export const useGeoRadiusRegion = (regionId) => {
  const useGeoRadiusRegions = [2609]
  return useGeoRadiusRegions.indexOf(Number(regionId)) !== -1
}

function stopObserve (element) {
  /**
   * If select is hidden, stop call handleResize
   */
  const isElementSelect = element.componentName === 'ElSelect'
  const isReferenceExist = !!this.$refs['reference']
  return isElementSelect && !isReferenceExist
}

/**
 * This methods is for adding a debounce to the resize listener method of the element ui component
 * @param {*} element element-ui component
 * @param {String} methodName method name in the methods of the component
 */
export const resizeObserverPatch = (element, methodName) => {
  try {
    const oldResizeObserver = element.methods[methodName]
    if (!oldResizeObserver) return false

    element.methods[methodName] = function () {
      if (window.requestAnimationFrame) {
        window.requestAnimationFrame(() => {
          const isStoppingObserve = stopObserve.call(this, element)
          !isStoppingObserve && oldResizeObserver.call(this)
        })
      } else {
        oldResizeObserver.call(this)
      }
    }
  } catch (_err) {
    return false
  }
}
