import axios from 'axios'
import Qs from 'qs'
import store from '@/store'
import { retrieveUser, retrieveAgent, retrieveAdmin, doLogout } from './auth'
import { debug } from '@/plugins/util'
import i18n from '@/i18n'

export const CancelToken = axios.CancelToken
export const isCancel = axios.isCancel
export const axiosInst = axios
const inst = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 60 * 1000, // 60 seconds to wait for server timeout
  maxRedirects: 1,
  paramsSerializer: function (params) {
    return Qs.stringify(params, { arrayFormat: 'brackets' })
  }
})

/**
 * Attach customer token on all api call
 */
inst.interceptors.request.use((config) => {
  if (config.url.indexOf('/admin/') > -1) {
    let adminToken = retrieveAdmin('usrToken')
    if (typeof adminToken === 'string') {
      config.headers = {
        'authorization': `Zumata ${adminToken}`,
        ...config.headers
      }
    }
  } else {
    let usrToken = retrieveUser('usrToken')
    if (typeof usrToken === 'string') {
      config.headers = {
        'authorization': `Zumata ${usrToken}`,
        ...config.headers
      }
    }

    let accessKey = retrieveAgent('accessKey')
    if (typeof accessKey === 'string') {
      config.headers = {
        ...config.headers,
        'x-access-key': accessKey
      }
    }

    if (process.env.VUE_APP_ENV === 'test') {
      config.headers = {
        ...config.headers,
        'x-client-app': 'e2e'
      }
    }
  }

  config.params = {
    ...config.params,
    'locale': store.state.user.locale
  }
  return config
})

inst.interceptors.response.use((response) => {
  if (response.data.meta && response.data.meta.version.indexOf('admin') < 0) {
    store.commit('meta/SET_META_ACTION', response.data.meta)
  } else if (response.data.meta && response.data.meta.version.indexOf('admin') > -1) {
    store.commit('admin/SET_META', response.data.meta)
  }
  if (response.headers.hasOwnProperty('content-disposition')) {
    return response
  }
  return response.data
}, (error) => {
  if (debug) console.log('request error:', error)
  // store.commit('SET_GLOBAL_MODAL', {
  //   show: true,
  //   content: error.message
  // })
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    // if (error.response.data.meta) {
    //   store.commit('meta/SET_META_ACTION', error.response.data.meta)
    // }

    const reason = {
      ...error.response.data,
      status: error.response.status
    }

    const shouldLogout = (e = {}) => {
      const { error = {} } = e

      if (['app_access_key_error'].includes(error.type)) {
        return true
      }
      if (['Invalid Authorization header'].includes(error.message)) {
        return true
      }

      return false
    }

    if (shouldLogout(reason)) {
      doLogout()
      return
    }

    return Promise.reject(reason)
  } else if (error.request && (error.request.message || error.request.error)) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    return Promise.reject(error.request)
  } else {
    if (debug) console.log('TIMEOUT')
    // for timeout request
    if (error.message.toLowerCase().indexOf('timeout') > -1 &&
        (
          error.config.url.indexOf('/search') < 0 &&
          error.config.url.indexOf('/georadius') < 0 &&
          error.config.url.indexOf('/properties/rooms') < 0 &&
          error.config.url.indexOf('/booking/checkout') < 0 &&
          error.config.url.indexOf('/properties/rooms') < 0
        )) {
      store.commit('SET_GLOBAL_MODAL', {
        show: true,
        oKBtn: true,
        title: i18n.t('app.error.timeout.title'),
        content: i18n.t('app.error.timeout.content')
      })
    }
    // Something happened in setting up the request that triggered an Error
    return Promise.reject(error)
  }
})

export default inst
