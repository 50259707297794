<template>
  <div class="footer">
    <div class="footer-top">
      <i />
      <i />
      <i />
      <i />
      <i />
    </div>
    <div class="footer-menu">
      <router-link
        to="/about-us"
        class="footer-link">
        {{ $t('views.TheHeader.why-us') }}
      </router-link>
      <!--      <router-link to="/ecreds"  class="footer-link">eCREDS</router-link>-->
      <router-link
        to="/feedback"
        class="footer-link">
        {{ $t('views.TheFooter.feedback') }}
      </router-link>
      <router-link
        to="/faq"
        class="footer-link">
        {{ $t('views.TheFooter.help') }}
      </router-link>
    </div>
    <CopyRight class="footer-copyright" />
  </div>
</template>

<script>
import CopyRight from './share/CopyRight'
export default {
  name: 'TheFooter',
  components: { CopyRight }
}
</script>

<style scoped>

</style>
