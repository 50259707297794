import {
  Pagination,
  Autocomplete,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Input,
  Radio,
  RadioButton,
  Checkbox,
  CheckboxGroup,
  CheckboxButton,
  Slider,
  Switch,
  Select,
  Option,
  Button,
  ButtonGroup,
  Popover,
  Tooltip,
  Form,
  FormItem,
  Icon,
  Loading,
  Drawer,
  Dialog,
  Alert,
  MessageBox,
  Table,
  TableColumn,
  Link,
  Upload,
  InputNumber,
  Collapse,
  CollapseItem,
  Message
} from 'element-ui'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
import { resizeObserverPatch } from './util'

locale.use(lang)

const LIST_ELEMENT_WITH_CUSTOM_RESIZE_OBSERVER = [
  { element: Table, methodName: 'resizeListener' },
  { element: Select, methodName: 'handleResize' }
]

const install = function (Vue) {
  for (const item of LIST_ELEMENT_WITH_CUSTOM_RESIZE_OBSERVER) {
    resizeObserverPatch(item.element, item.methodName)
  }

  Vue.use(Pagination)
  Vue.use(Autocomplete)
  Vue.use(Dropdown)
  Vue.use(DropdownMenu)
  Vue.use(DropdownItem)
  Vue.use(Input)
  Vue.use(Radio)
  Vue.use(RadioButton)
  Vue.use(Checkbox)
  Vue.use(CheckboxGroup)
  Vue.use(CheckboxButton)
  Vue.use(Slider)
  Vue.use(Switch)
  Vue.use(Select)
  Vue.use(Option)
  Vue.use(Button)
  Vue.use(ButtonGroup)
  Vue.use(Popover)
  Vue.use(Tooltip)
  Vue.use(Form)
  Vue.use(FormItem)
  Vue.use(Icon)
  Vue.use(Loading.directive)
  Vue.use(Drawer)
  Vue.use(Dialog)
  Vue.use(Alert)
  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Link)
  Vue.use(Upload)
  Vue.use(InputNumber)
  Vue.use(Collapse)
  Vue.use(CollapseItem)
  // Vue.use(Steps)
  // Vue.use(Step)

  Vue.prototype.$loading = Loading.service
  Vue.prototype.$msgbox = MessageBox
  Vue.prototype.$alert = MessageBox.alert
  Vue.prototype.$confirm = MessageBox.confirm
  Vue.prototype.$prompt = MessageBox.prompt
  Vue.prototype.$message = Message
}

export default {
  install
}
