import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/store'
import i18n from '@/i18n'
import VueMeta from 'vue-meta'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
import VueClipboard from 'vue-clipboard2'

// fonts
import '@/plugins/rtx-fonts.js'

// style
import './styles/pragmatic-pack.scss'
import './styles/index.scss'

import ElementUiCustom from '@/plugins/ElementUiCustom'
import Essentials from '@/plugins/Essentials'
import MQ from 'vue-match-media/src'
import VueLazyload from 'vue-lazyload'
import Pp from '@/plugins/PragmaticPack'
import vue2SmoothScroll from 'vue2-smooth-scroll'
import VueGtm from 'vue-gtm'
import './registerServiceWorker'
import { retrieveUser, isLoggedIn } from './service/auth'

Vue.use(VueLazyload, {
  preLoad: 1.6,
  throttleWait: 300,
  attempt: 0,
  listenEvents: ['scroll']
})

Vue.use(ElementUiCustom)
Vue.use(MQ)
Vue.use(Essentials)
Vue.use(Pp)
Vue.use(vue2SmoothScroll)
Vue.use(VueMeta)

Vue.config.productionTip = false

Vue.use(VueGtm, {
  id: process.env.VUE_APP_GTM_ID, // Your GTM single container ID or array of container ids ['GTM-xxxxxxx', 'GTM-yyyyyyy']
  // queryParams: { // Add url query string when load gtm.js with GTM ID (optional)
  //   gtm_auth:'AB7cDEf3GHIjkl-MnOP8qr',
  //   gtm_preview:'env-4',
  //   gtm_cookies_win:'x'
  // },
  enabled: !!process.env.VUE_APP_GTM_ID, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: process.env.NODE_ENV !== 'production', // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router // Pass the router instance to automatically sync with router (optional)
  // ignoredViews: ['homepage'] // If router, you can exclude some routes name (case insensitive) (optional)
})

let whitelistUrls = []
switch (process.env.VUE_APP_SENTRY_ENV) {
case 'production':
  whitelistUrls = [/https?:\/\/ebeds\.co/]
}

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      "Cannot read property 'style' of null",
      'Loading took too long',
      'Invalid LatLng object: (NaN, NaN)',
      'Network Error',
      /^timeout of/,
      /^Non-Error promise rejection captured with value/,
      "Cannot read property '_leaflet_pos' of undefined",
      /chrome-extension/i
    ],
    integrations: [new Integrations.Vue({ Vue, attachProps: true, logError: true })],
    environment: process.env.VUE_APP_SENTRY_ENV,
    whitelistUrls
  })

  Sentry.configureScope(scope => {
    scope.setTags({
      app: 'eBeds Frontend',
      brand: 'eBeds'
    })
    if (isLoggedIn()) {
      const uid = retrieveUser('uid')
      const email = retrieveUser('email')
      const name = retrieveUser('displayName')
      scope.setUser({ uid, email, name })
      scope.setTags({
        agent: name
      })
    }
  })
}

Vue.use(VueClipboard)
new Vue({
  router,
  store,
  i18n,
  beforeCreate () {
    store.commit('user/SET_LOCALE', null)
  },
  render: h => h(App),
  mq: {
    mobile: '(max-width: 768px)',
    tablet: '(min-width: 769px)',
    tabletOnly: '(min-width: 769px) and (max-width: 1023px)',
    desktop: '(min-width: 1024px)',
    desktopOnly: '(min-width: 1024px) and (max-width: 1215px)',
    widescreen: '(min-width: 1216px)',
    widescreenOnly: '(min-width: 1216px) and (max-width: 1407px)',
    fullhd: '(min-width: 1408px)'
  }
}).$mount('#app')
