const state = {
  list: [],
  selected: false
}

const mutations = {
  TOGGLE_SELECT: (state, val) => {
    if (!val) {
      state.selected = !state.selected
    } else {
      state.selected = val
    }
  },
  ADD_ITEM: (state, hotel) => {
    if (!state.list.some(item => {
      const property = item.property || {}
      const hotelProperty = hotel.property || {}
      return property.propertyCode === hotelProperty.propertyCode
    })) {
      state.list.push(JSON.parse(JSON.stringify(hotel)))
    }
  },
  REMOVE_ITEM: (state, hotel) => {
    state.list = state.list.filter(item => {
      const property = item.property || {}
      const hotelProperty = hotel.property || {}
      return property.propertyCode !== hotelProperty.propertyCode
    })
  },
  RESET: (state) => {
    state.list = []
    state.selected = false
  }
}

const actions = {}

const getters = {
  hasItem: (state, getters) => {
    return state.list.length > 0
  },
  itemCount: (state, getters) => {
    return state.list.length
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
