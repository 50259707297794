<template>
  <div class="home-page over-menu-hero">
    <div class="section has-background-white  home-banner">
      <div
        v-lazy:background-image="require(`@/assets/home-banner.jpg`)"
        class="home-banner-bg" />
      <div class="container">
        <div class="columns">
          <div class="column is-5">
            <h1 class="title is-size-3 m-b-4x has-text-black has-text-weight-bold">
              {{ $t('banner.title') }}
            </h1>
            <div
              style="font-size: 18px;"
              class="m-b-6x">
              <ul>
                <li><i class="ap-icon-check has-text-dark-blue is-size-6" /> Access 700,000+ properties globally</li>
                <li><i class="ap-icon-check has-text-dark-blue is-size-6" /> Award winning reviews, superior content and quotes</li>
                <li><i class="ap-icon-check has-text-dark-blue is-size-6" /> Set your own commission</li>
                <li><i class="ap-icon-check has-text-dark-blue is-size-6" /> Cash flow friendly, only pay just before arrival date</li>
                <li><i class="ap-icon-check has-text-dark-blue is-size-6" /> Proudly made in South Africa</li>
              </ul>
            </div>
            <div>
              <!-- TEMP: hide register entrance -->
              <!-- <el-button
                class="button-secondary m-r-4x"
                @click="$router.push('/register')">
                {{ $t('banner.Register Now') }}
              </el-button> -->
              <el-button
                type="text"
                class="has-text-secondary"
                @click="login">
                {{ $t('banner.Log in') }}
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    <div class="section is-vertically-large has-background-lightest">-->
    <!--      <div class="container">-->
    <!--        <h2 class="has-text-primary is-size-3 has-text-weight-bold has-text-centered m-b-6x">{{ $t('Recommended Hotels') }}</h2>-->
    <!--        <div class="columns is-multiline">-->
    <!--          <div class="column is-4" v-for="item in recommendedHotels" :key="item.propertyName">-->
    <!--            <div class="card-thumb-large-property" @click="login">-->
    <!--              <i :style="`background-image: url(${item.heroImage.url})`" class="thumb" />-->
    <!--              <div class="columns is-multiline is-gapless is-mobile">-->
    <!--                <div class="column is-12">-->
    <!--                  <StarRatings :ratings="item.starRating" />-->
    <!--                </div>-->
    <!--                <div class="column m-r">-->

    <!--                  <h5 class="property-name">{{item.name}}</h5>-->
    <!--                  <p>{{item.location.city}}, {{$t(item.location.country)}}</p>-->
    <!--                </div>-->
    <!--                <div class="column is-narrow">-->
    <!--                  <div class="is-size-7  has-text-grey-light has-text-right has-text-weight-bold" style="line-height: 1">{{$t('from')}}</div>-->
    <!--                  <strong class="is-size-5 has-text-weight-bold has-text-primary">{{item.adjustedDisplayRate|currencyObj({factionDigits:0})}}</strong>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="section is-vertically-large has-background-primary has-text-white">
      <div class="container">
        <div class="columns">
          <div class="column">
            <div class="count-list-item">
              <div class="count">
                700<span>k</span>
              </div>
              <div class="suffix">
                {{ $t('Hotels') }}
              </div>
            </div>
          </div>
          <div class="column">
            <div class="count-list-item">
              <div class="count">
                200<span>+</span>
              </div>
              <div class="suffix">
                {{ $t('Countries') }}
              </div>
            </div>
          </div>
          <div class="column">
            <div class="count-list-item">
              <div class="count">
                75
              </div>
              <div class="suffix">
                {{ $t('Hotel Suppliers') }}
              </div>
            </div>
          </div>
          <!--          <div class="column">-->
          <!--            <div class="count-list-item">-->
          <!--              <div class="count">2</div>-->
          <!--              <div class="suffix">{{$t('languages')}}</div>-->
          <!--            </div>-->
          <!--          </div>-->
          <div class="column">
            <div class="count-list-item">
              <div class="count">
                24/7
              </div>
              <div class="suffix">
                {{ $t('Support') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section is-vertically-large has-background-lightest">
      <div class="container">
        <h2 class="has-text-primary is-size-3 has-text-weight-bold has-text-centered m-b-6x">
          Some of our top integration partners:
        </h2>
        <div class="partner-logos">
          <div class="item">
            <img
              src="../../assets/partner-logo/rakuten.png"
              alt="rakuten">
          </div>
          <div class="item">
            <img
              src="../../assets/partner-logo/expedia.png"
              alt="expedia">
          </div>
          <div class="item">
            <img
              src="../../assets/partner-logo/booking.png"
              alt="booking.com">
          </div>
          <div class="item">
            <img
              src="../../assets/partner-logo/hotelbeds.png"
              alt="hotelbeds">
          </div>
          <div class="item">
            <img
              src="../../assets/partner-logo/webbeds.png"
              alt="webbeds">
          </div>
          <div class="item">
            <img
              src="../../assets/partner-logo/viva.png"
              alt="viva connect">
          </div>
        </div>
      </div>
    </div>
    <div class="section is-vertically-large has-background-white">
      <div class="container">
        <div class="columns">
          <div class="column is-3">
            <div class="list-item-with-icon">
              <i
                class="thumb"
                :style="`background-image: url(${require('../../assets/home-img-1.svg')})`" />
              <h5 class="is-size-18 has-text-weight-bold has-text-primary">
                {{ $t('list-item-1.title') }}
              </h5>
              <p>{{ $t('list-item-1.desc') }}</p>
            </div>
          </div>
          <div class="column is-3">
            <div class="list-item-with-icon">
              <i
                class="thumb"
                :style="`background-image: url(${require('../../assets/home-img-2.svg')})`" />
              <h5 class="is-size-18 has-text-weight-bold has-text-primary">
                {{ $t('list-item-2.title') }}
              </h5>
              <p>{{ $t('list-item-2.desc') }}</p>
            </div>
          </div>
          <div class="column is-3">
            <div class="list-item-with-icon">
              <i
                class="thumb"
                :style="`background-image: url(${require('../../assets/home-img-3.svg')})`" />
              <h5 class="is-size-18 has-text-weight-bold has-text-primary">
                {{ $t('list-item-3.title') }}
              </h5>
              <p>{{ $t('list-item-3.desc') }}</p>
            </div>
          </div>
          <div class="column is-3">
            <div class="list-item-with-icon">
              <i
                class="thumb"
                :style="`background-image: url(${require('../../assets/home-img-4.svg')})`" />
              <h5 class="is-size-18 has-text-weight-bold has-text-primary">
                {{ $t('list-item-4.title') }}
              </h5>
              <p>{{ $t('list-item-4.desc') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixHead from '@/mixins/mixHead'

export default {
  name: 'Homepage',
  mixins: [mixHead],
  // Static pages have a lot of text,use component based localization
  i18n: {
    messages: {
      'en-US': {
        'banner': {
          'title': 'Travel professionals get exclusive access to global hotel inventory, at the best NET rates',
          'desc': 'We compare 75 suppliers to give you the best selection of global hotel and vacation rentals at the lowest prices.',
          'Register Now': 'Register Now',
          'Log in': 'Log in'
        },
        'Recommended Hotels': 'Recommended Hotels',
        'list-item-1': {
          'title': 'Flexible Payments',
          'desc': 'Book today, pay... sometime in the future. Only pay before cancellation due date, giving you ample time to manage your cash flow. Pay with Visa, Mastercard, Amex, Diners, Virtual Cards or EFT.'
        },
        'list-item-2': {
          'title': 'Best Industry Rates',
          'desc': 'With over 75 suppliers connected to eBEDS we can source many more room types and Net rates per property, and provide these to you in a single, easy-to-use platform.'
        },
        'list-item-3': {
          'title': 'Flexible Commission',
          'desc': 'Stay in control by setting your own commissions, either for your account or for each individual booking. Reduce them to win the sale or increase them to earn even more.'
        },
        'list-item-4': {
          'title': '24/7 Africa First Support',
          'desc': 'We offer 24 hour support, through our chat-bot, email and WhatsApp. We also aim to reconfirm every booking with the hotel to alleviate any check in issues for your traveller.'
        },
        'Hotels': 'Hotels',
        'Countries': 'Countries',
        'Hotel Suppliers': 'Hotel Suppliers',
        'Support': 'Support',
        'languages': 'languages',
        'from': 'from'
      },
      'zh-CN': {
        'banner': {
          'title': '旅行社享受尽可能低的价格',
          'desc': '我们将对比75家供应商，以最低的价格为您提供全球酒店和度假租赁的最佳选择。',
          'Register Now': '立即注册',
          'Log in': '登录'
        },
        'Recommended Hotels': '推荐酒店',
        'list-item-1': {
          'title': '可退款选项',
          'desc': '根据您的需要从多个住宿方案中选择。想先保证最低价格，然后再取消吗？确保您预订了可退款的住宿方案。'
        },
        'list-item-2': {
          'title': '最低供应商费率',
          'desc': '不再登录多家旅行社门户网站进行价格比较。我们比较了75家以上的供应商，为您提供市场上最好的价格。'
        },
        'list-item-3': {
          'title': '多支付网关',
          'desc': '预付或后付费、信用卡或银行转账 -- 选择您喜欢的付款方式。'
        },
        'list-item-4': {
          'title': '全天候客户服务支持',
          'desc': '对你的预订有疑问吗？我们的客户服务代理可通过电子邮件或拨打我们的免费热线24/7联系我们。'
        },
        'Hotels': '酒店',
        'Countries': '国家',
        'Hotel Suppliers': '酒店供应商',
        'Support': '客户服务',
        'languages': '语言',
        'from': '始于'
      }
    }
  },
  data () {
    return {
      seo: {
        title: 'eBEDS - Powerful booking portal for travel agents',
        description: 'We compare 75 suppliers to give you the best selection of global hotel and vacation rentals at the lowest prices.',
        url: `${this.$store.state.baseUrl}/`,
        image: `${this.$store.state.baseUrl}/og_image_homepage.jpg`
      }
      // recommendedHotels: [
      //   {
      //     starRating: 2.5,
      //     name: 'GLANSIT AKIHABARA COMFORT CAPSULE HOTEL',
      //     heroImage: {
      //       url: require('../../assets/recommended-hotels/0.jpg')
      //     },
      //     location: {
      //       address: '4-4-6 Sotokanda, Chiyoda-ku',
      //       city: 'Tokyo',
      //       country: 'Japan',
      //       countryCode: 'JP',
      //       postalCode: '101-0021'
      //     },
      //     adjustedDisplayRate: {
      //       value: 59.04576,
      //       currency: 'USD',
      //       txFees: 0,
      //       taxesAndFeesTotal: 5.9045760000000005,
      //       taxesConfident: 0
      //     }
      //   },
      //   {
      //     starRating: 2.5,
      //     name: 'OYO 644 Hotel Art Inn Namba',
      //     heroImage: {
      //       url: require('../../assets/recommended-hotels/1.jpg')
      //     },
      //     location: {
      //       address: '2-4-22 Namba',
      //       city: 'Osaka',
      //       country: 'Japan',
      //       countryCode: 'JP',
      //       postalCode: '542-0076'
      //     },
      //     adjustedDisplayRate: {
      //       value: 70.029122,
      //       currency: 'USD',
      //       txFees: 0,
      //       taxesAndFeesTotal: 7.0029122,
      //       taxesConfident: 0
      //     }
      //   },
      //   {
      //     starRating: 3.5,
      //     name: 'Le Tada Parkview Hotel',
      //     heroImage: {
      //       url: require('../../assets/recommended-hotels/2.jpg')
      //     },
      //     location: {
      //       address: '440/58 Ratchawithi 3',
      //       city: 'Bangkok',
      //       country: 'Thailand',
      //       countryCode: 'TH',
      //       postalCode: '10400'
      //     },
      //     adjustedDisplayRate: {
      //       value: 42.374881,
      //       currency: 'USD',
      //       txFees: 0,
      //       taxesAndFeesTotal: 4.2374881,
      //       taxesConfident: 0
      //     }
      //   },
      //   {
      //     starRating: 3,
      //     name: 'Hyatt Place Washington DC/Georgetown/West End',
      //     heroImage: {
      //       url: require('../../assets/recommended-hotels/3.jpg')
      //     },
      //     location: {
      //       address: '2121 M St NW',
      //       city: 'Washington',
      //       country: 'United States of America',
      //       countryCode: 'US',
      //       postalCode: '20037'
      //     },
      //     adjustedDisplayRate: {
      //       value: 151.56384,
      //       currency: 'USD',
      //       txFees: 0,
      //       taxesAndFeesTotal: 15.156384000000001
      //     }
      //   },
      //   {
      //     starRating: 3,
      //     name: 'Hyatt Place Denver Airport',
      //     adjustedDisplayRate: {
      //       value: 101.347201,
      //       currency: 'USD',
      //       txFees: 0,
      //       taxesAndFeesTotal: 10.134720100000001
      //     },
      //     heroImage: {
      //       url: require('../../assets/recommended-hotels/4.jpg')
      //     },
      //     location: {
      //       address: '16250 East 40Th Avenue',
      //       city: 'Aurora',
      //       country: 'United States of America',
      //       countryCode: 'US',
      //       postalCode: '80011'
      //     }
      //   },
      //   {
      //     starRating: 3,
      //     name: 'Plaza Hotel and Casino - Las Vegas',
      //     adjustedDisplayRate: {
      //       value: 28.356,
      //       currency: 'USD',
      //       txFees: 0,
      //       taxesAndFeesTotal: 2.8356000000000003,
      //       taxesConfident: 0
      //     },
      //     heroImage: {
      //       url: require('../../assets/recommended-hotels/5.jpg')
      //     },
      //     location: {
      //       address: '1 Main Street',
      //       city: 'Las Vegas',
      //       country: 'United States of America',
      //       countryCode: 'US',
      //       postalCode: '89101'
      //     }
      //   },
      // ]
    }
  },
  mounted () {
    if (!window['__PRERENDER_INJECTED']) document.querySelector('body').classList.add('has-over-menu-hero')
  },
  beforeDestroy () {
    document.querySelector('body').classList.remove('has-over-menu-hero')
  },
  methods: {
    login () {
      this.$store.commit('SET_LOGIN_DIALOG_VISIBLE', true)
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "../../styles/bulma-variables";

  .home-banner {
    position: relative;
    min-height: 650px;
    display: flex;
    align-items: center;

    .home-banner-bg {
      position: absolute;
      left: 41.4%;
      top: 0;
      right: 0;
      bottom: 0;
      background-size: cover;
      background-position: left center;

      &:before {
        content: '';
        width: 30%;
        height: 100%;
        transform: skewX(8deg);
        position: absolute;
        left: 0;
        top: 0;
        margin-left: -15%;
        background-color: $white;
      }
    }
  }

  .list-item-with-icon {
    text-align: center;

    .thumb {
      width: 80px;
      height: 80px;
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      margin-bottom: 30px;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .count-list-item {
    text-align: center;

    .count {
      font-size: 64px;
      // color: $primary;
      line-height: 1;

      span {
        font-size: 36px;
      }
    }

    .suffix {
      font-size: $size-5;
    }
  }
  .partner-logos {
    background-color: $white;
    display: flex;
    flex-wrap: wrap;
    max-width: 906px;
    margin-right: auto;
    margin-left: auto;
    > .item {
      width: 33.3%;
      padding: 1.5rem 4.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-width: 100%;
        filter:grayscale(1);
        transition: all ease-in 0.2s;
        &:hover {
          filter:grayscale(0);
        }
      }
    }
  }
</style>
