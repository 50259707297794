const cols = {
  requestAt: {
    value: 'booking.createdAt',
    prop: 'booking.createdAt',
    label: 'Booked at (localtime)',
    desc: 'When this order was placed',
    active: false,
    type: 'date',
    width: 200,
    formatter: function (row, filters) {
      return filters.date(row.booking.createdAt, 'MMM DD, YYYY HH:mm')
    },
    reportColumns: [
      {
        col: 'booking.createdAt',
        label: 'Booked at (localtime)',
        align: 'left'
      }
    ]
  },
  orderReference: {
    value: 'order.orderReference',
    prop: 'order.orderReference',
    label: 'Booking ID',
    desc: 'Reference ID',
    active: false,
    type: 'string',
    formatter: function (row, filters) {
      return row.order.orderReference
    },
    reportColumns: [
      {
        col: 'order.orderReference',
        label: 'Booking ID',
        align: 'left',
        width: 120
      }
    ]
  },
  appLabel: {
    value: 'app.companyName',
    prop: 'app.companyName',
    label: 'Client name',
    desc: 'Name of the company',
    active: false,
    type: 'string',
    formatter: function (row, filters) {
      return row.app.companyName
    },
    reportColumns: [
      {
        col: 'app.label',
        label: 'Client name',
        align: 'left',
        width: 150
      }
    ]
  },
  orderStatus: {
    value: 'order.status.code',
    prop: 'order.status',
    label: 'Status',
    desc: 'State of order',
    active: false,
    type: 'status',
    html: true,
    formatter: function (row, filters, localFilters) {
      return localFilters.orderStatus(row.order.status.code)
    },
    reportColumns: [
      {
        col: 'order.status',
        label: 'Order status',
        align: 'center'
      }
    ]
  },
  stayPeriods: {
    value: 'booking.checkInDate',
    prop: 'booking.checkInDate',
    label: 'Staying period',
    desc: 'Check in and check out dates',
    active: false,
    type: 'date',
    formatter: function (row, filters) {
      return `${filters.date(row.booking.checkInDate, 'MMM DD, YYYY')} - ${filters.date(row.booking.checkOutDate, 'MMM DD, YYYY')}`
    },
    reportColumns: [
      {
        col: 'booking.checkInDate',
        label: 'Check-in date',
        align: 'left'
      },
      {
        col: 'booking.checkOutDate',
        label: 'Check-out date',
        align: 'left'
      }
    ]
  },
  propertyName: {
    value: 'property.name',
    prop: 'booking.propertyName',
    label: 'Property',
    desc: 'Name of the accommodation',
    active: false,
    type: 'string',
    formatter: function (row, filters) {
      return row.property.name
    },
    reportColumns: [
      {
        col: 'booking.propertyName',
        label: 'Property name',
        align: 'left',
        width: 320
      }
    ]
  },
  guestName: {
    value: 'contactPerson.fullName',
    prop: 'contactPerson.fullName',
    label: 'Primary Guest',
    desc: 'Primary person who is staying in accommodation',
    active: false,
    type: 'string',
    formatter: function (row, filters) {
      return row.contactPerson.fullName
    },
    reportColumns: [
      {
        col: 'contactPerson.fullName',
        label: 'Primary Guest',
        align: 'left',
        width: 180
      },
      {
        col: 'contactPerson.email',
        label: 'Primary Guest\'s email',
        align: 'left',
        width: 220
      }
    ]
  },
  freeCancellationBefore: {
    value: 'booking.freeCancellationBefore',
    prop: 'booking.freeCancellationBefore',
    label: 'Cancellation deadline',
    desc: ' Cancellation deadline',
    active: false,
    type: 'date',
    formatter: function (row, filters) {
      return filters.dateTz(row.booking.freeCancellationBefore, { tz: 'Africa/Johannesburg', format: 'MMM DD, YYYY HH:mm', isBeforeMidnight: true })
    },
    reportColumns: [
      {
        col: 'booking.freeCancellationBefore',
        label: 'Cancellation deadline',
        align: 'left',
        width: 180
      }
    ]
  },
  adultCount: {
    value: 'booking.adultCount',
    prop: 'booking.adultCount',
    label: 'Adults',
    desc: 'Number of adults',
    active: false,
    type: 'string',
    formatter: function (row, filters) {
      return row.booking.adultCount
    },
    reportColumns: [
      {
        col: 'booking.adultCount',
        label: 'Adults',
        align: 'right',
        type: 'number',
        width: 40
      }
    ]
  },
  cost: {
    value: 'order.cost',
    prop: 'cost',
    label: 'Cost',
    desc: 'Cost',
    active: false,
    type: 'currency',
    formatter: function (row, filters) {
      return filters.currencyObj(row.order.cost)
    },
    reportColumns: [
      {
        col: 'order.cost.value',
        label: 'Cost',
        align: 'right',
        type: 'number',
        width: 130
      },
      {
        col: 'order.cost.currency',
        label: 'Cost CCY',
        align: 'center',
        width: 80
      }
    ]
  },
  profitInCostCurrency: {
    value: 'order.profitInCostCurrency',
    prop: 'profitInCostCurrency',
    label: 'Profit',
    desc: 'Profit after less transaction fees',
    active: false,
    type: 'currency',
    formatter: function (row, filters) {
      return filters.currencyObj(row.order.profitInCostCurrency)
    },
    reportColumns: [
      {
        col: 'order.profitInCostCurrency.value',
        label: 'Profit',
        align: 'right',
        type: 'number',
        width: 130
      },
      {
        col: 'order.profitInCostCurrency.currency',
        label: 'Profit CCY',
        align: 'center',
        width: 80
      }
    ]
  },
  createdBy: {
    value: 'order.createdBy',
    prop: 'order.createdBy',
    label: 'Booked by',
    desc: 'Name of the user who made the booking',
    active: false,
    type: 'string',
    formatter: function (row, filters) {
      return row.order.createdBy
    },
    reportColumns: [
      {
        col: 'order.createdBy',
        label: 'Booked by',
        align: 'right',
        type: 'number',
        width: 130
      }
    ]
  },
  initialSellRate: {
    value: 'order.initialSellRate',
    prop: 'initialSellRate',
    label: 'Initial sell rate',
    desc: 'Initial sell rate',
    active: false,
    type: 'currency',
    formatter: function (row, filters) {
      return filters.currencyObj(row.order.initialSellRate)
    },
    reportColumns: [
      {
        col: 'order.initialSellRate.value',
        label: 'Initial Sell Rate',
        align: 'right',
        type: 'number',
        width: 130
      },
      {
        col: 'order.initialSellRate.currency',
        label: 'Initial Sell Rate CCY',
        align: 'center',
        width: 80
      }
    ]
  },
  childrenCount: {
    value: 'booking.childrenCount',
    prop: 'booking.childrenCount',
    label: 'Children',
    desc: 'Number of children and their ages',
    active: false,
    type: 'string',
    formatter: function (row, filters) {
      return row.booking.childrenCount
    },
    reportColumns: [
      {
        col: '"booking"."childrenCount"',
        label: 'Children',
        align: 'right',
        type: 'number',
        width: 40
      },
      {
        col: '"booking"."childrenAges"',
        label: 'Children\'s ages',
        align: 'left'
      }
    ]
  },
  supplierDescription: {
    value: 'booking.supplierDescription',
    prop: 'booking.supplierDescription',
    label: 'Room description',
    desc: 'Room type and meal plan',
    active: false,
    type: 'string',
    formatter: function (row, filters) {
      return row.booking.supplierDescription
    },
    reportColumns: [
      {
        col: 'booking.supplierDescription',
        label: 'Room description',
        align: 'left',
        width: 360
      },
      {
        col: 'booking.foodCode',
        label: 'Meal plan',
        align: 'left',
        width: 40
      }
    ]
  },
  totalAmount: {
    value: 'order.totalAmount',
    prop: 'totalAmount',
    label: 'Total amount',
    desc: 'Total amount before adjustment',
    active: false,
    type: 'currency',
    formatter: function (row, filters) {
      return filters.currencyObj(row.order.totalAmount)
    },
    reportColumns: [
      {
        col: 'order.totalAmount.value',
        label: 'Total amount',
        align: 'right',
        type: 'number',
        width: 130
      },
      {
        col: 'order.totalAmount.currency',
        label: 'Total amount CCY',
        align: 'center',
        width: 80
      }
    ]
  },
  // companyName: {
  //   value: 'app.companyName',
  //   prop: 'app.companyName',
  //   label: 'Company',
  //   desc: 'Name of the company',
  //   active: false,
  //   type: 'string',
  //   formatter: function (row, filters) {
  //     return row.app.companyName
  //   },
  //   reportColumns: [
  //     {
  //       col: 'app.companyName',
  //       label: 'Company',
  //       align: 'right',
  //       type: 'number',
  //       width: 130
  //     }
  //   ]
  // },
  roomCount: {
    value: 'booking.roomCount',
    prop: 'booking.roomCount',
    label: 'Rooms',
    desc: 'Number of rooms',
    active: false,
    type: 'string',
    formatter: function (row, filters) {
      return row.booking.roomCount
    },
    reportColumns: [
      {
        col: 'booking.roomCount',
        label: 'Rooms',
        align: 'right',
        type: 'number',
        width: 40
      }
    ]
  },
  // totalChargeAmount: {
  //   value: 'order.agentTotalChargeAmount',
  //   prop: 'agentTotalChargeAmount',
  //   label: 'Total charged amount',
  //   desc: 'Amount charged to client',
  //   active: false,
  //   type: 'currency',
  //   formatter: function (row, filters) {
  //     return filters.currencyObj(row.order.agentTotalChargeAmount)
  //   },
  //   reportColumns: [
  //     {
  //       col: 'order.agentTotalChargeAmount.value',
  //       label: 'Total charged amount',
  //       align: 'right',
  //       type: 'number',
  //       width: 130
  //     },
  //     {
  //       col: 'order.agentTotalChargeAmount.currency',
  //       label: 'Total charged amount CCY',
  //       align: 'center',
  //       width: 80
  //     }
  //   ]
  // },
  agentActualChargeAmount: {
    value: 'order.agentActualChargeAmount',
    prop: 'agentActualChargeAmount',
    label: 'Actual charged amount',
    desc: 'Total amount after self-markup',
    active: false,
    type: 'currency',
    formatter: function (row, filters) {
      return filters.currencyObj(row.order.agentActualChargeAmount)
    },
    reportColumns: [
      {
        col: 'order.agentActualChargeAmount.value',
        label: 'Actual charged amount',
        align: 'right',
        type: 'number',
        width: 130
      },
      {
        col: 'order.agentActualChargeAmount.currency',
        label: 'Actual charged amount CCY',
        align: 'center',
        width: 80
      }
    ]
  },
  agentEarningStatus: {
    value: 'order.agentEarningStatus',
    prop: 'order.agentEarningStatus',
    label: 'Commission status',
    desc: 'State of Commission',
    active: false,
    type: 'status',
    html: true,
    formatter: function (row, filters, localFilters) {
      return localFilters.agentEarningStatus(row.order.agentEarningStatus)
    },
    reportColumns: [
      {
        col: 'order.agentEarningStatus',
        label: 'Commission status',
        align: 'center'
      }
    ]
  },
  agentEarningAmount: {
    value: 'order.agentProfit',
    prop: 'agentProfit',
    label: 'Commission amount',
    desc: 'Amount the agent earned',
    active: false,
    type: 'currency',
    formatter: function (row, filters) {
      return filters.currencyObj(row.order.agentProfit)
    },
    reportColumns: [
      {
        col: 'order.agentProfit.value',
        label: 'Commission amount',
        align: 'right',
        type: 'number',
        width: 130
      },
      {
        col: 'order.agentProfit.currency',
        label: 'Commission amount CCY',
        align: 'center',
        width: 80
      }
    ]
  },
  payLaterDueDate: {
    value: 'payment.metadata.payLaterDueDate',
    prop: 'payment.metadata.payLaterDueDate',
    label: 'On hold deadline',
    desc: 'Payment due date for hold booking',
    active: false,
    type: 'date',
    formatter: function (row, filters) {
      return row.order.status.code === 'ON_HOLD'
        ? filters.date(row.payment.metadata.payLaterDueDate, 'MMM DD, YYYY HH:mm')
        : '-'
    },
    reportColumns: [
      {
        col: 'payment.metadata.payLaterDueDate',
        label: 'On hold deadline',
        align: 'left',
        width: 180
      }
    ]
  },
  travelGroup: {
    value: 'app.travelGroup',
    prop: 'app.travelGroup',
    label: 'Travel Group',
    desc: 'Name of travel group',
    active: false,
    type: 'string',
    formatter: function (row, filters) {
      return row.app.travelGroup
    },
    reportColumns: [
      {
        col: 'app.travelGroup',
        label: 'Travel Group',
        align: 'left',
        width: 150
      }
    ]
  },
  salesManager: {
    value: 'app.salesManager',
    prop: 'app.salesManager',
    label: 'Sales Manager',
    desc: 'Name of sales manager',
    active: false,
    type: 'string',
    formatter: function (row, filters) {
      return row.app.salesManager
    },
    reportColumns: [
      {
        col: 'app.salesManager',
        label: 'Sales Manager',
        align: 'left',
        width: 150
      }
    ]
  },
  failureReason: {
    value: '',
    prop: '',
    sort: false,
    label: 'Failure Reason',
    active: false,
    desc: 'Booking failure reason (if any)',
    formatter: function (row) {
      if (row.order.status.code === 'FAILURE') {
        return row.booking.failureMessage || row.payment.failureMessage || 'Supplier issue'
      }
      return '-'
    }
  },
  paymentGateway: {
    value: 'payment.paymentGateway',
    prop: 'paymentGateway',
    sort: true,
    label: 'Payment Gateway',
    active: false,
    desc: 'Payment Gateway',
    formatter: function (row) {
      return row.payment.paymentGateway
    }
  },
  amountRefunded: {
    value: 'payment.amountRefunded',
    prop: 'amountRefunded',
    sort: false,
    label: 'Amount Refunded',
    active: false,
    desc: 'Refunded amount',
    type: 'currency',
    formatter: function (row, filters) {
      return filters.currencyObj({ value: row.payment.amountRefunded, currency: row.payment.currency }, { cent: true })
    }
  }
}

/**
 * Cache for later use
 */
const colCodes = Object.keys(cols)

const list = colCodes.map(key => ({ key, ...cols[key] }))

/**
 * Retrive colmns insance from key
 * @param {string} colCode – code to represent the column
 */
const findByKey = (colCode) => {
  return cols[colCode]
}

const defaultColumns = ['requestAt', 'appLabel', 'orderReference', 'orderStatus', 'agentEarningStatus', 'stayPeriods', 'propertyName', 'guestName', 'freeCancellationBefore']

export default {
  cols,
  list,
  findByKey,
  defaultColumns
}
