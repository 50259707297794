import lazy from './lazyValue'
import googleMapsApiLoader from './CustomGoogleMapsApiLoader'

function makeGmapApiPromiseLazy (options) {
  // Things to do once the API is loaded
  function onApiLoaded () {
    return window.google
  }

  return lazy(() => { // Load the
    // This will only be evaluated once
    if (typeof window === 'undefined') { // server side -- never resolve this promise
      return new Promise(() => {}).then(onApiLoaded)
    } else {
      return googleMapsApiLoader(options).then(onApiLoaded).catch((err) => {
        throw err
      })
    }
  })
}

export default makeGmapApiPromiseLazy
