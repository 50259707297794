<template>
  <p
    class="is-size-7 is-light"
    v-html="$t('terms')" />
</template>

<script>
export default {
  name: 'RecaptchaTerms',
  i18n: {
    messages: {
      'en-US': {
        'terms': 'This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.'
      },
      'zh-CN': {
        'terms': '此网站受Google reCAPTCHA<a href="https://policies.google.com/privacy" target="_blank">隐私政策</a>和<a href="https://policies.google.com/terms" target="_blank">服务条款</a>的保护.'
      }
    }
  }
}
</script>

<style scoped>

</style>
