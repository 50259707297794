import countries from './countries'
import countriesCN from './countries-cn'
import cookies from 'js-cookie'

export function getCountryByAlpha2 (aplha2, locale = 'en-US') {
  if (aplha2 == null || aplha2.length !== 2 || typeof aplha2 !== 'string') return {}
  locale = locale || cookies.get(`${process.env.VUE_APP_PREFIX_NAME}locale`)
  const country = locale === 'en-US' ? countries.find(ele => ele.a2 === aplha2) : countriesCN.find(ele => ele.a2 === aplha2)

  return country
}

export function getCountryByPhone (ph) {
  if (ph == null || typeof ph !== 'string') return {}

  const country = countries.find(ele => ele.ph === ph)

  return country
}

export function getCountryByLocationQuery (locationQuery) {
  if (locationQuery == null || typeof locationQuery !== 'string' || locationQuery.indexOf(',') === -1) return {}
  const locationArray = locationQuery.split(',')
  if (!locationArray.length) return
  const country = countries.find(ele => ele.lb.toLowerCase() === locationArray[locationArray.length - 1].trim().toLowerCase())

  return country
}
