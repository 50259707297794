import uuidv4 from 'uuid/v4'
import moment from 'moment'
import momentTz from 'moment-timezone'
import vueSmoothScroll from 'vue2-smooth-scroll'
import ZuDivider from '@/components/ZuDivider'
import IconBase from '@/components/IconBase'
import EmptyState from '@/components/EmptyState'

import VueSticky from 'vue-sticky'
import VueSkeletonLoading from 'vue-skeleton-loading'

import i18n from '@/i18n'
import { hasRole, adminHasRole } from '../service/auth'
import { times } from 'number-precision'

const install = function (Vue) {
  Vue.component('EmptyState', EmptyState)
  Vue.component('ZuDivider', ZuDivider)
  Vue.component('IconBase', IconBase)

  Vue.use(vueSmoothScroll)
  Vue.use(VueSkeletonLoading)

  Vue.prototype.$newSearchId = function () {
    return uuidv4()
  }

  Vue.prototype.$hasRole = function (roleCode) {
    return hasRole(roleCode)
  }

  Vue.prototype.$adminHasRole = function (roleCode) {
    return adminHasRole(roleCode)
  }

  Vue.prototype.$enableWallet = function () {
    return process.env.VUE_APP_ENABLE_WALLET === 'true'
  }

  Vue.prototype.$enableMasterSettings = function () {
    return process.env.VUE_APP_ENABLE_MASTER_SETTINGS === 'true'
  }

  Vue.prototype.$enableMasterSettingsBookAndHold = function () {
    return process.env.VUE_APP_ENABLE_BOOK_AND_HOLD === 'true'
  }

  Vue.prototype.$isFoodCodeValid = function (code) {
    const foodCode = typeof code === 'number' ? code : parseInt(code, 10) || 0

    return foodCode > 0
  }

  Vue.mixin({
    directives: {
      'sticky': VueSticky
    },
    computed: {
      mx_user () {
        const storage = JSON.parse(localStorage.getItem(`${process.env.VUE_APP_PREFIX_NAME}zumataAP`))
        if (storage) {
          return storage.user
        }
        return undefined
      },
      mx_isLoggedIn () {
        return !!this.mx_customer
      }
    }
  })
  Vue.directive('opacity-zero', function (el, binding) {
    if (binding.value) {
      el.style.opacity = 0
    } else {
      el.style.opacity = 1
    }
  })
  Vue.directive('number-input', function (el, binding, vnode) {
    function onInput (el, ele, binding, vnode) {
      function handle () {
        let val = ele.value
        // modifiers为修饰符对象，传入了float，则其float属性为true
        if (binding.modifiers.float) {
          // 清除"数字"和"."以及"-"以外的字符
          val = val.replace(/[^\-\d.]/g, '')
          // 只保留第一个“点”号, 清除多余的
          const idx = val.indexOf('.')
          if (!(idx === -1 || idx === val.length - 1)) {
            val = val.substr(0, idx) + '.' + val.substr(idx + 1).replace(/\./g, '')
          }
          // 只保留首位的负号, 清除多余的
          if (val.length > 1) {
            val = val.charAt(0) + val.substr(1).replace(/-/g, '')
          }
          // 将 '-.' 替换成 '-0.'
          val = val.replace(/^\./g, '0.').replace(/^-\./, '-0.')
          if (typeof binding.value !== 'undefined') {
            // 期望保留的最大小数位数
            let pointKeep = 0
            if (typeof binding.value === 'string' ||
              typeof binding.value === 'number') {
              pointKeep = parseInt(binding.value)
            } else if (typeof binding.value === 'object') {
              // 支持新的小数点保留位配置
              pointKeep = binding.value.decimal
            }
            if (!isNaN(pointKeep)) {
              if (!Number.isInteger(pointKeep) ||
                pointKeep < 0) {
                pointKeep = 0
              }
              // 增加'-'号的支持
              const str = '^(\\-)*(\\d+)\\.(\\d{' + pointKeep + '}).*$'
              const reg = new RegExp(str)
              if (pointKeep === 0) {
                // 不需要小数点
                val = val.replace(reg, '$1$2')
              } else {
                // 通过正则保留小数点后指定的位数
                val = val.replace(reg, '$1$2.$3')
              }
            }
          }
        } else {
          val = ele.value.replace(/[^\-\d]/g, '').replace(/^(-)*(\d*)$/, '$1$2')
        }
        if (val !== '' && typeof binding.value === 'object') {
          let { min, max } = binding.value
          min = parseFloat(min)
          max = parseFloat(max)
          if (!isNaN(min)) {
            if (min >= 0) {
              // 不能是负数
              val = val.replace('-', '')
            }
            if (parseFloat(val) < min) {
              val = min
            }
          }
          if (!isNaN(max)) {
            if (parseFloat(val) > max) {
              val = max
            }
          }
        }
        ele.value = val
        console.log(val)
        if (vnode.componentInstance) {
          vnode.componentInstance.$emit('input', val)
        } else {
          vnode.elm.dispatchEvent(new CustomEvent('input', val))
        }
      }

      return handle
    }

    const ele = el.tagName === 'INPUT' ? el : el.querySelector('input')
    ele.addEventListener('input', onInput(el, ele, binding, vnode), false)
  })

  Vue.filter('countDays', function (starDate, options = {
    endDate: new Date(),
    round: 'down',
    preventMinus: false
  }) {
    if (!starDate) return '–'
    if (!options.endDate) return 'N.A'
    const totalMsPerDay = 24 * 60 * 60 * 1000
    const s = new Date(starDate)
    const e = new Date(options.endDate)
    const diff = e - s
    if (options.preventMinus) {
      if (diff < 0) return '-'
    }
    const dayDiff = Math.abs(diff / totalMsPerDay)
    switch (options.round) {
    case 'down':
      return Math.floor(dayDiff)
    case 'up':
      return Math.ceil(dayDiff)
    default:
      return dayDiff
    }
  })

  Vue.filter('nightLabel', function (night) {
    return night > 1 ? `${night} ${i18n.t('filters.nights')}` : `${Number(night >= 0)} ${i18n.t('filters.night')}`
  })

  Vue.filter('roomLabel', function (value) {
    return value > 1 ? `${value} ${i18n.t('components.SearchBarManyMobile.rooms')}` : `${Number(value >= 0)} ${i18n.t('components.SearchBarManyMobile.room')}`
  })

  Vue.filter('adultLabel', function (value) {
    return value > 1 ? `${value} ${i18n.t('components.SearchBarManyMobile.adults')}` : `${Number(value >= 0)} ${i18n.t('components.SearchBarManyMobile.adult')}`
  })

  // Convert firestore server timestamp to date
  Vue.filter('fsdate', function (value, options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
  }) {
    // const locale = 'en-US'
    const locale = i18n.locale
    if (!value) return '–'
    if (!value.toDate) return 'N.A'
    const d = value.toDate()
    const intl = new Intl.DateTimeFormat(locale, options)
    return intl.format(d)
  })

  // Vue.filter('date', function (val, options = {
  //   // timeZone: true, // Subtible to pass checkin date in local browser.
  //   hour12: false,
  //   year: 'numeric',
  //   month: 'short',
  //   day: 'numeric',
  //   hour: 'numeric',
  //   minute: 'numeric',
  //   second: 'numeric'
  // }) {
  //   // const locale = window.navigator.language || 'en-US'
  //   const locale = i18n.locale
  //   if (!val) return '–'
  //   // const { timeZone, ...rOptions } = options
  //   // Always set timeZone to true unless present in option false
  //   // const useTimeZone = timeZone === undefined ? true : timeZone
  //   const d = new Date(val)
  //   // if (useTimeZone) {
  //   // } else {
  //   //   const _d = val.match(/^(\d{4})-(\d{2})-(\d{2})/g)
  //   //   d = new Date(_d[0])
  //   //   // remove timezone
  //   // }
  //   // Something worng retrun N/A
  //   if (!(d.toJSON())) {
  //     console.error(new TypeError('val must be instance of Date'))
  //     return 'N.A'
  //   }
  //   const intl = new Intl.DateTimeFormat(locale, { ...options, hour12: false })
  //   return intl.format(d)
  // })

  Vue.filter('date', (val, format = 'ddd, MMM D, YYYY') => {
    if (!val) return '-'
    // return moment(val, 'YYYY-MM-DD').locale(i18n.locale).format(format) // Let moment check the format by itself
    return moment(val).locale(i18n.locale).format(format)
  })

  Vue.filter('dateTz', (value, options = {
    format: 'ddd, MMM D, YYYY, HH:mm',
    tz: 'Asia/Singapore',
    isBeforeMidnight: false
  }) => {
    if (!value) return 'N/A'
    if (!options.format) options.format = 'ddd, MMM D, YYYY, HH:mm'
    if (options.isBeforeMidnight) {
      return momentTz(value).subtract(1, 'd').tz(options.tz).hours(23).minutes(59).format(options.format)
    }
    return momentTz(value).tz(options.tz).format(options.format)
  })

  Vue.filter('currency', function (value, options = {
    currency: 'USD',
    factionDigits: 2,
    nights: null
  }) {
    const { currency, factionDigits, nights } = options
    // const locale = window.navigator.language || 'en-US'
    const locale = i18n.locale
    if (value == null) return '–'
    if (isNaN(value)) return 'N.A'
    if (nights) {
      value = times(value, nights)
    }
    const intl = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
      maximumFractionDigits: currency === 'ZAR' ? 0 : factionDigits,
      minimumFractionDigits: currency === 'ZAR' ? 0 : factionDigits
    })
    return currency === 'USD'
      ? intl.format(value).replace(/[a-z]*\$/i, 'USD')
      : intl.format(value)
  })

  Vue.filter('currencyLabel', function (currency) {
    return i18n.locale === 'en-US' ? currency : i18n.t(`currencies.${currency}`)
  })

  Vue.filter('taxesAndFees', function (rate, options = {
    offset: false // If True return the price minus taxesAndFees
  }) {
    const { offset } = options

    return {
      value: offset ? rate.value - rate.taxesAndFeesTotal : rate.taxesAndFeesTotal,
      currency: rate.currency,
      taxesConfident: rate.taxesConfident
    }
  })

  Vue.filter('refundablePaser', function (val) {
    return (val === undefined || val) ? '–' : i18n.t('filters.refundable')
  })

  Vue.filter('pct', function (value, options = {
    factionDigits: 2
  }) {
    const { factionDigits } = options
    // const locale = window.navigator.language || 'en-US'
    const locale = i18n.locale
    if (value == null) return '–'
    if (isNaN(value)) return i18n.t('filters.na')
    const intl = new Intl.NumberFormat(locale, {
      style: 'percent',
      maximumFractionDigits: factionDigits,
      minimumFractionDigits: factionDigits
    })
    return intl.format(value)
  })

  /**
   * @param objVal.currency String - ISO currency code
   * @param objVal.value Number - Number to be parse
   */
  Vue.filter('currencyObj', function (objVal = {}, options = {
    factionDigits: 2,
    excludeTax: false,
    nights: null,
    cent: false
  }) {
    let { currency, value, txFees, taxesAndFeesTotal } = objVal
    if (value == null || !currency) return i18n.t('filters.na')
    if (value == null) return '–'
    if (isNaN(value)) return i18n.t('filters.na')
    const { factionDigits, excludeTax, nights, cent } = options
    if (cent) value = value / 100
    // subtract the value with the fees
    if (excludeTax) {
      if (txFees) {
        value -= txFees
      }
      if (taxesAndFeesTotal) {
        value -= taxesAndFeesTotal
      }
    }
    if (nights) {
      value = times(value, nights)
    }
    // const locale = window.navigator.language || 'en-US'
    const locale = i18n.locale
    const intl = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
      maximumFractionDigits: currency === 'ZAR' ? 0 : factionDigits,
      minimumFractionDigits: currency === 'ZAR' ? 0 : factionDigits
    })

    return currency === 'USD'
      ? intl.format(value).replace(/[a-z]*\$/i, 'USD')
      : intl.format(value)
  })

  /**
   * @param value Number - Number to be parse
   */
  Vue.filter('number', function (value, fractionDigits = 0) {
    if (value == null) return '–'
    if (isNaN(value)) return i18n.t('filters.na')
    // const locale = window.navigator.language || 'en-US'
    const locale = i18n.locale
    const intl = new Intl.NumberFormat(locale, {
      style: 'decimal',
      minimumFractionDigits: fractionDigits
    })
    return intl.format(value)
  })

  Vue.filter('foodCode', function (val) {
    if (!val) return i18n.t('filters.na')
    const k = {
      1: i18n.t('filters.foodCode1'),
      2: i18n.t('filters.foodCode2'),
      3: i18n.t('filters.foodCode3'),
      4: i18n.t('filters.foodCode4'),
      5: i18n.t('filters.foodCode5'),
      6: i18n.t('filters.foodCode6'),
      7: i18n.t('filters.foodCode7')
    }
    return k[val]
  })

  Vue.filter('checkInOutTime', function (value) {
    return value ? value.split(':').slice(0, 2).join(':') : '-'
  })

  Vue.filter('nightsAllRoom', function (value, inNightlyAvgRate = true, room = 1) {
    if (inNightlyAvgRate) return 'Nightly avg.'
    let nightLabel = value > 1 ? 'nights' : 'night'
    let roomLabel = room > 1 ? 'rooms' : 'room'
    return value ? `for ${value} ${nightLabel} ${room} ${roomLabel}` : ''
  })

  Vue.filter('truncate', function (text, length = 180, clamp = '...') {
    text = text || ''

    if (text.length <= length) return text

    let tcText = text.slice(0, length - clamp.length)
    let last = tcText.length - 1

    while (last > 0 && tcText[last] !== ' ' && tcText[last] !== clamp[0]) last -= 1

    // Fix for case when text dont have any `space`
    last = last || length - clamp.length

    tcText = tcText.slice(0, last)

    return tcText + clamp
  })

  Vue.filter('orderStatus', function (val) {
    if (val === 'AWAITING_CONFIRMATION') return 'CONFIRMED'
    else return val
  })

  Vue.filter('capitalizeFirstLetter', function (val) {
    return val ? (val.charAt(0).toUpperCase() + val.slice(1)) : val
  })
}

export default {
  install
}
