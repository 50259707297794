<template>
  <el-popover
    trigger="hover"
    placement="top-end"
    popper-class="contact-popover">
    <div
      slot="reference"
      class="contact">
      <IconBase
        width="16"
        height="26"
        icon-name="phone">
        <IconQuestion />
      </IconBase>
    </div>
    <ul class="contact-content">
      <li>
        <router-link
          class="has-text-primary is-size-5 has-text-weight-bold"
          to="/faq">
          {{ $t('views.share.help-faq') }}
        </router-link>
      </li>
      <li>
        <router-link
          class="has-text-primary is-size-5 has-text-weight-bold"
          to="/feedback">
          {{ $t('views.share.feedback-form') }}
        </router-link>
      </li>
      <li>
        <div class="has-text-primary is-size-5 has-text-weight-bold m-b">
          {{ $t('views.share.contact') }}
        </div>
        <div class="m-b">
          <a
            class="has-text-secondary "
            :href="`mailto:${$store.state.zumata.email}`">{{ $store.state.zumata.email }}</a>
        </div>
        <div class="m-b">
          <a
            :href="`${$store.state.zumata.whatsapp}`"
            class="has-text-secondary "
            target="_blank">{{ $store.state.zumata.tel2 }}</a>
          <img
            class="image"
            src="../../../public/img/icons/dashicons_whatsapp.svg">
        </div>
      </li>
    </ul>
  </el-popover>
</template>

<script>
import IconQuestion from '@/components/icons/IconPhone'
export default {
  name: 'Contact',
  components: { IconQuestion },
  mounted () {
    window.$crisp = []
    window.CRISP_WEBSITE_ID = process.env.VUE_APP_CRISP_WEBSITE_ID
    ;(function () {
      const s = document.createElement('script')
      s.src = 'https://client.crisp.chat/l.js'
      s.async = 1
      document.getElementsByTagName('head')[0].appendChild(s)
    })()
  }
}
</script>

<style lang="scss">
  @import "../../styles/bulma-variables";

  .contact {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    bottom: 20px;
    right: 90px;
    width: 60px;
    height: 60px;
    z-index: #{$_jh_fullscreen-z-index - 1};
    border-radius: 50%;
    background-color: $button-secondary-color;
    color: $white;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);

    &:hover {
      background-color: darken($button-secondary-color, 5%);
    }
  }

  .contact-popover {
    border: 1px solid $contact-border-color;
    padding: 0;
  }

  .contact-content {
    li {
      padding: 10px;

      &:not(:last-child) {
        border-bottom: 1px solid $contact-border-color;
      }
    }
  }

  .image {
    margin-bottom: -2px;
    margin-left: 1px;
  }

  #crisp-chatbox .cc-imbb {
    background: $color-4 !important;
  }

</style>
