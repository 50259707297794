import cookies from 'js-cookie'

// In the firebox private window mode, newly opened windows cannot share localStorage. Therefore, user auth data is best stored in a cookie (fix bug AP-529)
export const retrieveUser = (key) => {
  if (key === 'usrToken') return cookies.get(`${process.env.VUE_APP_PREFIX_NAME}usrToken`)

  let profile = cookies.get(process.env.VUE_APP_AUTH_STORAGE_KEY)
  if (profile) {
    profile = JSON.parse(profile)
  } else {
    return null
  }

  switch (key) {
  // case 'usrToken':
  //   return cookies.get('usrToken')
  case 'uid':
    return profile.uid || ''
  case 'displayName':
    return profile.displayName || ''
  case 'firstName':
    return profile.firstName || ''
  case 'lastName':
    return profile.lastName || ''
  case 'email':
    return profile.email || ''
  case 'role':
    return profile.roleCode || ''
  case 'touVersion':
    return profile.touVersion || ''
  default:
    return profile
  }
}

export const retrieveAgent = (key) => {
  if (key === 'accessKey') return cookies.get(`${process.env.VUE_APP_PREFIX_NAME}ap_accessKey`)

  let agent = localStorage.getItem(`${process.env.VUE_APP_PREFIX_NAME}ap_agent`)
  if (agent) agent = JSON.parse(agent)

  switch (key) {
  // case 'accessKey':
  //   return cookies.get(`${process.env.VUE_APP_PREFIX_NAME}ap_accessKey`)
  case 'defaultSourceMarket':
    return agent.defaultSourceMarket
  case 'defaultLocale':
    return agent.defaultLocale || 'en-US'
  case 'defaultCurrency':
    return agent.defaultDisplayCurrency
  case 'email':
    return agent.email
  case 'supportedCurrencies':
    return agent.supportedCurrencies
  case 'companyName':
    return agent.companyName
  case 'timezone':
    return agent.timezone
  case 'contactNo':
    let contactNoArray = agent.contactNo.split(')')
    if (contactNoArray.length === 2) {
      contactNoArray[0] = contactNoArray[0].substr(1)
      contactNoArray[1] = contactNoArray[1].trim()
      return contactNoArray
    } else {
      return null
    }
  case 'wallet':
    return agent.wallet
  default:
    return agent
  }
}

export const retrieveAdmin = (key) => {
  if (key === 'usrToken') return cookies.get(`${process.env.VUE_APP_PREFIX_NAME}adminToken`)

  let profile = localStorage.getItem(process.env.VUE_APP_ADMIN_STORAGE_KEY)
  if (profile) profile = JSON.parse(profile)
  else return null

  switch (key) {
  // case 'usrToken':
  //   return cookies.get(`${process.env.VUE_APP_PREFIX_NAME}adminToken`)
  case 'uid':
    return profile.uid
  case 'displayName':
    return profile.displayName || ''
  case 'firstName':
    return profile.firstName || ''
  case 'lastName':
    return profile.lastName || ''
  case 'email':
    return profile.email || ''
  case 'role':
    return profile.roleCode || ''
  default:
    return profile
  }
}

export const isAdminLoggedIn = () => {
  return typeof retrieveAdmin('usrToken') === 'string'
}

export const isLoggedIn = () => {
  return typeof retrieveAgent('accessKey') === 'string' &&
    typeof retrieveUser('usrToken') === 'string' &&
    typeof retrieveUser('uid') === 'string'
}

export const hasRole = (roleCode) => {
  if (!retrieveUser()) return null
  if (!roleCode) return true
  if (typeof roleCode === 'string') return retrieveUser('role') === roleCode
  return roleCode.includes(retrieveUser('role'))
}

export const adminHasRole = (roleCode) => {
  if (!retrieveAdmin()) return null
  if (!roleCode) return true
  if (typeof roleCode === 'string') return retrieveAdmin('role') === roleCode
  return roleCode.includes(retrieveAdmin('role'))
}

export const doLogout = () => {
  // localStorage.clear()
  // only clear server side data
  localStorage.removeItem(`${process.env.VUE_APP_PREFIX_NAME}ap_agent`)
  localStorage.removeItem(process.env.VUE_APP_ADMIN_STORAGE_KEY)
  cookies.remove(`${process.env.VUE_APP_PREFIX_NAME}ap_accessKey`)
  cookies.remove(`${process.env.VUE_APP_PREFIX_NAME}usrToken`)
  cookies.remove(`${process.env.VUE_APP_PREFIX_NAME}adminToken`)
  cookies.remove(`${process.env.VUE_APP_PREFIX_NAME}locale`)
  cookies.remove(`${process.env.VUE_APP_PREFIX_NAME}prfs`)
  cookies.remove(process.env.VUE_APP_AUTH_STORAGE_KEY)

  window.location.href = '/'
}
