<template>
  <div>
    <div
      v-if="$i18n.locale==='zh-CN'"
      class="container is-tablet">
      <h1
        class="m-b-5x"
        :class="titleClass">
        使用条款
      </h1>
      <div class="content">
        <p>
          请仔细阅读以下条款。本网站由 Viva Connect (PTY) LTD（“eBEDS”、“我们”、“我们”、“我们”）拥有和管理，通过评估本网站及其所有页面，您同意遵守以下条款和条件。如果您不同意以下条款和条件，请不要访问本网站或其任何页面。<br><br>
          eBEDS网站由各种网站和网页组成，这些网站和网页目前为用户提供访问大量在线资源的途径，包括各种通信工具、在线论坛、商业服务、个性化内容和信息（统称为“在线设施”）。<br><br>
          本网站的某些部分或页面可能包含附加条款和条件，附加条款和条件是对这些条款和条件的补充。如有冲突，以相关章节或页面的附加条款为准。
        </p>
        <h3 class="is-size-5 has-text-primary has-text-weight-bold m-b">
          隐私权与个人信息保护
        </h3>
        <p>有关收集和使用您的个人信息的披露，请参阅我们的隐私政策。</p>
        <h3 class="is-size-5 has-text-primary has-text-weight-bold m-b">
          用户行为
        </h3>
        <p>您不得将eBEDS网站或在线设施用于任何非法或本协议条款禁止的目的。除非另有说明，eBEDS网站和在线设施仅供您个人和非商业用途。此外，但不限于：</p>
        <ol>
          <li>你保证你至少18岁，头脑健全。</li>
          <li>您不得复制、修改、传输、分发、再制造、发布、执行或出售从我们的网站和在线设施获得的任何信息、材料、产品或服务，或以任何商业方式使用它们。</li>
          <li>您不得以任何可能损坏、禁用、负担过重或损害我们的任何网站和在线设施或其网络的方式使用我们的网站或在线设施。</li>
          <li>您不会妨碍任何其他方使用和享用我们的任何网站或在线设施。</li>
          <li>您不得试图通过黑客攻击、密码挖掘或任何其他方式未经授权访问我们的任何网站或在线设施、帐户、计算机系统或与我们的网站或在线设施连接的网络</li>
          <li>您不得试图通过我们的网站或在线设施无意提供的任何方式获取任何材料或信息。</li>
        </ol>
        <h3 class="is-size-5 has-text-primary has-text-weight-bold m-b">
          使用在线设施
        </h3>
        <p>我们的网站和在线设施可能包含电子邮件服务、通知、公告板、社区、聊天区、新闻组、论坛和/或其他通信设施。您同意仅使用这些通讯设施来发布、发送和接收适当且不以任何方式违法的信息和材料。你不会：</p>
        <ol>
          <li>使用与调查、竞赛、传销、连锁信、垃圾邮件、滥发邮件或任何重复或未经请求的信息（商业或其他）有关的通信设施。</li>
          <li>诽谤、虐待、骚扰、跟踪、威胁或以其他方式侵犯他人的合法权利（如隐私权和公开权）。</li>
          <li>发布、提交、上传、分发或传播任何不适当、亵渎、诽谤、淫秽、不雅或非法的主题、名称、材料或信息。</li>
          <li>发布、提交、上传、分发或传播因属于任何种族、宗教或国家而煽动对一个人或一个群体的歧视、仇恨或暴力的任何主题、姓名、材料或信息。</li>
          <li>上传或以其他方式提供包含受知识产权法保护的图像、照片、软件或其他材料的文件，包括版权法或商标法（或隐私权或公开权），除非您拥有或控制其权利，或已获得所有必要的同意。</li>
          <li>以侵犯任何一方的版权、商标、专利、商业秘密或其他专有权利的任何方式使用通过我们的网站或在线设施提供的任何材料或信息，包括图片或照片。</li>
          <li>上载包含病毒、特洛伊木马、蠕虫、定时炸弹、删除蝇程式、损坏的文件或任何其他可能损坏他人计算机操作或他人财产的类似软件或程序的文件。</li>
          <li>在我们的网站上使用元标记搜索。</li>
          <li>为任何商业目的宣传或提议出售或购买任何商品或服务，除非此类通信设施特别允许此类信息。</li>
          <li>下载您知道或理应知道的、无法以这种方式合法分发的由通信设施的另一用户发布的任何文件。</li>
          <li>伪造或删除上传文件中所含软件或其他材料的来源或来源的任何作者归属、法律或其他适当通知、专有名称或标签。</li>
          <li>限制或禁止任何其他用户使用和享用通信设施。</li>
          <li>违反任何可能适用于任何特定通信设施的行为准则或其他准则。</li>
          <li>收集或以其他方式收集有关他人的信息，包括但不限于电子邮件地址。</li>
          <li>违反任何适用的法律或法规。</li>
          <li>为了误导他人而制造虚假身份。</li>
          <li>使用、下载、复制或向某人提供（无论是否收费）我们网站或在线设施的用户目录或其他用户或使用信息。</li>
        </ol>
        <p>
          虽然我们没有义务监控通讯设施，但我们保留审查使用通讯设施提交的材料的权利，并自行决定移除任何材料。<br><br>
          我们也保留随时终止您使用任何或所有通讯服务的权利，无需任何通知，无论出于任何原因。<br><br>
          我们还保留披露我们认为满足任何适用法律、法规或法律程序所需的任何信息的权利，或自行决定编辑、删除或拒绝发布任何信息或材料的权利。我们特别声明不承担与通讯设施有关的任何责任，也不承担因您使用任何通讯设施而导致的任何行为。
        </p>
        <h3 class="is-size-5 has-text-primary has-text-weight-bold m-b">
          不担保
        </h3>
        <ol>
          <li>本网站及其所有信息和材料均以“现有”和“可用”为基础提供。我们不保证本网站和/或其资料的准确性、充分性或完整性。我们明确声明不对信息和材料中的任何错误或遗漏承担任何责任。</li>
          <li>材料/本网站不提供任何形式的担保，包括但不限于不侵犯第三方权利、所有权、适销性、令人满意的质量/特定用途适用性的担保。</li>
          <li>我们不保证任何材料或本网站将及时提供，不间断或没有错误，或任何确定的缺陷将得到纠正。</li>
          <li>我们不保证本网站及其资料没有任何病毒或其他恶意的，破坏性的或破坏性的代码，程序或宏。</li>
          <li>除非在条款和条件中明确说明，否则您从我们的网站或在线设施获得的任何建议或信息均不构成任何保证。</li>
          <li>通过使用我们的网站和在线设施下载或以其他方式获得的任何材料由您自行决定并承担风险，您将对下载任何此类材料导致的任何计算机系统损坏或数据丢失承担全部责任。</li>
        </ol>
        <h3 class="is-size-5 has-text-primary has-text-weight-bold m-b">
          责任免除
        </h3>
        <p>在任何情况下，我们对任何损害、损失或费用概不负责，包括但不限于直接、间接、特殊或后果性或惩罚性损害，或因以下原因或与之相关的经济损失：</p>
        <ol>
          <li>任何访问、使用或无法访问或使用我们的网站或在线设施，或依赖我们网站中的材料或任何信息。</li>
          <li>任何系统、服务器或连接故障、错误、遗漏、中断、传输延迟或计算机病毒。</li>
          <li>使用或访问链接到此网站的任何其他网站。</li>
        </ol>
        <h3 class="is-size-5 has-text-primary has-text-weight-bold m-b">
          链接到第三方网站
        </h3>
        <p>
          为了您的方便，我们的网站和在线设施可能包含指向第三方网站的超链接。此类链接网站不受我们控制，我们不对此类链接网站的内容或访问此类网站中任何链接的后果负责。我们也不对从任何第三方网站收到的任何形式的传输负责。包含任何链接并不意味着eBEDS认可任何此类第三方网站或与其所有者或运营商的任何关联。<br><br>
          您同意，任何访问和/或使用此类链接网站的风险由您自己承担，与我们网站和在线设施内的任何此类第三方的任何交易或参与任何促销和/或交易，以及与此类交易相关的任何其他条款和条件仅在您和第三方之间。我们不对此类交易或促销的任何部分负责。
        </p>
        <h3 class="is-size-5 has-text-primary has-text-weight-bold m-b">
          知识产权
        </h3>
        <p>
          我们网站和在线设施的所有知识产权，包括但不限于版权、商标、注册或未注册的服务商标和徽标，均为eBEDS、其战略合作伙伴和/或许可方的财产。因此，不得以任何方式复制、分发、改编、修改、再版、显示、传送、广播或超链接，也不得以任何方式储存在信息检索系统中。<br><br>
          您不能在任何其他网站上插入到我们网站的超链接。eBEDS的名称不得以任何方式使用，包括与本网站上任何材料相关的广告或宣传。但是，您可以下载并打印我们网站和在线设施上的材料和信息，供您个人、非商业使用。
        </p>
        <h3 class="is-size-5 has-text-primary has-text-weight-bold m-b">
          条款和条件的修改
        </h3>
        <p>我们保留修改和更改任何条款和条件的权利，包括但不限于与您使用我们的网站和在线设施有关的费用和收费，恕不另行通知。</p>
        <p>对这些条款和条件的所有修改将通过在我们的网站上发布修改后的条款和条件反映出来。此外，如果这些条款和条件有任何重大变化，我们将在我们的网站上表明，我们的条款和条件已经改变。</p>
        <h3 class="is-size-5 has-text-primary has-text-weight-bold m-b">
          修改网站和在线设施
        </h3>
        <p>我们保留随时及不时修改或暂时或永久终止其任何网站及网上设施的权利，不论是否另行通知。您同意，对于网站和在线设施的任何修改、暂停或终止，我们不对您或任何第三方承担任何责任。</p>
        <h3 class="is-size-5 has-text-primary has-text-weight-bold m-b">
          终止
        </h3>
        <p>
          我们保留暂停或终止您的帐户的权利，并以任何理由拒绝您当前或将来使用我们的任何网站或在线设施，无论出于何种原因，包括我们有理由相信您违反了条款和条件或行为与条款和条件不符。<br>
          <br>
          我们对您或任何第三方终止访问我们的网站和/或在线设施不承担任何责任。
        </p>
        <h3 class="is-size-5 has-text-primary has-text-weight-bold m-b">
          适用法律
        </h3>
        <p>通过访问我们的网站和在线设施，您同意此类访问和/或使用以及上述条款和条件应受新加坡法律的管辖，包括但不限于《电子交易法》（第88章）。您还同意不可撤销地服从新加坡法院的非排他性管辖。</p>
        <h3 class="is-size-5 has-text-primary has-text-weight-bold m-b">
          第三方权利
        </h3>
        <p>未同意受上述条款和条件约束的第三方无权根据《2001年合同（第三方权利）法》执行上述任何条款和条件。</p>
        <h3 class="is-size-5 has-text-primary has-text-weight-bold m-b">
          联系我们
        </h3>
        <p>有关eBEDS网站的任何问题，包括预订和付款相关的问题，如退款、取消预订和退单，请联系我们的客户服务支持。</p>
        <h3 class="is-size-5 has-text-primary has-text-weight-bold m-b">
          浏览器兼容性
        </h3>
        <p>
          此网站的最佳浏览方式是：<br><br>
          谷歌Chrome最新版本。<br>
          Internet Explorer 9及更高版本。<br>
          Mozilla Firefox 24及以上版本。<br>
          适用于Mac/iOS的Apple Safari 7.0。<br>
          iOS 6.0和以上。<br>
          Android 4.0及以上版本
        </p>
      </div>
    </div>
    <div
      v-else
      class="container is-tablet">
      <h1
        v-if="layout !== 'modal'"
        class="m-b-5x"
        :class="titleClass">
        Terms Of Use
      </h1>
      <div class="content">
        <p>
          Please read the following Terms and Conditions carefully. This website is owned and managed by Viva Connect (PTY) LTD ("eBEDS", "we", "us", "our"), by assessing this website and all its pages, you agree to be bound by the Terms and Conditions below. If you do not agree to the Terms and Conditions below, do not access this website or any pages thereof.<br><br>
          eBEDS website consists of various websites and pages which currently provide users access to a large collection of online resources, including various communication tools, online forums, commercial services, personalised content and information (collectively “Online Facilities”).<br><br>
          Certain sections or pages on this website may contain additional terms and conditions, which are in addition to these Terms and Conditions. In the event of a conflict, the additional terms and conditions will prevail for those relevant sections or pages.
        </p>
        <h3 class="is-size-5 has-text-primary has-text-weight-bold m-b">
          Privacy and Protection of Personal Information
        </h3>
        <p>Please see our Privacy Policy for disclosures relating to the collection and use of your personal information.</p>
        <h3 class="is-size-5 has-text-primary has-text-weight-bold m-b">
          User's Conduct
        </h3>
        <p>You will not use the eBEDS websites or Online Facilities for any purpose that is unlawful or prohibited by these Terms and Conditions. Unless otherwise specified, the eBEDS websites and Online Facilities are for your personal and non-commercial use only. In addition, but without limitation:</p>
        <ol>
          <li>You warrant that you are at least 18 years of age and are of sound mind.</li>
          <li>You may not copy, modify, transmit, distribute, reproduce, publish, perform or sell any information, materials, products or services obtained from our websites and Online Facilities, or use them in any commercial manner.</li>
          <li>You will not use our websites or Online Facilities in any manner that could damage, disable, overburden, or impair any of our websites and Online Facilities or its network.</li>
          <li>You will not interfere with any other party's use and enjoyment of any of our websites or Online Facilities.</li>
          <li>You will not attempt to gain unauthorized access to any of our websites or Online Facilities, accounts, computer systems or networks connected to our websites or Online Facilities, through hacking, password mining or any other means.</li>
          <li>You will not attempt to obtain any materials or information through any means not intentionally made available through our websites or Online Facilities.</li>
        </ol>
        <h3 class="is-size-5 has-text-primary has-text-weight-bold m-b">
          Use of Online Facilities
        </h3>
        <p>Our websites and Online Facilities may contain e-mail services, notices, bulletin boards, communities, chat areas, news groups, forums and/or other communication facilities. You agree to use these communication facilities only to post, send and receive messages and material that are proper and not offending in any manner. You will not:</p>
        <ol>
          <li>Use the communication facilities in connection with surveys, contests, pyramid schemes, chain letters, junk emails, spams or any duplicative or unsolicited messages (commercial or otherwise).</li>
          <li>Defame, abuse, harass, stalk, threaten or otherwise violate the legal rights (such as rights of privacy and publicity) of others.</li>
          <li>Publish, post, upload, distribute or disseminate any inappropriate, profane, defamatory, obscene, indecent or unlawful topic, name, material or information.</li>
          <li>Publish, post, upload, distribute or disseminate any topic, name, material or information that incites discrimination, hate or violence towards one person or a group because of their belonging to any race, religion or nation.</li>
          <li>Upload, or otherwise make available, files that contain images, photographs, software or other material protected by intellectual property laws, including, for example, copyright or trademark laws (or by rights of privacy or publicity) unless you own or control the rights thereto or have received all necessary consents to do the same.</li>
          <li>Use any material or information, including images or photographs, which are made available through our websites or Online Facilities in any manner that infringes any copyright, trademark, patent, trade secret, or other proprietary right of any party.</li>
          <li>Upload files that contain viruses, Trojan horses, worms, time bombs, cancelbots, corrupted files, or any other similar software or programs that may damage the operation of another's computer or property of another.</li>
          <li>Use of meta tag searches on our websites.</li>
          <li>Advertise or offer to sell or buy any goods or services for any business purpose, unless such communication facilities specifically allow such messages.</li>
          <li>Download any file posted by another user of the communication facilities that you know, or reasonably should know, which cannot be legally distributed in such manner.</li>
          <li>Falsify or delete any author attributions, legal or other proper notices or proprietary designations or labels of the origin or source of software or other material contained in a file that is uploaded.</li>
          <li>Restrict or inhibit any other user from using and enjoying the communication facilities.</li>
          <li>Violate any code of conduct or other guidelines which may be applicable for any particular communication facilities.</li>
          <li>Harvest or otherwise collect information about others, including but not limited to e-mail addresses.</li>
          <li>Violate any applicable laws or regulations.</li>
          <li>Create a false identity for the purpose of misleading others.</li>
          <li>Use, download, copy, or provide (whether or not for a fee) to a person any directory of users of our website or Online Facilities or other user or usage information.</li>
        </ol>
        <p>
          While we have no obligation to monitor the communication facilities, we reserve the right to review materials posted using the communication facilities and to remove any materials in our sole discretion.<br><br>
          We also reserve the right to terminate your access to any or all of the communication services at any time, without any notice, for any reason whatsoever.<br><br>
          We further reserve the right to disclose any information as we deem necessary to satisfy any applicable law, regulation or legal process, or to edit, remove, or refuse to post any information or materials in our sole discretion. We specifically disclaim any liability with regard to the communication facilities and any actions resulting from your utilising any of the communication facilities.
        </p>
        <h3 class="is-size-5 has-text-primary has-text-weight-bold m-b">
          No Warranty
        </h3>
        <ol>
          <li>This website and all its information and materials are provided on an “as is” and “as available” basis. We do not warrant the accuracy, adequacy or completeness of this website and/or its materials. We expressly disclaim any liability for any errors or omissions in the information and materials.</li>
          <li>No warranty of any kind, implied, express or statutory, including but not limited to the warranties of non-infringement of third party rights, title, merchantability, satisfactory quality and/or fitness for a particular purpose, is given in conjunction with the materials and/or this website.</li>
          <li>We do not warrant that any of the materials or that this website will be timely provided, uninterrupted or free from errors or that any identified defect will be corrected.</li>
          <li>We do not warrant that this website and its materials are free from any virus or other malicious, destructive or corrupting code, program or macro.</li>
          <li>No advice or information obtained by you from our websites or Online Facilities shall create any warranty unless expressly stated in the Terms and Conditions.</li>
          <li>Any material downloaded or otherwise obtained through the use of our websites and Online Facilities is done at your own discretion and risk and you will be solely responsible for any damage to your computer system or loss of data that results from the download of any such material.</li>
        </ol>
        <h3 class="is-size-5 has-text-primary has-text-weight-bold m-b">
          Exclusion of liability
        </h3>
        <p>We shall in no event be liable for any damage, loss or expense including without limitation, direct, indirect, special, or consequential or punitive damage, or economic loss arising from or in connection with:</p>
        <ol>
          <li>any access, use or the inability to access or use our websites or Online Facilities, or reliance on the materials and/or any information in our websites.</li>
          <li>any system, server or connection failure, error, omission, interruption, delay in transmission, or computer virus.</li>
          <li>any use of or access to any other websites linked to this website.</li>
        </ol>
        <h3
          id="third-party"
          class="is-size-5 has-text-primary has-text-weight-bold m-b">
          Links to Third Party Websites
        </h3>
        <p>
          Our websites and Online Facilities may contain hyperlinks to third party websites for your convenience. Such linked websites are not under our control and we are not responsible for the contents of such linked websites or the consequences of accessing any link in any such websites. We are also not responsible for any forms of transmission received from any third party websites. The inclusion of any link does not imply endorsement by eBEDS of any such third party websites or any association with its owners or operators.<br><br>
          You agree that any access to and/or use of such linked websites is at your own risk and any dealings with any such third parties included within our websites and Online Facilities or participation in any promotion and/or transaction, and any other terms and conditions associated with such transactions are solely between you and the third party. We will not be liable or responsible for any part of such transactions or promotions.
        </p>
        <p>Particularly to our third-party plugins such as Booking.com, Rentalcars.com and any other plugin or integration, you agree to the booking terms and conditions, cancellation policies and payment terms as stipulated on their websites.</p>
        <h3 class="is-size-5 has-text-primary has-text-weight-bold m-b">
          Intellectual Property
        </h3>
        <p>
          All intellectual property rights to our websites and Online Facilities, including but without limitation to, copyright, trademarks, registered or unregistered service marks and logos are the property of eBEDS, its strategic partners and/or licensors. As such, they may not be reproduced, distributed, adapted, modified, republished, displayed, transmitted, broadcast or hyperlinked in any manner or stored in an information retrieval system by any means.<br><br>
          You may not insert a hyperlink to our website on any other of our websites. The name of eBEDS may not be used in any way, including advertising or publicity pertaining to any material on this website. However you may download and print the materials and information found on our websites and Online Facilities for your personal, non-commercial use.
        </p>
        <h3 class="is-size-5 has-text-primary has-text-weight-bold m-b">
          Modification of Terms and Conditions
        </h3>
        <p>We reserve the right to modify and make changes to any of these terms and conditions, including but not limited to the fees and charges relating to your use of our websites and Online Facilities, without notice.</p>
        <p>All modifications to these terms and conditions will be reflected by the posting of the amended terms and conditions on our website. In addition, if there are any material change to any of these terms and conditions, we will indicate on our website that our terms and conditions have changed.</p>
        <h3 class="is-size-5 has-text-primary has-text-weight-bold m-b">
          Modification of Websites and Online Facilities
        </h3>
        <p>We reserve the right at any time and from time to time to modify or discontinue, temporarily or permanently, any of its websites and Online Facilities with or without notice. You agree that we will NOT be liable or responsible in any way to you or to any third party for any modification, suspension or discontinuance of the websites and Online Facilities.</p>
        <h3 class="is-size-5 has-text-primary has-text-weight-bold m-b">
          Termination
        </h3>
        <p>
          We reserve the right to suspend or terminate your account and refuse you any current or future use of any of our websites or Online Facilities in our sole discretion, without any notice, for any reason whatsoever, including where we have reasons to believe that you have breached or acted inconsistently with the Terms and Conditions.<br>
          <br>
          We will not be liable in any way to you or to any third party for any termination of your access to our websites and/or the Online Facilities.
        </p>
        <h3 class="is-size-5 has-text-primary has-text-weight-bold m-b">
          Governing Law
        </h3>
        <p>By accessing our websites and Online Facilities, you agree that such access and/or use, as well as the above Terms and Conditions shall be governed by the laws of Singapore, including but not limited to the Electronic Transactions Act (Cap.88). You further agree to submit irrevocably to the non-exclusive jurisdiction of the Singapore courts.</p>
        <h3 class="is-size-5 has-text-primary has-text-weight-bold m-b">
          Third Parties Rights
        </h3>
        <p>No third party who has not agreed to be bound by the above Terms and Conditions shall have any right pursuant to the Contracts (Right of Third Parties) Act 2001 to enforce any of the above Terms and Conditions.</p>
        <h3 class="is-size-5 has-text-primary has-text-weight-bold m-b">
          Contact Us
        </h3>
        <p>Any issues regarding the eBEDS website, including booking and payment related issues such as refunds, cancellations and chargebacks, please contact our customer service support.</p>
        <h3 class="is-size-5 has-text-primary has-text-weight-bold m-b">
          Browser Compatibility
        </h3>
        <p>
          This website is best viewed with:<br><br>
          Google Chrome latest version.<br>
          Internet Explorer 9 and above.<br>
          Mozilla Firefox 24 and above.<br>
          Apple Safari 7.0 for Mac/iOS.<br>
          iOS 6.0 and above.<br>
          Android 4.0 and above
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import smoothScroll from '../plugins/smoothScroll'

export default {
  name: 'TouContent',
  props: {
    titleClass: {
      type: String,
      default: 'is-size-3 has-text-weight-bold has-text-primary'
    },
    layout: {
      type: String,
      default: ''
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.$route.hash) {
        smoothScroll.scrollTo(this.$route.hash)
      }
    })
  }
}
</script>
