<template>
  <div class="app-header">
    <nav
      class="admin-navbar"
      role="navigation"
      aria-label="main navigation">
      <div class="container is-fluid">
        <div
          v-if="isLoggedIn"
          class="admin-navbar-brand">
          <a
            role="button"
            class="navbar-burger burger admin-navbar-burger"
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
            @click="handleBurgerClicked">
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </a>
        </div>
        <div class="admin-navbar-menu is-active">
          <div class="admin-navbar-end has-text-weight-bold">
            <template v-if="isLoggedIn">
              <span class="admin-navbar-item">{{ displayName }}</span>
              <a
                class="admin-navbar-item has-text-secondary"
                @click.prevent="handleLogout">{{ $t('views.TheHeader.logout') }}</a>
            </template>
            <template v-else>
              <router-link
                to="/admin/login"
                class="admin-navbar-item has-text-secondary">
                {{ $t('views.TheHeader.login') }}
              </router-link>
            </template>
          </div>
        </div>
      </div>
    </nav>
    <el-drawer
      :visible.sync="adminMenuActive"
      title=" "
      direction="ltr"
      size="100"
      class="admin-menu-drawer">
      <div class="drawer-content">
        <ul class="admin-sidebar-menu">
          <li class="menu-text">
            <span class="is-size-7">{{ $t('views.admin.Admin') }}</span>
          </li>
          <li>
            <a @click.prevent="sidebarMenuSelected('/admin/client-management')">{{ $t('views.admin.Client Management') }}</a>
          </li>
          <li>
            <a @click.prevent="sidebarMenuSelected('/admin/user-management')">{{ $t('views.admin.User Management') }}</a>
          </li>
          <li>
            <a
              v-if="$adminHasRole(['SUPER_ADMIN']) && $enableMasterSettings()"
              @click.prevent="sidebarMenuSelected('/admin/master-settings')">Master Settings</a>
          </li>
        </ul>
        <ul class="admin-sidebar-menu">
          <li class="menu-text">
            <span class="is-size-7">{{ $t('views.admin.Bookings') }}</span>
          </li>
          <li>
            <a @click.prevent="sidebarMenuSelected('/admin/orders-management')">{{ $t('views.admin.Order Management') }}</a>
          </li>
        </ul>
        <ul
          v-if="$enableWallet() && $adminHasRole(['SUPER_ADMIN', 'FINANCE_ADMIN'])"
          class="admin-sidebar-menu">
          <li class="menu-text">
            <span class="is-size-7">{{ $t('views.admin.Financials') }}</span>
          </li>
          <li>
            <a @click.prevent="sidebarMenuSelected('/admin/top-up-requests')">{{ $t('views.admin.Top up requests') }}</a>
          </li>
        </ul>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { doLogout, isAdminLoggedIn, retrieveAdmin } from '@/service/auth'

export default {
  name: 'AdminHeader',
  data () {
    return {
      adminMenuActive: false
    }
  },
  computed: {
    isLoggedIn () {
      return isAdminLoggedIn()
    },
    displayName () {
      return retrieveAdmin('displayName')
    }
  },
  methods: {
    handleBurgerClicked () {
      this.adminMenuActive = !this.adminMenuActive
    },
    handleLogout () {
      doLogout()
      window.location = this.$router.resolve({ name: 'admin/login' }).href
    },
    sidebarMenuSelected (location) {
      if ((location === '/admin/master-settings') && !this.$enableMasterSettingsBookAndHold()) {
        location = '/admin/master-settings-pricing'
      }
      this.$router.push(location, () => {
        this.adminMenuActive = false
      })
    }
  }
}
</script>

<style scoped>

</style>
