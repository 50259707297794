<template>
  <span>{{ counter }}</span>
</template>
<script>
export default {
  name: 'RefreshTimer',
  data () {
    return {
      countDown: 30
    }
  },
  computed: {
    counter () {
      return this.countDown
    }
  },
  created: function () {
    this.$nextTick(function () {
      this.timerCounting()
    })
  },
  methods: {
    timerCounting () {
      var intervalID = setInterval(() => {
        if (this.countDown > 0) {
          this.countDown -= 1
          // this.timerCounting()
        } else {
          console.log('END')
          clearInterval(intervalID)
          // Hide refresh
          this.$store.commit('SET_GLOBAL_MODAL', { refresh: false }, { root: true })
          this.$router.go(this.$router.currentRoute)
        }
      }, 1000)
    }
  }
}
</script>
<style scoped>

</style>
