<template>
  <img
    v-if="companyLogo"
    :src="companyLogo"
    :class="logoClass">
  <svg
    v-else
    :class="logoClass"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 200 71.46"><path
      class="cls-1"
      fill="#00a0d8"
      d="M191.71,54.12a35.17,35.17,0,0,1,9.2,3C212,62.5,219.1,71.92,221.08,83.78A45.4,45.4,0,0,1,220.73,97a35.7,35.7,0,0,1-28,27.44,40,40,0,0,1-13.81,0,34.9,34.9,0,0,1-18.37-9.83A35.48,35.48,0,0,1,150.06,90.9c-.12-2.21-.1-2.69.2-2.66a32.06,32.06,0,0,1,4,1.71l3.65,1.68.3,1.79A28.49,28.49,0,0,0,170,112.36,27.85,27.85,0,0,0,210.7,77a28.21,28.21,0,0,0-20-15c-10.57-2-21.69,2.72-24.59,10.54a11.2,11.2,0,0,0,.33,7.62c1.78,3.68,5.3,6.09,10.24,7a35.72,35.72,0,0,0,9.3-.1c4.91-1,8.06-3.32,7.32-5.28-.88-2.26-6.36-3.4-10.08-2.06a10.62,10.62,0,0,0-4.8,3.72c-.56.8-1.16,1.46-1.34,1.46-1.16,0-4.48-1.56-6-2.84l-.73-.61.83-1.48a21.14,21.14,0,0,1,6.67-6.44c3.3-1.71,5-2.09,9.43-2.07,3.55,0,4.08.08,5.72.68,6.89,2.47,10,8.76,7.27,14.7a15.19,15.19,0,0,1-5.44,5.43c-3.44,1.91-9.13,3.2-14.29,3.2h-2.16l1.08,1.11c3.32,3.37,8.56,4,13.41,1.51A13,13,0,0,0,195,96.64l.93-.85,2.77,2.77,2.74,2.76L200,102.73a21.74,21.74,0,0,1-8,4.15,23.28,23.28,0,0,1-9.68.13,20.09,20.09,0,0,1-9.87-6.32,19.73,19.73,0,0,1-3.27-6.44c-.22-1-.45-1.35-1.06-1.68a19.24,19.24,0,0,1-9.73-11.65,20.73,20.73,0,0,1-.15-9.31c2.4-9.16,12-16.38,23.62-17.86A42.35,42.35,0,0,1,191.71,54.12Z"
      transform="translate(-149.99 -53.6)" /><path
        class="cls-1"
        fill="#00a0d8"
        d="M344.69,70.42c2.72,1,3.15,1.49,3.25,3.9.1,2.22-.2,3.32-.93,3.32a13.35,13.35,0,0,1-2.67-1,8.84,8.84,0,0,0-4.27-1.16c-2.52-.13-3.63.18-4.66,1.36a2.59,2.59,0,0,0-.76,2.16c0,2.37,1.14,3.43,6,5.6s7.09,3.93,8.45,6.64a12.16,12.16,0,0,1,.63,6.8c-.88,4.08-3.95,7.12-8.37,8.33-4.83,1.31-12.23.18-14.4-2.24-.63-.68-.88-4.15-.4-5.31.4-1,.83-1,3.07.23a13.5,13.5,0,0,0,6.89,1.86c3.65,0,5.41-1.33,5.6-4.25.1-1.51.07-1.6-.86-2.62-.8-.85-1.89-1.51-5.28-3.17-4.93-2.4-6.8-3.84-8-6.21a10.53,10.53,0,0,1,2-12c2.44-2.26,4.91-3,9.54-2.92A13.15,13.15,0,0,1,344.69,70.42Z"
        transform="translate(-149.99 -53.6)" /><path
          class="cls-1"
          fill="#00a0d8"
          d="M285.62,70.52c.48.56.48,4.38,0,4.94-.28.37-1.2.42-6.92.47l-6.57.08-.07,4.33L272,84.69h5.64c4.93,0,5.66,0,6,.43a5,5,0,0,1,.35,2.46,5.1,5.1,0,0,1-.35,2.47c-.3.37-1,.43-6,.43H272l.05,5,.07,5,6.72.08c7.9.07,7.35-.18,7.2,3.25-.05,1.53-.18,2.16-.45,2.33s-4.38.25-10,.25H266l-.61-.62-.63-.61V88.34c0-11.38.08-16.94.28-17.26a1.88,1.88,0,0,1,.8-.73c.3-.13,4.8-.23,10-.23C284.34,70.1,285.31,70.15,285.62,70.52Z"
          transform="translate(-149.99 -53.6)" /><path
            class="cls-1"
            fill="#00a0d8"
            d="M258.22,91.88a8.06,8.06,0,0,0-4.88-4.7l-1.26-.4.91-.55a9.25,9.25,0,0,0,3.32-4.1,10.87,10.87,0,0,0-.45-6.87c-1.2-2.27-3.12-3.7-6.17-4.56-1.13-.32-3-.45-8.55-.52h-7.73c-.7,0-.67,1.53-.78,2.21s-.12,8.23-.07,16.91l.07,15.82.74.63.72.63,7.33-.13c6.66-.07,7.49-.15,9.2-.65a11.2,11.2,0,0,0,5.6-3.27c1.89-2,2.54-3.7,2.54-6.8A7.45,7.45,0,0,0,258.22,91.88ZM240.06,80.26v-4.7l3,.15c2.36.1,3.22.22,4,.65a4.15,4.15,0,0,1,2.16,4.6,4.09,4.09,0,0,1-2.36,3.4c-1,.5-1.51.58-3.95.58h-2.84Zm8.85,19.47c-1.14.66-1.36.68-5,.76l-3.85.1V90.48h3.1c4.57,0,6.54.75,7.49,2.86A5,5,0,0,1,248.91,99.73Z"
            transform="translate(-149.99 -53.6)" /><path
              class="cls-1"
              fill="#00a0d8"
              d="M322,81.8a15,15,0,0,0-11.57-11.1c-1.34-.3-3.57-.45-9.11-.53l-7.35-.12-.61.65-.66.63v34l.71.53c.67.56.78.56,7.85.43,6.18-.1,7.44-.18,9.23-.65,6.82-1.74,10.82-6.29,11.93-13.6A37.1,37.1,0,0,0,322,81.8ZM309,99.48c-1.31.6-1.76.68-5.25.76l-3.86.1V75.88H302c5,0,7.88.93,9.84,3,2.17,2.32,3,4.88,3,9.18C314.83,94.12,313,97.64,309,99.48Z"
              transform="translate(-149.99 -53.6)" /></svg>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'TheLogo',
  computed: {
    logoClass () {
      return this.$route.name === 'homepage' ? 'logo-home' : 'logo-page'
    },
    ...mapGetters('app', ['companyInfo']),
    companyLogo () {
      return this.companyInfo && this.companyInfo.images && this.companyInfo.images.mail && this.companyInfo.images.mail.url && this.companyInfo.images.customLogo ? this.companyInfo.images.mail.url : null
    }
  }
}
</script>
<style lang="scss" scoped>
  .logo-home {
    //width: 134px;
    height: 48px;
  }
  .logo-page {
    //width: 98px;
    height: 35px;
  }
</style>
