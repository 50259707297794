<template>
  <transition name="view-fade">
    <div
      v-show="fullScreenLoaderVisible"
      class="full-screen-loader"
      :style="`top:${top}px;`">
      <div class="wrapper">
        <div class="orange ball" />
        <div class="cyan ball" />
        <div class="blue ball" />
        <div class="yellow ball" />
      </div>
      <div class="loader-title">
        {{ title }}
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'FullScreenLoader',
  data () {
    return {
      top: 65,
      excludeElements: ['.app-header', '.hero-my-bookings']
    }
  },
  computed: {
    ...mapState(['fullScreenLoaderVisible', 'fullScreenLoaderTitle']),
    title () {
      return this.fullScreenLoaderTitle ? this.$t(`loader.${this.fullScreenLoaderTitle}`) : this.$t('loader.default')
    }
  },
  watch: {
    fullScreenLoaderVisible: {
      immediate: true,
      handler (nV) {
        if (nV) {
          this.top = 0
          this.excludeElements.forEach(el => {
            let $el = document.querySelector(el)
            if ($el) {
              this.top += $el.offsetHeight
            }
          })
          document.querySelector('body').classList.add('full-screen-loader-visible')
        } else {
          document.querySelector('body').classList.remove('full-screen-loader-visible')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../styles/bulma-variables";

  .full-screen-loader {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: $_jh_fullscreen-z-index + 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.75);
  }

  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px
  }

  .ball {
    width: 10px;
    height: 10px;
    border-radius: 6px;
    margin: 0 6px;

    animation: 1.5s bounce ease infinite;
  }

  .orange {
    background-color: #00A0D8
  }

  .cyan {
    background-color: #003061;
    animation-delay: .125s;
  }

  .blue {
    background-color: #00A0D8;
    animation-delay: .25s;
  }

  .yellow {
    background-color: #003061;
    animation-delay: .50s;
  }

  .loader-title {
    color: #002A5A;
    font-weight: bold;
    text-transform: uppercase;
    font-size: $size-6;
    margin-top: 10px;
  }

  @keyframes bounce {
    50% {
      transform: translateY(25px);
    }
  }
</style>
<style lang="scss">
  @import "../styles/bulma-variables";

  body.full-screen-loader-visible {
    overflow: hidden;

    .app-header {
      position: relative;
      z-index: $_jh_fullscreen-z-index + 1002;
    }
  }

</style>
