<script>
/**
 * @param size - x2, x3
 */
export default {
  name: 'ZuDivider',
  functional: true,
  props: {
    size: {
      type: String,
      default: ''
    }
  },
  render (h, context) {
    const { size } = context.props
    function sizeString (size) {
      switch (size) {
      case 'x2':
        return 'gap-x2'
      case 'x3':
        return 'gap-x3'
      default:
        return 'gap'
      }
    }
    const str = sizeString(size)
    const a = h('div', { class: str })
    const b = h('div', { class: 'divider' })
    const c = h('div', { class: str })
    return h('div', {
      class: {
        [context.data.staticClass]: context.data.staticClass
      }
    }, [a, b, c])
  }
}
</script>
