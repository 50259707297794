<template>
  <g>
    <path d="M7.70364 17.9C7.07964 17.9 6.53964 17.708 6.08364 17.324C5.65164 16.94 5.43564 16.436 5.43564 15.812C5.43564 14.828 5.68764 13.952 6.19164 13.184C6.71964 12.392 7.53564 11.588 8.63964 10.772C9.57564 10.1 10.2116 9.51201 10.5476 9.00801C10.9076 8.48001 11.0876 7.91601 11.0876 7.31601C11.0876 6.57201 10.7876 5.96001 10.1876 5.48001C9.58764 5.00001 8.81964 4.76001 7.88364 4.76001C6.49164 4.76001 5.14764 5.39601 3.85164 6.66801C3.32364 7.12401 2.80764 7.35201 2.30364 7.35201C1.77564 7.35201 1.33164 7.17201 0.971641 6.81201C0.611641 6.42801 0.431641 5.96001 0.431641 5.40801C0.431641 4.64001 0.851641 3.90801 1.69164 3.21201C2.53164 2.42001 3.51564 1.79601 4.64364 1.34001C5.79564 0.884006 7.00764 0.656006 8.27964 0.656006C9.76764 0.656006 11.0756 0.932006 12.2036 1.48401C13.3316 2.03601 14.2196 2.79201 14.8676 3.75201C15.5156 4.71201 15.8396 5.82801 15.8396 7.10001C15.8396 8.39601 15.5396 9.47601 14.9396 10.34C14.3396 11.18 13.4996 12.008 12.4196 12.824C11.5316 13.472 10.9076 14.048 10.5476 14.552C10.2116 15.056 9.98364 15.632 9.86364 16.28C9.71964 16.784 9.44364 17.18 9.03564 17.468C8.65164 17.756 8.20764 17.9 7.70364 17.9ZM7.70364 26.396C6.86364 26.396 6.14364 26.108 5.54364 25.532C4.94364 24.932 4.64364 24.212 4.64364 23.372C4.64364 22.532 4.94364 21.812 5.54364 21.212C6.14364 20.612 6.86364 20.312 7.70364 20.312C8.54364 20.312 9.26364 20.612 9.86364 21.212C10.4636 21.812 10.7636 22.532 10.7636 23.372C10.7636 24.212 10.4636 24.932 9.86364 25.532C9.26364 26.108 8.54364 26.396 7.70364 26.396Z" />
  </g>
</template>

<script>
export default {
  name: 'IconQuestion'
}
</script>

<style scoped>

</style>
