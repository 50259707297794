const k = `${process.env.VUE_APP_PREFIX_NAME}CHECKOUT_EXP_AT`
const removeExpiredItem = function (expObj) {
  let removeArr = []
  for (let ckToken in expObj) {
    if (expObj[ckToken] <= new Date().getTime()) {
      removeArr.push(ckToken)
    }
  }
  removeArr.forEach((ckToken) => {
    delete expObj[ckToken]
  })
  return expObj
}

export function setCheckoutExpiredAt (ckToken, time) {
  let expObj = window.localStorage.getItem(k)
  expObj = expObj ? JSON.parse(expObj) : {}
  expObj[ckToken] = time
  expObj = removeExpiredItem(expObj)
  window.localStorage.setItem(k, JSON.stringify(expObj))
}

/*
* @return false , don't display CountDown
 */
export function getCheckoutExpiredAt (ckToken) {
  let expObj = window.localStorage.getItem(k)
  if (!expObj) return false
  expObj = JSON.parse(expObj)
  removeExpiredItem(expObj)
  return expObj[ckToken] ? expObj[ckToken] : new Date(1970, 12, 12).getTime()
}
