import * as searchService from '@/service/search'

import { debug } from '@/plugins/util'
import { isCancel } from '@/service/api'
import moment from 'moment'
import i18n from '@/i18n'
import { retrieveUser } from '@/service/auth'
import uniqBy from 'lodash/uniqBy'
import Vue from 'vue'
import { times } from 'number-precision'

const RECENT_SEARCH_KEY = `${process.env.VUE_APP_PREFIX_NAME}ap_recent_search`
const getRecentSearchKey = function () {
  const uid = retrieveUser('uid') || ''
  return RECENT_SEARCH_KEY + uid + i18n.locale
}

const state = {
  selected: '',
  isSearching: false,
  isComplete: false,
  availability: {
    results: [],
    pagination: {}
  },
  searchIdTmp: undefined,
  isGeoEmpty: false,
  rate: undefined,
  nights: undefined,
  roomCount: undefined,
  queryParams: {
    checkInDate: '',
    checkOutDate: '',
    locationQuery: '',
    nationality: undefined,
    regionId: undefined,
    roomCount: 1,
    adultCount: 2,
    children: undefined,
    searchId: '',
    currency: undefined,
    page: 1,
    locale: undefined,
    lng: undefined,
    lat: undefined,
    radius: undefined
  },
  mapFitBounds: false,
  mapLatLngs: [],
  filterSummary: {
    category: {},
    trustYou: {},
    amenities: {},
    starRating: {},
    meal: {},
    payAtHotel: {}
  },
  recentSearches: [],
  timeout: undefined
}

const getters = {
  maxPriceRange: (state, getters, rootState) => {
    return state.rate && state.rate[state.targetCcy] ? (rootState.inNightlyAvgRate ? state.rate[state.targetCcy] : times(state.rate[state.targetCcy], state.roomCount, state.nights)) : undefined
  },
  getCountryCode: state => {
    return state.queryParams.nationality.a2
  },
  getStarRatingCount: (state) => (rating) => {
    const key = rating || 0
    return state.filterSummary.starRating[key]
      ? state.filterSummary.starRating[key]
      : 0
  },
  getTrustYouCount: (state) => (key) => {
    return state.filterSummary.trustYou[key]
      ? state.filterSummary.trustYou[key]
      : 0
  },
  getQuickMenuCount: (state) => (type, key) => {
    const _type = type === 'category' ? type : 'amenities'
    return state.filterSummary[_type][key]
      ? state.filterSummary[_type][key]
      : 0
  },
  getRefundableCount: (state) => (type, key) => {
    return state.filterSummary[type][key]
      ? state.filterSummary[type][key] : 0
  },
  getMealCount: (state) => (key) => {
    return state.filterSummary.meal && state.filterSummary.meal[key]
      ? state.filterSummary.meal[key]
      : 0
  },
  getPayAtHotelCount: (state) => {
    return typeof state.filterSummary.payAtHotel === 'number' ? state.filterSummary.payAtHotel : 0
  }
}

const mutations = {
  SET_SEARCH_MANY: (state, payload) => {
    if (debug) console.log('setSearchMany triggered with', payload)
    const keys = Object.keys(payload)
    keys.forEach((key) => {
      state[key] = payload[key]
    })
  },
  SET_QUERY_PARAMS: (state, payload) => {
    if (debug) console.log('setQueryParams triggered with', payload)
    const keys = Object.keys(payload)
    keys.forEach((key) => {
      state.queryParams[key] = payload[key]
    })
  },
  CLEAR_SEARCH_MANY: (state) => {
    if (debug) console.log('clearSearchMany triggered')
    state.selected = ''
    state.availability = { results: [] }
    state.isGeoEmpty = false
    state.rate = undefined
    state.nights = undefined
  },
  SET_RECENT_SEARCHES: (state, payload) => {
    state.recentSearches = payload
  },
  SET_TIMEOUT: (state, payload) => {
    state.timeout = payload
  },
  CLEAR_TIMEOUT: (state) => {
    if (state.timeout) {
      clearTimeout(state.timeout)
    }
  }
}

const actions = {
  // search({commit, dispatch, state}, query) {
  //   commit('SET_SEARCH_MANY', { isSearching: true })

  //   searchService.poll(() => {
  //     return searchService.many(query)
  //   }, 2000, 20).then(
  //     (resp) => {
  //       const { outlets, meta } = resp
  //       // set state on meta module
  //       commit('meta/SET_META_ACTION', meta, { root: true })
  //       commit('SET_SEARCH_MANY', {
  //         isSearching: false,
  //         isGeoEmpty: false,
  //         ...outlets
  //       })
  //       const dateNow = moment().format('YYYY-MM-DD')
  //       if (state.queryParams.checkInDate < dateNow ||
  //           state.queryParams.checkOutDate < dateNow) {
  //         dispatch(
  //           'setGlobalErrorModal',
  //           {
  //             show: true,
  //             title: i18n.t('app.error.past-date.title'),
  //             content: i18n.t('app.error.past-date.content')
  //           },
  //           { root: true }
  //         )
  //       }
  //     }
  //   ).catch((err) => {
  //     if (isCancel(err)) {
  //       if (debug) console.warn(`Cancel: ${err.message}`)
  //       if (err.message !== 'NEW_SEARCH_MANY') {
  //         commit('SET_SEARCH_MANY', {
  //           isSearching: false,
  //           isGeoEmpty: false,
  //           availability: { results: [] }
  //         })
  //         commit('meta/SET_META_ACTION', err.error.meta, { root: true })
  //         commit('SET_GLOBAL_MODAL', { show: true, content: err.message || err.error.message }, { root: true })
  //       }
  //     } else {
  //       commit('SET_SEARCH_MANY', {
  //         isSearching: false,
  //         isGeoEmpty: false
  //       })
  //       if (err.error && err.error.status === 403) {
  //         commit(
  //           'SET_GLOBAL_MODAL',
  //           {
  //             show: true,
  //             title: i18n.t('app.error.availabilitySearch.title'),
  //             content: i18n.t('app.error.availabilitySearch.content')
  //           },
  //           { root: true }
  //         )
  //       }
  //     }
  //   })
  // },
  search ({ commit, dispatch, state }, query) {
    const startTime = Date.now()
    commit('SET_SEARCH_MANY', { isSearching: true })
    if (query.searchId !== state.searchIdTmp) {
      commit('SET_SEARCH_MANY', { availability: { results: [] } })
    }

    // Clear any existing timeout for search before performing a new one
    commit('CLEAR_TIMEOUT')

    searchService.many(query).then(
      (resp) => {
        const { outlets } = resp
        Vue.gtm.trackEvent({
          event: 'search_response_time',
          label: `/search?${Object.keys(query).map(key => `${key}=${query[key]}`).join('&')}`,
          value: Date.now() - startTime,
          regionId: query.regionId,
          city: state.queryParams.locationQuery
        })
        const { results } = outlets.availability
        const tmp = uniqBy([...state.availability.results, ...results], 'property.propertyCode')
        outlets.availability.results = tmp
        if (outlets.availability.status === 'in-progress') {
          commit('SET_SEARCH_MANY', {
            isGeoEmpty: false,
            searchIdTmp: query.searchId,
            ...outlets
          })

          const timeout = setTimeout(() => dispatch('search', query), 2500)

          commit('SET_TIMEOUT', timeout)
        } else {
          outlets.availability.results = results
          commit('SET_SEARCH_MANY', {
            isSearching: false,
            searchIdTmp: query.searchId,
            isGeoEmpty: false,
            ...outlets
          })
          const dateNow = moment().format('YYYY-MM-DD')
          if (state.queryParams.checkInDate < dateNow || state.queryParams.checkOutDate < dateNow) {
            dispatch(
              'setGlobalErrorModal',
              {
                show: true,
                title: i18n.t('app.error.past-date.title'),
                content: i18n.t('app.error.past-date.content')
              },
              { root: true }
            )
          }
        }
      }
    ).catch((err) => {
      if (isCancel(err)) {
        if (debug) console.warn(`Cancel: ${err.message}`)
        if (err.message !== 'NEW_SEARCH_MANY') {
          commit('SET_SEARCH_MANY', {
            isSearching: false,
            isGeoEmpty: false,
            availability: { results: [] }
          })
          commit('meta/SET_META_ACTION', err.error.meta, { root: true })
          commit('SET_GLOBAL_MODAL', { show: true, content: err.message || (err.error && err.error.message) || '' }, { root: true })
        }
      } else {
        // if (err.error && err.error.status === 403) {
        //   commit(
        //     'SET_GLOBAL_MODAL',
        //     {
        //       show: true,
        //       title: i18n.t('app.error.availabilitySearch.title'),
        //       content: i18n.t('app.error.availabilitySearch.content')
        //     },
        //     { root: true }
        //   )
        // }
        if (err.message && err.message.toLowerCase().indexOf('timeout') > -1) {
          const timeout = setTimeout(() => dispatch('search', query), 2500)

          commit('SET_TIMEOUT', timeout)
        } else {
          commit('SET_SEARCH_MANY', {
            isSearching: false,
            isGeoEmpty: false,
            availability: { results: [] }
          })
        }
      }
    })
  },
  mapFitBounds ({ commit }, payload) {
    commit('SET_SEARCH_MANY', {
      mapFitBounds: payload.mapFitBounds,
      mapLatLngs: payload.mapLatLngs
    })
  },
  getRecentSearches ({ commit }) {
    // Excessive use of cookies may result in exceeding the 80k limit, so switch to using localstorage
    let recentSearches = localStorage.getItem(getRecentSearchKey())
    recentSearches = recentSearches ? JSON.parse(recentSearches) : []
    commit('SET_RECENT_SEARCHES', recentSearches)
  },
  updateRecentSearches ({ commit }, payload) {
    let recentSearches = localStorage.getItem(getRecentSearchKey())
    recentSearches = recentSearches ? JSON.parse(recentSearches) : []
    if (!recentSearches.some(item => item.id === payload.id)) {
      const { name, id, term } = payload
      const termArray = name ? name.split(',') : term.split(',')
      recentSearches.push({
        name: name || term,
        id: id,
        city: termArray[0]
      })
      if (recentSearches.length > 4) {
        recentSearches = recentSearches.slice(-4)
      }
      localStorage.setItem(getRecentSearchKey(), JSON.stringify(recentSearches))
      commit('SET_RECENT_SEARCHES', recentSearches)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
