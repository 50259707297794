import api from '@/service/api'
import { debug } from '@/plugins/util'
import { setCheckoutExpiredAt, getCheckoutExpiredAt } from '@/components/CountDown/checkoutExpired'
import { divide, plus, times } from 'number-precision'
import { retrieveAgent } from '@/service/auth'

const state = {
  bookingPolicy: {},
  nextStep: undefined,
  reserveForm: {
    property: {},
    searchQuery: {},
    searchParams: {},
    availability: {},
    backUrl: ''
  },
  postBook: {},
  paymentMethods: {
    details: {},
    originKey: '',
    paymentEnv: ''
  },
  pciBookingSession: {
    paymentToken: '',
    paymentApi: '',
    paymentBrand: ''
  },
  isFetchingPaymentMethods: false,
  isFetchingPaymentSession: false,
  isFetching: false,
  isSubmitting: false,
  error: {
    backUrl: ''
  },
  forms: undefined,
  finalAdjustmentForm: {
    type: 'PERCENTAGE',
    amount: '',
    currency: ''
  },
  userInputAdjustment: {
    type: 'PERCENTAGE',
    amount: '',
    currency: ''
  },
  totalEquivalent: {
    value: 0,
    currency: ''
  },
  retryCount: 0,
  maxRetries: 5,
  retrying: 0
}

const mutations = {
  SET_DATA: (state, payload) => {
    if (debug) console.log('bookingPolicy triggered with', payload)
    const keys = Object.keys(payload)
    keys.forEach((key) => {
      state[key] = payload[key]
    })
  },
  SET_ADJUSTMENT_FORM: (state, payload) => {
    const keys = Object.keys(payload)
    keys.forEach((key) => {
      state.finalAdjustmentForm[key] = payload[key]
    })
  },
  SET_USER_INPUT_ADJUSTMENT: (state, payload) => {
    const keys = Object.keys(payload)
    keys.forEach((key) => {
      state.userInputAdjustment[key] = payload[key]
    })
  },
  RETRY_COUNT: (state, payload) => {
    state.retryCount = payload
  },
  RETRY_PROCESS: (state, payload) => {
    state.retrying = payload
  }
}

const actions = {
  async checkout ({ commit, dispatch, state }, payload) {
    try {
      const { outlets, meta } = await api.post('/booking/checkout', payload.body, { params: payload.query })
      commit('meta/SET_META_ACTION', meta, { root: true })
      const { availability, ckToken } = outlets
      let { package: newPckg = {} } = availability
      commit('SET_DATA', {
        bookingPolicy: newPckg,
        nextStep: { ckToken },
        forms: undefined
      })
      commit('RETRY_COUNT', 0)
      setCheckoutExpiredAt(ckToken, new Date().getTime() + 15 * 60000)
      return { outlets, meta }
    } catch (err) {
      if ((err.error && err.error.status > 499) ||
        (err.error && err.error.status > 408) ||
        (err.message.toLowerCase().indexOf('timeout') > -1)) {
        let retries = state.retryCount + 1
        commit('RETRY_COUNT', retries)
        commit('RETRY_PROCESS', 1)
        if (state.retryCount < 5) {
          setTimeout(() => {
            dispatch('checkout', payload)
          }, 2000)
        } else {
          commit('RETRY_COUNT', 0)
          commit('RETRY_PROCESS', 2)
          commit('SET_DATA', { bookingPolicy: {}, nextStep: {}, forms: undefined })
        }
      } else {
        commit('RETRY_PROCESS', 2)
        commit('SET_DATA', { bookingPolicy: {}, nextStep: {}, forms: undefined })
      }
    }
  },
  reserveForm ({ commit }, payload) {
    commit('SET_DATA', { isFetching: true })
    return new Promise((resolve, reject) => {
      api.get(`/booking/checkout/${payload.ckToken}`, { params: payload.query })
        .then((resp) => {
          const { outlets, meta } = resp
          commit('meta/SET_META_ACTION', meta, { root: true })
          commit('SET_DATA', { reserveForm: outlets })
          commit('SET_DATA', { isFetching: false })
          setCheckoutExpiredAt(outlets.ckToken, getCheckoutExpiredAt(payload.ckToken))
          resolve(outlets)
        }).catch(err => {
          if (err.error && err.error.resolveActions && err.error.resolveActions.resolveActions) {
            for (const action of err.error.resolveActions.resolveActions) {
              if (action.label === 'Get latest rate') {
                commit('SET_DATA', { error: { backUrl: action.relativeUrl } })
              }
            }
          }
          reject(err)
        })
    })
  },
  preBook (_, payload) {
    localStorage.setItem(`${process.env.VUE_APP_PREFIX_NAME}postBook`, JSON.stringify(payload))
  },
  postBook ({ commit, state }, payload) {
    payload = { ...payload, agentFinalAdjustment: { amount: state.finalAdjustmentForm.amount, type: state.finalAdjustmentForm.type } }

    commit('SET_DATA', { isSubmitting: true })
    return new Promise((resolve, reject) => {
      api.post('/booking/book', payload).then((resp) => {
        const { outlets, meta } = resp
        commit('meta/SET_META_ACTION', meta, { root: true })
        commit('SET_DATA', { isSubmitting: false, postBook: outlets })
        resolve(resp)
      }).catch((err) => {
        commit('SET_DATA', { isSubmitting: false })
        reject(err)
      })
    })
  },
  transactionConversion ({ commit, state, getters }, payload) {
    commit('SET_DATA', { isSubmitting: true, isFetchingPaymentMethods: true })
    return new Promise((resolve, reject) => {
      const { query } = getters.searchQueryParams
      api.post('/transaction/conversion', payload, { params: { sourceMarket: query.sourceMarket || 'US' } }).then((resp) => {
        const { meta } = resp
        commit('meta/SET_META_ACTION', meta, { root: true })
        commit('SET_DATA', { isSubmitting: false, isFetchingPaymentMethods: false })
        resolve(resp)
      }).catch((err) => {
        commit('SET_DATA', { isSubmitting: false, isFetchingPaymentMethods: false })
        reject(err)
      })
    })
  },
  getPaymentMethods ({ commit }, payload) {
    commit('SET_DATA', { isFetchingPaymentMethods: true })
    return new Promise((resolve, reject) => {
      api.post('/booking/checkout/paymentMethods', payload.body, { params: payload.params })
        .then(({ outlets, meta }) => {
          commit('meta/SET_META_ACTION', meta, { root: true })
          commit('SET_DATA', { paymentMethods: outlets })
          commit('SET_DATA', { isFetchingPaymentMethods: false })
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getPciBookingSession ({ commit }) {
    commit('SET_DATA', { isFetchingPaymentSession: true })
    return new Promise((resolve, reject) => {
      api.get('/booking/checkout/pci-booking/session')
        .then(({ outlets, meta }) => {
          commit('meta/SET_META_ACTION', meta, { root: true })
          commit('SET_DATA', { pciBookingSession: outlets })
          commit('SET_DATA', { isFetchingPaymentSession: false })
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  sendNoAvailabilityInfo (_, payload) {
    return api.post('/feedback/noAvailability', payload)
  }
}

const getters = {
  searchQueryParams: (state) => {
    const { name, ...rest } = state.reserveForm.searchQuery
    return {
      query: {
        ...rest,
        regionId: state.reserveForm.regionId,
        // nationality: rest.sourceMarket,
        currency: state.reserveForm.targetCcy
      },
      params: {
        locationQuery: state.reserveForm.locationQuery
      }
    }
  },
  backUrl: (state) => {
    return state.error.backUrl || state.reserveForm.backUrl
  },
  // getters totalAmount is  agentDisplayRate (not include tx fee)
  totalAmount: (state) => {
    return state.reserveForm.availability.package ? state.reserveForm.availability.package.agentDisplayRate : undefined
  },
  // tx fee pct array
  finalAdjustments: (state) => {
    return state.reserveForm.availability.package ? state.reserveForm.availability.package.finalAdjustments : []
  },
  finalAdjustment: (state, getters) => {
    if (state.finalAdjustmentForm.amount && getters.totalAmount) {
      return {
        value: state.finalAdjustmentForm.type === 'PERCENTAGE' ? times(getters.totalAmount.value, divide(state.finalAdjustmentForm.amount, 100)) : parseFloat(state.finalAdjustmentForm.amount),
        currency: getters.totalAmount.currency
      }
    } else {
      return undefined
    }
  },
  examplePrice: (state, getters) => {
    return retrieveAgent('defaultCurrency') === 'ZAR' ? 1000.00 : 100.00
  },
  examplePriceObj: (state, getters) => {
    if (getters.totalAmount) {
      return {
        value: getters.examplePrice,
        currency: retrieveAgent('defaultCurrency')
      }
    } else {
      return undefined
    }
  },
  examplePriceAfterAdjustment: (state, getters) => (finalAdjustmentForm) => {
    if (finalAdjustmentForm.amount && getters.totalAmount) {
      const value = finalAdjustmentForm.type === 'PERCENTAGE' ? times(getters.examplePrice, divide(finalAdjustmentForm.amount, 100)) : parseFloat(finalAdjustmentForm.amount)
      return {
        value: plus(getters.examplePrice, value),
        currency: retrieveAgent('defaultCurrency')
      }
    } else {
      return {
        value: getters.examplePrice,
        currency: retrieveAgent('defaultCurrency')
      }
    }
  },
  totalAmountAfterAdjustment: (state, getters) => {
    if (state.finalAdjustmentForm.amount && getters.totalAmount) {
      const value = state.finalAdjustmentForm.type === 'PERCENTAGE' ? times(getters.totalAmount.value, divide(state.finalAdjustmentForm.amount, 100)) : parseFloat(state.finalAdjustmentForm.amount)
      return {
        value: plus(getters.totalAmount.value, value),
        currency: getters.totalAmount.currency
      }
    } else {
      return {
        value: getters.totalAmount.value,
        currency: getters.totalAmount.currency
      }
    }
  },
  additionalFeeAmount: (state) => {
    return state.reserveForm.availability.package.additionalFeeAmount
  },
  totalConvertedAmount: (state) => {
    return state.totalEquivalent
  },
  txFeeAmount: (_, getters) => {
    if (getters.totalAmountAfterAdjustment && getters.finalAdjustments.length) {
      const txFeePct = getters.finalAdjustments[0].value || 0
      const additionalFee = getters.additionalFeeAmount.value || 0
      const total = getters.totalConvertedAmount
      return {
        value: total.value < 30 ? plus(times(getters.totalAmountAfterAdjustment.value || 0, txFeePct), additionalFee) : times(getters.totalAmountAfterAdjustment.value, txFeePct),
        currency: getters.totalAmountAfterAdjustment.currency
      }
    } else {
      return {
        value: 0,
        currency: getters.totalAmountAfterAdjustment.currency
      }
    }
  },
  totalChargeAmountLocal: (state, getters) => {
    return {
      value: Math.ceil(plus(getters.totalAmountAfterAdjustment.value, getters.txFeeAmount.value)),
      currency: getters.totalAmount.currency
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
