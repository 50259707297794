import { debug } from '@/plugins/util'
import LazyLoadGoogleMap from '@/components/google/LazyLoadGoogleMap'

const state = {
  google: undefined,
  center: {
    lat: 1.3100128416300763,
    lng: 103.88438311096195
  }
}

const getters = {}

const mutations = {
  SET_DATA: (state, payload) => {
    if (debug) console.log('set google triggered with', payload)
    const keys = Object.keys(payload)
    keys.forEach((key) => {
      state[key] = payload[key]
    })
  }
}

const actions = {
  async loadGoogle ({ commit, dispatch, state }, payload) {
    if (!payload.apiKey) return
    try {
      const google = await LazyLoadGoogleMap(payload)()
      commit('SET_DATA', { google: google })
    } catch (e) {
      // Ignore errors
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
