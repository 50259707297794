export default {
  data () {
    return {
      showPassword: false
    }
  },
  computed: {
    passwordType () {
      return this.showPassword ? 'text' : 'password'
    },
    togglePasswordText () {
      return this.showPassword ? this.$t('views.customer.form.hide') : this.$t('views.customer.form.show')
    }
  },
  methods: {
    togglePassword () {
      this.showPassword = !this.showPassword
    }
  }
}
