/**
 * https://www.npmjs.com/package/webfontloader
 * Use to load webfont without blocking the rendering. however it will
 * have Flash of Unstyled Text (FOUT).
 *
 * The FOUT can be more easily avoided when loading the Web Font Loader
 * synchronously, as it will automatically set the wf-loading class on the
 * HTML element as soon as Webfont.load has been called. The browser will
 * wait for the script to load before continuing to load the rest of the content,
 * FOUT is avoided.
 */
if (window.WebFont) {
  // eslint-disable-next-line no-undef
  WebFont.load({
    google: {
      api: 'https://fonts.googleapis.com/css',
      families: [
        // Define the font famaily name
        'Maven Pro:400,600,700'
      ]
    }
  })
}
