import { debug } from '@/plugins/util'
import api from '@/service/api'

const formatPriceRange = function (pr) {
  if (pr == null) return []
  const o = pr.split(',').map(e => Number(e))
  if (o.length !== 2) return []
  return o
}

const formatCheckBox = function (ty) {
  if (!ty) return {}
  return ty.split(',').reduce((pV, cV) => {
    pV[cV] = true
    return pV
  }, {})
}

const checkboxToQueryString = function (val) {
  const actives = Object.entries(val).filter(([key, boo]) => boo)
  return actives.map(([key, boo]) => key).join(',')
}

const state = {
  refundable: undefined,
  payAtHotel: undefined,
  meals: {},
  propertyName: [''],
  priceRange: [],
  tyRange: {},
  starRange: {},
  categories: {},
  facilities: {},
  locationDistance: {}
}

const getters = {
  filters: state => {
    let ret = {
      refundable: state.refundable,
      payAtHotel: state.payAtHotel,
      propertyName: state.propertyName[0],
      priceRange: state.priceRange.join(','),
      tyRange: checkboxToQueryString(state.tyRange),
      starRange: checkboxToQueryString(state.starRange),
      categories: checkboxToQueryString(state.categories),
      facilities: checkboxToQueryString(state.facilities),
      meals: checkboxToQueryString(state.meals)
    }
    if (state.locationDistance.location && state.locationDistance.radius && state.locationDistance.lng && state.locationDistance.lat) {
      ret = {
        ...ret,
        location: state.locationDistance.location,
        radius: state.locationDistance.radius,
        lng: state.locationDistance.lng,
        lat: state.locationDistance.lat
      }
    }
    return ret
  },
  hasFilter: (state, getters) => {
    return !!getters.filters.refundable || !!getters.filters.propertyName || !!getters.filters.priceRange ||
        !!getters.filters.tyRange || !!getters.filters.starRange ||
        !!getters.filters.categories || !!getters.filters.facilities || !!getters.filters.location || !!getters.filters.meals ||
        !!getters.filters.payAtHotel
  },
  filterCount: (state, getters) => {
    let count = 0
    if (getters.filters.refundable) count++
    if (getters.filters.payAtHotel) count++
    if (getters.filters.propertyName) count++
    if (getters.filters.priceRange) count++
    if (getters.filters.tyRange) count++
    if (getters.filters.starRange) count++
    if (getters.filters.categories) count++
    if (getters.filters.facilities) count++
    if (getters.filters.location) count++
    if (getters.filters.meals) count++
    return count
  }
}

const mutations = {
  SET_DATA: (state, payload) => {
    if (debug) console.log('setSearchFilters triggered with', payload)
    state.refundable = payload.hasOwnProperty('refundable')
      ? payload.refundable
      : undefined
    state.payAtHotel = payload.hasOwnProperty('payAtHotel')
      ? payload.payAtHotel
      : undefined
    state.propertyName = payload.hasOwnProperty('propertyName')
      ? [payload.propertyName]
      : ['']
    state.priceRange = formatPriceRange(payload['priceRange'])
    state.tyRange = formatCheckBox(payload['tyRange'])
    state.starRange = formatCheckBox(payload['starRange'])
    state.categories = formatCheckBox(payload['categories'])
    state.facilities = formatCheckBox(payload['facilities'])
    state.meals = formatCheckBox(payload['meals'])
    if (payload['location'] && payload['radius'] && payload['lng'] && payload['lat']) {
      state.locationDistance = {
        location: payload['location'],
        radius: payload['radius'],
        lng: payload['lng'],
        lat: payload['lat']
      }
    } else {
      state.locationDistance = {
        location: undefined,
        radius: undefined,
        lng: undefined,
        lat: undefined
      }
    }
  },
  UPDATE_DATA: (state, payload) => {
    if (debug) console.log('update SearchFilters triggered with', payload)
    const keys = Object.keys(payload)
    keys.forEach((key) => {
      state[key] = payload[key]
    })
  },
  CLEAR_DATA: (state) => {
    if (debug) console.log('clear SearchFilters triggered with')
    state.refundable = undefined
    state.propertyName = ['']
    state.priceRange = []
    state.tyRange = {}
    state.starRange = {}
    state.categories = {}
    state.facilities = {}
    state.locationDistance = {}
    state.meals = {}
    state.payAtHotel = undefined
  }
}

const actions = {
  propertySuggest ({ commit, dispatch, state }, params) {
    return api.get('/properties/property_autosuggest', { params: params })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
