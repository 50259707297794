import cookies from 'js-cookie'
import api, { axiosInst } from '@/service/api'
import { debug } from '@/plugins/util'
import { doLogout } from '@/service/auth'

let initApp = {}
try {
  let storeApp = localStorage.getItem(`${process.env.VUE_APP_PREFIX_NAME}ap_agent`)
  if (storeApp) {
    initApp = JSON.parse(storeApp)
  }
} catch (e) {
  console.error(e)
}

const state = {
  companyName: undefined,
  appAccessKey: undefined,
  supportedPaymentCurrencies: undefined,
  defaultPaymentCurrency: undefined,
  defaultDisplayCurrency: '',
  defaultLocale: undefined,
  defaultSourceMarket: '',
  postalCode: undefined,
  country: undefined,
  city: undefined,
  contactNo: undefined,
  address: undefined,
  payment: undefined,
  timezone: undefined,
  walletInfo: {},
  images: {},
  email: {},
  supportedCurrencies: [],
  pricing: undefined,
  isSubmitting: false,
  isFetching: false,
  payAtHotel: false,
  ...initApp
}

const mutations = {
  SET_APP: (state, payload) => {
    if (debug) console.log('setAppInfo triggered with', payload)
    const keys = Object.keys(payload)
    keys.forEach((key) => {
      state[key] = payload[key]
    })
    const fromStorage = localStorage.getItem(`${process.env.VUE_APP_PREFIX_NAME}ap_agent`)
    if (fromStorage) {
      const agent = { ...JSON.parse(fromStorage), ...payload }
      localStorage.setItem(`${process.env.VUE_APP_PREFIX_NAME}ap_agent`, JSON.stringify(agent))
    } else {
      localStorage.setItem(`${process.env.VUE_APP_PREFIX_NAME}ap_agent`, JSON.stringify(payload))
    }
  },
  SET_DATA: (state, payload) => {
    const keys = Object.keys(payload)
    keys.forEach((key) => {
      state[key] = payload[key]
    })
  }
}

const actions = {
  getAppInfo ({ commit }) {
    return new Promise((resolve, reject) => {
      api.get('/auth/appinfo').then(
        ({ outlets, meta }) => {
          const { appAccessKey, ...rest } = outlets
          commit('SET_APP', rest)
          commit('user/SET_LOCALE', outlets.defaultLocale, { root: true })
          cookies.set(`${process.env.VUE_APP_PREFIX_NAME}ap_accessKey`, appAccessKey, { expires: 14 })
          resolve({ outlets, meta })
        }
      ).catch((err) => {
        const shouldLogout = e => {
          const logoutCode = [403]
          if (e && logoutCode.includes(e.status)) {
            return true
          }
          return false
        }
        if (shouldLogout(err)) {
          doLogout()
          return
        }
        reject(err)
      })
    })
  },
  updateCompanyInfo (_, payload) {
    return api.patch('/agents/companyInfo', payload)
  },
  updateCompanyLogo (_, payload) {
    return api.patch('/agents/companyLogo', payload)
  },
  updateDailyBalanceReminder (_, payload) {
    return api.patch('/agents/wallet/dailyBalanceReminder', payload)
  },
  updatedEmailTemplate (_, payload) {
    return api.patch('/agents/emailTemplate', payload)
  },
  getAgentPricing ({ commit }) {
    return new Promise((resolve, reject) => {
      api.get('/agents/pricing').then(
        ({ outlets, meta }) => {
          commit('SET_APP', { pricing: outlets.details })
          resolve({ outlets, meta })
        }
      ).catch((err) => {
        reject(err)
      })
    })
  },
  updateAgentPricing (_, payload) {
    return api.patch('/agents/pricing', payload)
  },
  updateAgentUser (_, payload) {
    return api.patch('/agents/user', payload)
  },
  fetchTransactions (_, params) {
    return api.get('/agents/transaction', { params })
  },
  exportTransaction (_, params) {
    return api.post('/agents/transaction/xls', {},
      {
        headers: {
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
        params: { ...params },
        responseType: 'blob'
      }
    )
  },
  uploadLogo (_, params) {
    let data = new FormData()
    data.append(params.filename, params.file)
    return api.post('/agents/upload', data,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },
  uploadTransferSlip (_, params) {
    let data = new FormData()
    data.append(params.filename, params.file)
    return api.post('/agents/upload_private', data,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },
  removeFile (_, params) {
    return api.delete(`/agents/upload/${params.fileName}`)
  },
  topUpRequest (_, payload) {
    return api.post('/agents/topup', payload)
  },
  getWalletInfo ({ commit }, payload) {
    commit('SET_DATA', { isFetching: true })
    return new Promise((resolve, reject) => {
      api.get('/agents/wallet', { params: payload }).then((resp) => {
        const { outlets } = resp
        commit('SET_DATA', { walletInfo: outlets })
        commit('SET_DATA', { isFetching: false })
        resolve(outlets)
      }).catch(err => reject(err))
    })
  },
  geoIp (_) {
    return axiosInst.get('https://ipapi.co/json')
  }
}

const getters = {
  appAccessKey: () => {
    return cookies.get(`${process.env.VUE_APP_PREFIX_NAME}ap_accessKey`) || null
  },
  defaultSourceMarket: (state) => {
    return state.defaultSourceMarket
  },
  defaultCurrency: (state) => {
    return state.defaultDisplayCurrency
  },
  supportedCurrencies: (state) => {
    return state.supportedCurrencies.length ? state.supportedCurrencies.map(o => o.abbreviation) : ['USD', 'ZAR', 'NGN']
  },
  companyInfo: (state) => {
    return JSON.parse(JSON.stringify(state))
  },
  payAtHotelEnabled: (state) => {
    return state.payAtHotel
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
