import * as searchService from '@/service/search'
import api from '@/service/api'
import { plus, times, divide, minus } from 'number-precision'
const state = {
  list: [],
  isFetching: false,
  fetchError: null,
  examplePrice: 100.00,
  form: {
    customerName: '',
    markupType: '',
    markupAmount: '',
    discountType: '',
    discountAmount: '',
    currency: ''
  },
  isFetchingMore: false
}

const mutations = {
  ADD_ITEM: (state, hotel) => {
    if (!state.list.some(item => {
      const property = item.property || {}
      const hotelProperty = hotel.property || {}
      return property.propertyCode === hotelProperty.propertyCode
    })) {
      let cloneHotel = JSON.parse(JSON.stringify(hotel))
      cloneHotel.checked = true
      cloneHotel.availability.packages.forEach((p, i) => {
        if (i === 0) {
          p.checked = true
        } else {
          p.checked = false
        }
      })
      state.list.push(cloneHotel)
    }
  },
  REMOVE_ITEM: (state, hotel) => {
    state.list = state.list.filter(item => {
      const property = item.property || {}
      const hotelProperty = hotel.property || {}
      return property.propertyCode !== hotelProperty.propertyCode
    })
  },
  RESET: (state) => {
    state.list = []
    state.fetchError = null
  },
  SET_DATA: (state, payload) => {
    const keys = Object.keys(payload)
    keys.forEach((key) => {
      state[key] = payload[key]
    })
  },
  SET_FORM: (state, payload) => {
    const keys = Object.keys(payload)
    keys.forEach((key) => {
      state.form[key] = payload[key]
    })
  },
  CHECK_PROPERTY: (state, payload) => {
    let i = state.list.findIndex((item) => {
      const property = item.property || {}
      return property.propertyCode === payload.propertyCode
    })
    if (i !== -1) {
      state.list[i]['checked'] = payload.checked
    }
  },
  CHECK_PACKAGE: (state, payload) => {
    let i = state.list.findIndex((item) => {
      const property = item.property || {}
      return property.propertyCode === payload.propertyCode
    })
    if (i !== -1) {
      state.list[i]['availability']['packages'][payload.index]['checked'] = payload.checked
    }
  }
}

const actions = {
  async fetchData ({ commit, dispatch, state }, query) {
    let { propertyCodes, ...rest } = query
    commit('SET_DATA', { isFetching: true })
    commit('RESET')
    propertyCodes = propertyCodes.split(',')

    // restore later
    /* let { outlets, meta } = await api.post('/properties/quotes', { ...rest, propertyCodes: propertyCodes })
    commit('meta/SET_META_ACTION', meta, { root: true })
    outlets.list.forEach(item => {
      commit('ADD_ITEM', item)
    }) */

    // remove it later
    const items = []

    for (let i = 0; i < propertyCodes.length; i++) {
      const propertyCode = propertyCodes[i]
      const singleQuery = {
        ...rest,
        propertyCode
      }
      let item = {}

      if (propertyCode === undefined) {
        throw 'Invalid property code'
      }

      // get property
      try {
        const {
          outlets,
          meta
        } = await api.get(`/properties/details/${propertyCode}`)

        commit('meta/SET_META_ACTION', meta, { root: true })

        item = { ...item, ...outlets }
      } catch (err) {
        commit('SET_DATA', { fetchError: err.message || (err.error && err.error.message) || '' })

        if (err.error) {
          commit('meta/SET_META_ACTION', err.error.meta, { root: true })
        }
      }

      try {
        const { outlets, meta } = await searchService.poll(() => {
          return searchService.one(singleQuery)
        }, 2500, 20)

        commit('meta/SET_META_ACTION', meta, { root: true })

        item = { ...item, ...outlets }
      } catch (err) {
        commit('SET_DATA', { fetchError: err.message || (err.error && err.error.message) || '' })
      }

      items.push(item)
    }

    items.forEach((item) => {
      commit('ADD_ITEM', item)
    })

    commit('SET_DATA', { isFetching: false })
  },
  async fetchMore ({ commit, state }, query) {
    commit('SET_DATA', { isFetchingMore: true })
    const { outlets, meta } = await searchService.poll(() => {
      if (typeof (query.propertyCode) === 'undefined') {
        commit('SET_GLOBAL_MODAL', { show: true, content: 'Invalid property code' }, { root: true })
      }
      return searchService.one(query)
    }, 2500, 20)
    // set state on meta module
    commit('meta/SET_META_ACTION', meta, { root: true })
    const idx = state.list.findIndex((item) => {
      const property = item.property || {}
      return property.propertyCode === query.propertyCode
    })
    const tmp = JSON.parse(JSON.stringify(state.list[idx]))
    tmp.availability.packages = [...tmp.availability.packages, ...outlets.availability.packages]
    tmp.availability.pagination = outlets.availability.pagination
    const listTmp = JSON.parse(JSON.stringify(state.list))
    listTmp[idx] = { ...listTmp[idx], ...tmp }
    commit('SET_DATA', { list: listTmp })
    commit('SET_DATA', { isFetchingMore: false })
  },
  download ({ state }, query) {
    let { propertyCodes, ...rest } = query
    propertyCodes = state.list.filter((item) => item.checked).map((item) => {
      const property = item.property || {}
      return property.propertyCode
    })
    const payload = {
      selectedItem: state.list.filter((item) => item.checked).reduce((obj, cV) => {
        const tmp = cV.availability.packages.filter((p) => p.checked === true)
        const property = cV.property || {}
        const newKey = property.propertyCode
        Object.assign(obj, { ...obj, [newKey]: tmp.map((pckg) => pckg.token) })
        return obj
      }, {})
    }
    return api.post('/properties/quote', payload,
      {
        params: {
          ...rest,
          propertyCodes: propertyCodes
        },
        responseType: 'arraybuffer',
        headers: {
          'Accept': 'application/pdf'
        }
      }
    )
  }
}

const getters = {
  hasItem: (state, getters) => {
    return state.list.length > 0
  },
  itemCount: (state, getters) => {
    return state.list.length
  },
  selectedHotel: (state, getters) => {
    return state.list.filter(item => item.checked)
  },
  selectedHotelCount: (state, getters) => {
    return getters.selectedHotel.length
  },
  totalInit: (state, getters) => {
    let total = 0
    getters.selectedHotel.forEach(h => {
      let selectPackages = h['availability']['packages'].filter(item => item.checked)
      selectPackages.forEach(p => {
        total = plus(total, p.adjustedDisplayRate.value)
      })
    })
    return total
  },
  priceMarkup: (state, getters) => (price) => {
    if (state.form.markupAmount) {
      if (state.form.markupType === 'Percentage') {
        return times(price, plus(1, divide(state.form.markupAmount, 100)))
      } else {
        return plus(price, state.form.markupAmount)
      }
    } else {
      return price
    }
  },
  priceDiscount: (state, getters) => (price) => {
    if (state.form.discountAmount) {
      if (state.form.discountType === 'Percentage') {
        return times(price, minus(1, divide(state.form.discountAmount, 100)))
      } else {
        return minus(price, state.form.discountAmount)
      }
    } else {
      return price
    }
  },
  exampleMarkup: (state, getters) => {
    return getters.priceMarkup(state.examplePrice)
  },
  exampleDiscount: (state, getters) => {
    return getters.priceDiscount(getters.priceMarkup(state.examplePrice))
  },
  selectedPackages: (state) => (propertyCode) => {
    const property = state.list.find((item) => {
      const property = item.property || {}
      return property.propertyCode === propertyCode
    })
    return property.availability.packages.filter((pckg) => pckg.checked).length
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
