<template>
  <div
    id="app"
    :class="{'sticky-header':$route.meta.stickyHeader,'is-admin-route':isAdminRoute}"
    class="app">
    <AdminHeader v-if="isAdminRoute" />
    <TheHeader v-else />
    <keep-alive>
      <router-view
        v-if="$route.meta.keepAlive"
        class="content-slot">
        <!-- 这里是会被缓存的视图组件，比如 Home！ -->
      </router-view>
    </keep-alive>

    <router-view
      v-if="!$route.meta.keepAlive"
      class="content-slot">
      <!-- 这里是不被缓存的视图组件，比如 Edit！ -->
    </router-view>
    <TheFooter v-if="!$route.meta.hasFooterHidden && !$route.meta.isAdminRoute" />
    <Contact v-if="isLoggedIn && !$route.meta.hasContactHidden && !$route.meta.isAdminRoute" />
    <!-- Global message popup for unspecific error -->
    <!-- <PpModal v-model="$store.state.globalModal.show">
      <div class="message is-danger">
        <h5 class="title is-5 has-text-primary">{{ $t('app.title') }}</h5>
        <p class="subtitle is-6 m-t">{{ $t('app.subtitle') }}</p>
        <PpGap/>
        <p class="is-size-7">{{ $store.state.globalModal.content }}</p>
        <p class="is-size-7">Request id: {{ $store.state.meta.reqId }}</p>
        <PpGap size="large"/>
        <div class="has-text-right">
          <el-button
            class="button-secondary"
            size="small"
            @click="$store.commit('SET_GLOBAL_MODAL',{ show: false, content: undefined })">{{ $t('app.ok') }}
          </el-button>
        </div>
      </div>
    </PpModal> -->
    <PpModal
      v-if="!isRenderSeoTemplate"
      v-model="$store.state.globalModal.show"
      class="z-[2001]">
      <div
        class="message has-background-white"
        style="width: 597px">
        <h5 class="title is-4 has-text-secondary">
          <i
            v-if="$store.state.globalModal.showIcon"
            class="el-icon-warning has-text-danger" />
          {{ $store.state.globalModal.title || $t('app.title') }}
        </h5>
        <PpGap />
        <p
          v-if="!$store.state.globalModal.refresh"
          class="is-size-7 m-b-6x">
          {{ $store.state.globalModal.content }}
        </p>
        <p
          v-if="$store.state.globalModal.refresh"
          class="is-size-7 m-b-6x">
          {{ $t('app.retry') }}
          <RefreshTimer />
        </p>
        <!-- <p class="is-size-7">Request id: {{ $store.state.meta.reqId }}</p> -->
        <div class="has-text-right">
          <el-button
            v-if="$store.state.globalModal.okBtn"
            class="button-secondary"
            style="min-width: 100px;"
            size="small"
            @click="$store.commit('SET_GLOBAL_MODAL',{ show: false, content: undefined })">
            {{ $t('app.ok') }}
          </el-button>

          <el-button
            v-if="$store.state.globalModal.newSearchBtn"
            size="small"
            style="min-width: 155px;"
            class="button-secondary"
            @click="$store.commit('SET_GLOBAL_MODAL',{ show: false, content: undefined });$router.replace({ name: 'dashboard' })">
            {{ $t('app.start-new-search') }}
          </el-button>
          <el-button
            v-if="$store.state.globalModal.hotelPageBtn"
            size="small"
            style="min-width: 155px;"
            class="button-secondary"
            @click="$store.commit('SET_GLOBAL_MODAL',{ show: false, content: undefined });$router.go(-1)">
            {{ $t('app.back-to-hotel-page') }}
          </el-button>

          <el-button
            v-if="$store.state.globalModal.refresh"
            size="small"
            class="button-secondary-outline"
            style="width: 120px; height: 40px;"
            @click="$store.commit('SET_GLOBAL_MODAL',
                                  { show: false, content: undefined, refresh: false },
                                  { root: true });">
            {{ $t('app.close') }}
          </el-button>
          <el-button
            v-if="$store.state.globalModal.refresh"
            size="small"
            class="button-secondary"
            style="width: 120px; height: 40px;"
            @click="$router.go($router.currentRoute)">
            {{ $t('app.refresh') }}
          </el-button>
        </div>
      </div>
    </PpModal>
    <!-- For specific error -->
    <PpModal
      v-if="!isRenderSeoTemplate"
      v-model="$store.state.globalErrorModal.show"
      class="z-[2001]">
      <div
        class="box has-border is-flex has-text-centered"
        style="padding: 50px;">
        <div
          class="error-modal-content modal-columns-outer"
          style="max-width: 400px;">
          <div class="columns is-multiline">
            <div class="column is-12">
              <IconBase
                width="65"
                height="65"
                icon-name="Error">
                <IconError />
              </IconBase>
            </div>
            <div class="column is-12">
              <div class="has-text-danger is-size-4 has-text-weight-bold">
                {{ $store.state.globalErrorModal.title }}
              </div>
              <div>{{ $store.state.globalErrorModal.content }}</div>
              <div v-if="$store.state.globalErrorModal.showForgotLink">
                Forgot your password? <router-link
                  to="forgot-password"
                  class="has-text-secondary">
                  <span @click="$store.dispatch('setGlobalErrorModal', { show: false, content: undefined,title:undefined, showForgotLink: false })">Click here</span>
                </router-link>
              </div>
            </div>
            <div
              class="column is-12"
              style="padding-bottom: 0;">
              <el-button
                style="min-width: 155px;"
                class="button-secondary"
                @click="$store.dispatch('setGlobalErrorModal', { show: false, content: undefined,title:undefined, showForgotLink: false })">
                {{ $t('app.ok') }}
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </PpModal>
    <FullScreenLoader />
  </div>
</template>
<script>
import TheHeader from './views/TheHeader'
import TheFooter from './views/TheFooter'
import Contact from './views/share/Contact'
import AdminHeader from '@/views/admin/AdminHeader'
import FullScreenLoader from '@/components/FullScreenLoader'
import IconError from '@/components/icons/IconError'
import { isLoggedIn } from '@/service/auth'
import RefreshTimer from '@/components/RefreshTimer'

export default {
  components: {
    IconError,
    FullScreenLoader,
    AdminHeader,
    Contact,
    TheFooter,
    TheHeader,
    RefreshTimer
  },
  data () {
    return {
      isRenderSeoTemplate: !!window['__PRERENDER_INJECTED'] // remove modal for pre rendered page
    }
  },
  computed: {
    isAdminRoute () {
      return window.location.href.indexOf('admin') > -1
    },
    isLoggedIn () {
      return isLoggedIn()
    }
  },
  beforeCreate () {
    /* We need to get appInfo in advance.
     * When agent admin updates company info, agent user can still get the latest company information without visiting the /my-company page
     * (if user login by cookie and not visiting the /my-company page, localStorage companyInfo is old)
     * */
    if (window.location.href.indexOf('admin') === -1 && isLoggedIn()) {
      this.$store.dispatch('app/getAppInfo')
    }
  },
  metaInfo () {
    const titleSuffix = this.isAdminRoute ? 'Super Admin' : 'eBEDS'
    return {
      title: '',
      titleTemplate: chunk => chunk ? `${chunk} | ${titleSuffix}` : `${titleSuffix}`
    }
  }
}

</script>

<style scoped>
.z-\[2001\] {
  z-index: 2001;
}
</style>
