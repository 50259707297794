import cookies from 'js-cookie'
import isEmpty from 'lodash/isEmpty'
import store from '@/store'
import api from '@/service/api'
import { debug } from '@/plugins/util'

// get exist locales from ./locales
const getExistLocales = function () {
  const locales = require.context('@/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  let existLocales = []
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      existLocales.push(matched[1])
    }
  })
  return existLocales
}
const existLocales = getExistLocales()

const state = {
  locale: 'en-US'
}

const mutations = {
  SET_LOCALE: (state, payload) => {
    if (!isEmpty(payload) && existLocales.indexOf(payload) !== -1) {
      state.locale = payload
      cookies.set(`${process.env.VUE_APP_PREFIX_NAME}locale`, payload, { expires: 712 })
    } else {
      const cookieLocale = cookies.get(`${process.env.VUE_APP_PREFIX_NAME}locale`)
      if (!isEmpty(cookieLocale) && existLocales.indexOf(cookieLocale) !== -1) {
        state.locale = cookieLocale
      }
    }
  },
  SET_DATA: (state, payload) => {
    if (debug) console.log('setAppInfo triggered with', payload)
    const keys = Object.keys(payload)
    keys.forEach((key) => {
      state[key] = payload[key]
    })
  },
  SET_USER: (state, payload) => {
    const user = state.users.find((user) => user === payload.user)
    user[payload.key] = payload.value
  }
}

const actions = {
  register: ({ commit }, { data, headers }) => {
    return api.post('/auth/register', data, { headers })
  },
  login: (_, { data, headers }) => {
    return new Promise((resolve, reject) => {
      api.post('/auth/login', data, { headers }).then(({ outlets, meta }) => {
        cookies.set(`${process.env.VUE_APP_PREFIX_NAME}usrToken`, outlets.usrToken, { expires: 14 })
        resolve({ outlets, meta })
      }).catch((err) => reject(err))
    })
  },
  confirm: ({ commit }, payload) => {
    return api.post('/auth/confirm', payload)
  },
  postActivity (_, payload) {
    api.post('/users/activity', payload)
  },
  toggleBookmark (_, { flag, property }) {
    if (flag) {
      const payload = {
        locale: store.state.meta.locale,
        property
      }
      api.post('/properties/bookmark', payload)
    } else {
      api.delete(`/properties/bookmark/${property.propertyCode}`)
    }
  },
  invite: (_, payload) => {
    return api.post('/users/invite', payload)
  },
  registerByInvitation: (_, { data, headers }) => {
    return api.post('/users/new', data, { headers })
  },
  forgotPassword: (_, { data, headers }) => {
    return api.post('/users/forgot_password', data, { headers })
  },
  resetPassword: (_, { data, headers }) => {
    return api.post('/users/reset_password', data, { headers })
  },
  fetchUsers: () => {
    return api.get('/users/list')
  },
  updateUser: (_, payload) => {
    return api.post('/users/edit', payload)
  },
  acceptTerm: (_, payload) => {
    return api.post('/users/acceptTerm', payload)
  },
  // In the firebox private window mode, newly opened windows cannot share localStorage. Therefore, user auth data is best stored in a cookie (fix bug AP-529)
  updateLocalStorage: (_, payload) => {
    const existingStorage = cookies.getJSON(process.env.VUE_APP_AUTH_STORAGE_KEY)
    const updated = { ...existingStorage, ...payload }
    cookies.set(process.env.VUE_APP_AUTH_STORAGE_KEY, JSON.stringify(updated), { expires: 14 })
  },
  resendConfirmationEmail: (_, payload) => {
    return api.post('/auth/register/resendEmail', payload)
  },
  changePassword: (_, payload) => {
    return api.post('/users/change_password', payload)
  },
  sendFeedback: (_, { data, headers }) => {
    return api.post('/feedback', data, { headers })
  }
}

const getters = {
  isLoggedIn: () => {
    // if (localStorage[process.env.VUE_APP_AUTH_STORAGE_KEY]) {
    //   const parsed = JSON.parse(localStorage[process.env.VUE_APP_AUTH_STORAGE_KEY])
    const parsed = cookies.getJSON(process.env.VUE_APP_AUTH_STORAGE_KEY)
    if (parsed) {
      return parsed.hasOwnProperty('user') && parsed.hasOwnProperty('usrToken')
    }
    return false
  },
  usrToken: () => {
    // if (localStorage[process.env.VUE_APP_AUTH_STORAGE_KEY]) {
    //   const parsed = JSON.parse(localStorage[process.env.VUE_APP_AUTH_STORAGE_KEY])
    const parsed = cookies.getJSON(process.env.VUE_APP_AUTH_STORAGE_KEY)
    if (parsed) {
      return parsed.usrToken
    }
    return null
  },
  displayName: (state, getters) => {
    if (getters.userProfile) {
      return getters.userProfile.displayName
    }
    return null
  },
  firstName: (state, getters) => {
    if (getters.userProfile) {
      return getters.userProfile.firstName
    }
    return null
  },
  lastName: (state, getters) => {
    if (getters.userProfile) {
      return getters.userProfile.lastName
    }
    return null
  },
  email: (state, getters) => {
    if (getters.userProfile) {
      return getters.userProfile.email
    }
    return null
  },
  userProfile: () => {
    // if (localStorage[process.env.VUE_APP_AUTH_STORAGE_KEY]) {
    //   const parsed = JSON.parse(localStorage[process.env.VUE_APP_AUTH_STORAGE_KEY])
    const parsed = cookies.getJSON(process.env.VUE_APP_AUTH_STORAGE_KEY)
    if (parsed) {
      return parsed.user
    }
    return null
  },
  userRole: (state, getters) => {
    if (getters.userProfile) {
      return getters.userProfile.roleCode
    }
    return null
  },
  hasRole: (state, getters) => (roleCode) => {
    if (getters.userProfile) {
      return getters.userProfile.roleCode === roleCode
    }
    return false
  },
  timezone: () => {
    // if (localStorage[process.env.VUE_APP_AUTH_STORAGE_KEY]) {
    //   const parsed = JSON.parse(localStorage[process.env.VUE_APP_AUTH_STORAGE_KEY])
    const parsed = cookies.getJSON(process.env.VUE_APP_AUTH_STORAGE_KEY)
    if (parsed) {
      return parsed.user.timezone
    }
    return null
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
