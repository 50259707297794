/* eslint-disable no-console */
import { register } from 'register-service-worker'

// import {MessageBox} from 'element-ui'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready (registration) {
      console.log(
        'App is being served from cache by a service worker.\n' +
          'For more details, visit https://goo.gl/AFskqB'
      )
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updated () {
      console.log('New content is available; please refresh.')
      // caches.keys().then(async (names) => {
      //   for (let name of names) await caches.delete(name)
      // })
      window.location.reload({ forceReload: true })
      // MessageBox.alert('Please reload the page.', 'New version is available',
      //     {
      //       confirmButtonText: 'Reload',
      //       confirmButtonClass: 'button-secondary',
      //       customClass: 'warning-message-box mini',
      //       dangerouslyUseHTMLString: true,
      //       callback: (action) => {
      //         window.location.reload()
      //       }
      //     }
      // )
    },
    offline () {
      console.log(
        'No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
