import api from '@/service/api'
import { debug } from '@/plugins/util'

const state = {
  isFetching: {
    recentViews: false,
    propertyBookmarks: false,
    lastBookings: false,
    accountSummary: false
  },
  recentViews: [],
  lastBookings: [],
  accountSummary: {},
  propertyBookmarks: {
    items: [],
    totalItems: 0
  },
  paginateBookmarks: {
    page: 1,
    limit: 2
  },
  moreBookmarks: false
}

const mutations = {
  SET_DASHBOARD: (state, payload) => {
    if (debug) console.log('setDashboard triggered with', payload)
    const keys = Object.keys(payload)
    keys.forEach((key) => {
      state[key] = payload[key]
    })
  },
  SET_IS_FETCHING: (state, payload) => {
    if (debug) console.log('setDashboardIsFetching triggered with', payload)
    state.isFetching = { ...state.isFetching, ...payload }
  }
}

const actions = {
  fetchRecentViews ({ commit }) {
    commit('SET_IS_FETCHING', { recentViews: true })
    api.get('/dashboard/recent_views').then(
      (resp) => {
        commit('SET_DASHBOARD', { recentViews: resp.outlets.items })
      }
    ).finally(() => {
      commit('SET_IS_FETCHING', { recentViews: false })
    })
  },
  fetchPropertyBookmarks ({ commit, state }, payload) {
    commit('SET_IS_FETCHING', { propertyBookmarks: true })
    api.get('/dashboard/property_bookmarks', { params: { ...state.paginateBookmarks } }).then(
      (resp) => {
        commit('SET_DASHBOARD', { propertyBookmarks: resp.outlets })
      }
    ).finally(() => {
      commit('SET_IS_FETCHING', { propertyBookmarks: false })
    })
  },
  fetchLastBookings ({ commit }, params) {
    commit('SET_IS_FETCHING', { lastBookings: true })
    api.get('/dashboard/last_bookings', { params }).then(
      ({ outlets }) => {
        commit('SET_DASHBOARD', { lastBookings: outlets.list.rows })
      }
    ).finally(() => {
      commit('SET_IS_FETCHING', { lastBookings: false })
    })
  },
  fetchAccountSummary ({ commit }) {
    commit('SET_IS_FETCHING', { accountSummary: true })
    api.get('/dashboard/summary').then(
      ({ outlets }) => {
        commit('SET_DASHBOARD', { accountSummary: outlets })
      }
    ).finally(() => {
      commit('SET_IS_FETCHING', { accountSummary: false })
    })
  },
  clearRecentViews ({ commit }) {
    commit('SET_DASHBOARD', { recentViews: [] })
    api.delete('/dashboard/recent_views')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
