import api, { CancelToken } from './api'

export const source = {
  many: CancelToken.source(),
  one: CancelToken.source()
}

export const poll = (fn, interval = 1000, maxRetries = 3) => {
  let requestCount = 0

  const checkCondition = (resolve, reject) => {
    let ajax = fn()
    requestCount += 1
    ajax.then((resp) => {
      const { outlets } = resp
      if (outlets && outlets.availability && outlets.availability.status === 'complete') {
        resolve(resp)
      } else if (requestCount <= maxRetries) {
        setTimeout(checkCondition, interval, resolve, reject)
      } else {
        reject(new Error('Timeout'))
      }
    }).catch((err) => {
      if (err.message && err.message.toLowerCase().indexOf('timeout') > -1) {
        setTimeout(checkCondition, interval, resolve, reject)
      } else {
        reject(err)
      }
    })
  }
  return new Promise(checkCondition)
}

export const many = (query) => {
  source.many.cancel('NEW_SEARCH_MANY')
  source.many = CancelToken.source()
  let apiEndPoint = '/properties/search'

  if (query.lat !== undefined && query.lng !== undefined && query.radius !==
      undefined) {
    apiEndPoint = '/properties/georadius'
  }
  return api.get(apiEndPoint, {
    params: query,
    cancelToken: source.many.token,
    timeout: 30 * 1000
  })
}

export const one = (query) => {
  source.one.cancel('NEW_SEARCH_ONE')
  source.one.cancel('NEW_SEARCH_ONE')
  source.one = CancelToken.source()
  return api.get(`/properties/rooms/${query.propertyCode}`, {
    params: query,
    cancelToken: source.one.token,
    timeout: 60 * 1000
  })
}
