import cookies from 'js-cookie'
import api from '@/service/api'
import clientColumns from './clientColumns'

const CLIENT_COLS_KEY = `${process.env.VUE_APP_PREFIX_NAME}clientColumns`

const state = {
  meta: {},
  companyForm: {
    accountsEmail: undefined,
    companyName: undefined,
    contactNo: undefined,
    address: undefined,
    city: undefined,
    country: undefined,
    postalCode: undefined,
    salesManager: undefined,
    travelGroup: undefined,
    inactive: undefined,
    availabilitySearch: undefined,
    reservation: undefined,
    balanceUSDIsActive: false,
    balanceZARIsActive: false
  },
  emailTemplateForm: {
    emailSupport: undefined,
    contactNo: undefined,
    companyName: undefined,
    whatsAppNo: undefined
  },
  clientCols: []
}

const mutations = {
  SET_META (state, payload) {
    state.meta = { ...state.meta, ...payload }
    if (payload.hasOwnProperty('user')) {
      const stringify = JSON.stringify({
        ...JSON.parse(localStorage.getItem(process.env.VUE_APP_ADMIN_STORAGE_KEY)),
        ...payload.user
      })
      localStorage.setItem(process.env.VUE_APP_ADMIN_STORAGE_KEY, stringify)
    }
    if (payload.hasOwnProperty('usrToken')) {
      cookies.set(`${process.env.VUE_APP_PREFIX_NAME}adminToken`, payload.usrToken, { expires: 14 })
    }
  },
  SET_COMPANY_FORM (state, payload) {
    Object.keys(payload).forEach((k) => {
      state.companyForm[k] = payload[k]
    })
  },
  SET_EMAIL_FORM (state, payload) {
    Object.keys(payload).forEach((k) => {
      state.emailTemplateForm[k] = payload[k]
    })
  },
  SET_CLIENT_COLS (state, nV) {
    if (nV != null) state.clientCols = nV
  }
}

const actions = {
  login (_, { data, headers }) {
    return api.post('/admin/auth/login', data, { headers })
  },
  inviteUser (_, payload) {
    return api.post('/admin/users/invite', payload)
  },
  registerUser (_, { data, headers }) {
    return api.post('/admin/auth/register', data, { headers })
  },
  clientList (_, params) {
    return api.get('/admin/client/list', { params })
  },
  clientOne ({ commit }, appCode) {
    return new Promise((resolve) => {
      api.get(`/admin/client/one/${appCode}`).then(({ outlets }) => {
        const { config, pricing, ...company } = outlets.details
        const companyFormPayload = {
          accountsEmail: config.accountsEmail,
          companyName: config.companyName,
          contactNo: config.contactNo,
          address: config.address,
          city: config.city,
          country: config.country,
          postalCode: config.postalCode,
          inactive: company.inactive,
          travelGroup: config.travelGroup,
          salesManager: config.salesManager,
          availabilitySearch: company.availabilitySearch,
          reservation: company.reservation,
          balanceUSDIsActive: company.balance.USD.isActive,
          balanceZARIsActive: company.balance.ZAR.isActive
        }
        commit('SET_COMPANY_FORM', companyFormPayload)
        commit('SET_EMAIL_FORM', outlets.details.config.email)
        resolve({ outlets })
      })
    })
  },
  updateClient (_, payload) {
    return api.patch('/admin/client/agent', payload)
  },
  updateConfig (_, payload) {
    return api.patch('/admin/client/config', payload)
  },
  updateDailyLimit (_, payload) {
    return api.patch('/admin/client/dailyLimit', payload)
  },
  updateDailyBalanceReminder (_, payload) {
    return api.patch('/admin/client/wallet/dailyBalanceReminder', payload)
  },
  updateNegativeBalance (_, payload) {
    return api.patch('/admin/client/wallet/negativeBalance', payload)
  },
  updateEmailTemplate (_, payload) {
    return api.patch('/admin/client/emailTemplate', payload)
  },
  updateCompanyInfo (_, payload) {
    return api.patch('/admin/client/companyInfo', payload)
  },
  updateCompanyLogo (_, payload) {
    return api.patch('/admin/client/companyLogo', payload)
  },
  updatePayAtHotelStatus (_, payload) {
    return api.patch('/admin/client/payAtHotelStatus', payload)
  },
  userList (_, params) {
    return api.get('/admin/users/list', { params })
  },
  exportUsersXLS (_, params) {
    return api.post('/admin/users/xls', {},
      {
        headers: {
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
        params: { ...params },
        responseType: 'blob'
      }
    )
  },
  exportClientXLS (_, params) {
    return api.post('/admin/client/xls', {},
      {
        headers: {
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
        params,
        responseType: 'blob'
      }
    )
  },
  userAutosuggest (_, params) {
    return api.get('/admin/users/autosuggest', { params })
  },
  clientUser (_, payload) {
    return api.post('/admin/client/user', payload)
  },
  updatePricing (_, payload) {
    return api.post('/admin/client/pricing', payload)
  },
  walletTransactions (_, params) {
    return api.get('/admin/client/wallet/list', { params })
  },
  exportWalletTransactions (_, params) {
    return api.post('/admin/client/wallet/xls', {},
      {
        headers: {
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
        params: { ...params },
        responseType: 'blob'
      }
    )
  },
  adjustBalance (_, payload) {
    return api.post('/admin/client/wallet/adjust', payload)
  },
  historyList (_, params) {
    return api.get(`/admin/client/history/${params.appCode}`)
  },
  userOne (_, uid) {
    return api.get(`/admin/users/one/${uid}`)
  },
  userHistory (_, uid) {
    return api.get(`/admin/users/one/${uid}/history`)
  },
  updateUser (_, payload) {
    return api.patch('/admin/users/one', payload)
  },
  updateUserClient (_, payload) {
    return api.put('/admin/users/client', payload)
  },
  getTopUpRequests (_, params) {
    return api.get('/admin/topup/list', { params })
  },
  exportTopUpRequests (_, payload) {
    return api.post('/admin/topup/list/xls', payload,
      {
        headers: {
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
        responseType: 'blob'
      }
    )
  },
  confirmTopup (_, payload) {
    return api.post('/admin/topup/confirm', payload)
  },
  uploadLogo (_, params) {
    let data = new FormData()
    data.append(params.filename, params.file)
    return api.post('/admin/client/upload', data,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },
  removeFile (_, params) {
    return api.delete(`/admin/client/upload/${params.fileName}`)
  },
  downloadFile (_, fileName) {
    return api.post('/admin/topup/slip', { fileName }, {
      headers: {
        'Accept': 'application/octet-stream'
      },
      responseType: 'blob'
    })
  },
  forgotPassword (_, { data, headers }) {
    return api.post('/admin/auth/forgot_password', data, { headers })
  },
  resetPassword (_, { data, headers }) {
    return api.post('/admin/auth/reset_password', data, { headers })
  },
  resendUserInvitation (_, payload) {
    return api.post('/admin/users/resend_invitation', payload)
  },
  getMasterSettings (_, params) {
    return api.get('/admin/masterConfig', { params })
  },
  updateMasterSettings (_, payload) {
    return api.patch('/admin/masterConfig', payload)
  },
  updateOrder (_, payload) {
    const { orderReference, ...rest } = payload
    return api.patch(`/admin/order/reference/${orderReference}`, rest)
  },
  confirmOrder (_, orderReference) {
    return api.post(`/admin/order/confirmHold/${orderReference}`)
  },
  loadClientCols ({ commit }) {
    const defaultCols = clientColumns.defaultColumns
    let k
    try {
      k = JSON.parse(localStorage.getItem(CLIENT_COLS_KEY))
    } catch (err) {
      console.error('Invalid saved cols, reset to default cols')
    }
    if (k == null || (Array.isArray(k) && k.length === 0)) {
      k = defaultCols
      localStorage.setItem(CLIENT_COLS_KEY, JSON.stringify(k))
    }
    commit('SET_CLIENT_COLS', k)
  },
  setClientCols ({ commit }, payload) {
    if (payload != null) localStorage.setItem(CLIENT_COLS_KEY, JSON.stringify(payload))
    commit('SET_CLIENT_COLS', payload)
  },
  updateOrderMetadata (_, payload) {
    const { orderReference, ...rest } = payload
    return api.put(`/admin/order/metadata/${orderReference}`, rest)
  }
}

const getters = {
  isLoggedIn: () => {
    if (localStorage[process.env.VUE_APP_ADMIN_STORAGE_KEY]) {
      const parsed = JSON.parse(localStorage[process.env.VUE_APP_ADMIN_STORAGE_KEY])
      return parsed.hasOwnProperty('user') && parsed.hasOwnProperty('usrToken')
    }
    return false
  },
  usrToken: () => {
    if (localStorage[process.env.VUE_APP_ADMIN_STORAGE_KEY]) {
      const parsed = JSON.parse(localStorage[process.env.VUE_APP_ADMIN_STORAGE_KEY])
      return parsed.usrToken
    }
    return null
  },
  displayName: (_, getters) => {
    if (getters.userProfile) {
      return getters.userProfile.displayName
    }
    return null
  },
  userProfile: () => {
    if (localStorage[process.env.VUE_APP_ADMIN_STORAGE_KEY]) {
      const parsed = JSON.parse(localStorage[process.env.VUE_APP_ADMIN_STORAGE_KEY])
      return parsed.user
    }
    return null
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
